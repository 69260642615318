import List from "antd-mobile/es/components/list";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import sortBy from "lodash/sortBy";
import { Fragment, useMemo } from "react";
import UpcomingRecipe from "~/components/charts/UpcomingRecipe";
import { useCalendarSelectorContext } from "~/components/context/calendarContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
dayjs.extend(calendar);

export default function UserCalendar() {
  const userCalOpen = useCalendarSelectorContext((state) => state.userCalOpen);
  const datesList = useCalendarSelectorContext((state) => state.datesList);
  const closeUserCalendar = useCalendarSelectorContext((state) => state.closeUserCalendar);

  const thisWeekRecipes = useMemo(() => {
    return datesList?.filter((r) => {
      const date = r.date.split("T")[0];
      //calculate the difference in days between the date and today
      const diff = dayjs(date).diff(dayjs(), "day");
      //if the difference is less than 7 days, it's this week
      return diff >= 0 && diff < 7;
    });
  }, [datesList]);

  const groupedThisWeek = useMemo(() => {
    const grouped = {};
    thisWeekRecipes?.forEach((r) => {
      const date = r.date.split("T")[0];
      let day = dayjs(date).format("YYYY-MM-DD");
      if (r.placeholder) {
        day = "This Week";
      }
      if (!grouped[day]) {
        grouped[day] = [];
      }
      grouped[day].push(r);
    });
    return grouped;
  }, [thisWeekRecipes]);

  const thisWeekDates = useMemo(() => {
    return sortBy(Object.keys(groupedThisWeek), (value) => {
      return value === "This Week" ? 0 : dayjs(value).unix();
    }).filter((d) => {
      return d !== "This Week";
    });
  }, [groupedThisWeek]);

  const nextWeekRecipes = useMemo(() => {
    return datesList?.filter((r) => {
      const date = r.date.split("T")[0];
      //calculate the difference in days between the date and today
      const diff = dayjs(date).diff(dayjs(), "day");
      //if the difference is greater than or equal to 7 days, and less than 14 days it's next week
      return diff >= 7 && diff < 14;
    });
  }, [datesList]);

  const groupedNextWeek = useMemo(() => {
    const grouped = {};
    nextWeekRecipes?.forEach((r) => {
      const date = r.date.split("T")[0];
      let day = dayjs(date).format("YYYY-MM-DD");
      if (r.placeholder) {
        day = "Next Week";
      }
      if (!grouped[day]) {
        grouped[day] = [];
      }
      grouped[day].push(r);
    });
    return grouped;
  }, [nextWeekRecipes]);

  const nextWeekDates = useMemo(() => {
    return sortBy(Object.keys(groupedNextWeek), (value) => {
      return value === "Next Week" ? 0 : dayjs(value).unix();
    }).filter((d) => {
      return d !== "Next Week";
    });
  }, [groupedNextWeek]);

  //   const extraSteppersHeader = (
  //     <div className="flex space-x-3 text-center">
  //       <div className="w-24 pr-1">Pantry</div>
  //       <div className="w-24 pr-1">Cart</div>
  //     </div>
  //   );
  return (
    <PopupWithNavbar
      headerClass="bg-theme-300"
      className={"choose-cook-popup"}
      open={userCalOpen}
      onClose={closeUserCalendar}
      shadow={false}
      title={"My Calendar"}
    >
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="nested-list flex flex-1 flex-col items-center">
          <List key={"pantry_list"} className="sticky-items w-full bg-gray-100">
            {thisWeekDates?.map((date) => {
              return (
                <Fragment key={"thisweek"}>
                  <List.Item
                    key={date}
                    className="sticky top-0 z-10 bg-gray-100"
                    title={dayjs(date).calendar(undefined, {
                      sameDay: "[Today]",
                      lastDay: "[Yesterday]",
                      lastWeek: "[Last] dddd",
                      sameElse: "ddd, MMM D",
                      nextWeek: "dddd",
                      nextDay: "[Tomorrow]",
                    })}
                  />
                  {groupedThisWeek[date]?.map((item) => {
                    return <UpcomingRecipe key={item.id} {...item} />;
                  })}
                </Fragment>
              );
            })}

            <List.Item className="sticky top-0 z-10 bg-gray-100" title={"This Week"} />
            {groupedThisWeek["This Week"]?.map((item) => {
              return <UpcomingRecipe key={item.id} {...item} />;
            })}
            {nextWeekDates?.map((date) => {
              return (
                <Fragment key={"nextweek"}>
                  <List.Item
                    key={date}
                    className="sticky top-0 z-10 bg-gray-100"
                    title={dayjs(date).calendar(undefined, {
                      sameDay: "[Today]",
                      lastDay: "[Yesterday]",
                      lastWeek: "[Last] dddd",
                      sameElse: "ddd, MMM D",
                      nextWeek: "dddd",
                      nextDay: "[Tomorrow]",
                    })}
                  />
                  {groupedNextWeek[date]?.map((item) => {
                    return <UpcomingRecipe key={item.id} {...item} />;
                  })}
                </Fragment>
              );
            })}
            <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Next Week"} />
            {groupedNextWeek["Next Week"]?.map((item) => {
              return <UpcomingRecipe key={item.id} {...item} />;
            })}
          </List>
          {/* <List key={"pantry_list"} className="w-full bg-gray-100">
          
        </List> */}
        </div>
      </div>
    </PopupWithNavbar>
  );
}
