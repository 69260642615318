import CapsuleTabs from "antd-mobile/es/components/capsule-tabs";
import { Group as CheckboxGroup } from "antd-mobile/es/components/checkbox/group";
import List from "antd-mobile/es/components/list";
import Stepper from "antd-mobile/es/components/stepper";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import Toast from "antd-mobile/es/components/toast";
import clsx from "clsx";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCalendarSelectorContext } from "~/components/context/calendarContext";
import { usePantrySelectorContext } from "~/components/context/pantryContext";
import { useShoppingListSelectorContext } from "~/components/context/shoppingListContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import ChevronLeftIcon from "~/components/ui/icons/ChevronLeftIcon";
import HomeIcon from "~/components/ui/icons/HomeIcon";
import PlusIcon from "~/components/ui/icons/PlusIcon";
import XIcon from "~/components/ui/icons/XIcon";
import InputText from "~/components/ui/input/InputText";
import ShoppingIngredient from "./ShoppingIngredient";
import { closeConfirmModal, confirmModal } from "./confirmModal";
dayjs.extend(calendar);

export default function UserShopping() {
  const open = useShoppingListSelectorContext((state) => state.open);
  const closeShoppingList = useShoppingListSelectorContext((state) => state.closeShoppingList);
  const shoppingListItems = useShoppingListSelectorContext((state) => state.shoppingListItems);
  const shoppingRecipes = useShoppingListSelectorContext((state) => state.shoppingRecipes);
  const deleteRecipeDateId = useShoppingListSelectorContext((state) => state.deleteRecipeDateId);
  const deleteShoppingRecipe = useShoppingListSelectorContext((state) => state.deleteShoppingRecipe);
  const handleRemoveFromShoppingList = useShoppingListSelectorContext((state) => state.handleRemoveFromShoppingList);
  const handleAddOneIngredientToShoppingList = useShoppingListSelectorContext((state) => state.handleAddOneIngredientToShoppingList);
  const handleAddToPantry = useShoppingListSelectorContext((state) => state.handleAddToPantry);
  const completeShoppingListItem = useShoppingListSelectorContext((state) => state.completeShoppingListItem);
  const uncompleteShoppingListItem = useShoppingListSelectorContext((state) => state.uncompleteShoppingListItem);

  const openPantry = usePantrySelectorContext((state) => state.openPantry);

  const deleteDatePlan = useCalendarSelectorContext((state) => state.deleteDatePlan);
  const handleChangeServings = useCalendarSelectorContext((state) => state.handleChangeServings);
  const [activeTab, setActiveTab] = useState("item");
  const [showAddIngredient, setShowAddIngredient] = useState(false);
  const [value, setValue] = useState([]);
  const [removedGroups, setRemovedGroups] = useState([]);
  const [addingItem, setAddingItem] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");

  const inputRef = useRef();
  const showRecipeHelp = () => {
    Toast.show({ icon: <ChevronLeftIcon className="h-6 w-6" />, content: "Swipe left and right for more actions" });
  };
  const extraSteppersHeader = (
    <div className="flex space-x-2">
      <div className="w-24 text-center">Qty</div>
    </div>
  );

  const showAddIng = () => {
    setShowAddIngredient(true);
    setTimeout(() => inputRef.current?.input?.current?.focus(), 100);
  };

  const hideAddIng = () => {
    setShowAddIngredient(false);
  };

  const numCompleted = useMemo(() => {
    return shoppingListItems.filter((i) => i.completed).length;
  }, [shoppingListItems]);

  const groups = useMemo(() => {
    let group = [];
    if (activeTab === "item") {
      let completed = shoppingListItems
        .filter((i) => i.completed)
        .reduce((hash, obj) => ({ ...hash, [obj.ingredient]: (hash[obj.ingredient] || []).concat(obj) }), {});
      let incomplete = shoppingListItems
        .filter((i) => !i.completed)
        .reduce((hash, obj) => ({ ...hash, [obj.ingredient]: (hash[obj.ingredient] || []).concat(obj) }), {});

      const completeItems = Object.keys(completed)
        .map((key) => {
          return {
            title: key,
            items: completed[key],
            id: completed[key][0].id,
            completed: true,
          };
        })
        .sort((a, b) => {
          try {
            return a.title.localeCompare(b.title);
          } catch (e) {
            return 0;
          }
        });

      const incompleteItems = Object.keys(incomplete)
        .map((key) => {
          return {
            title: key,
            items: incomplete[key],
            id: incomplete[key][0].id,
            completed: false,
          };
        })
        .sort((a, b) => {
          try {
            return a.title.localeCompare(b.title);
          } catch (e) {
            return 0;
          }
        });
      group = [
        {
          id: "items",
          completed: completeItems,
          incomplete: incompleteItems,
          incompleteTitle: (
            <SwipeAction className="sticky top-[70px] z-10 bg-gray-100" leftActions={[]} rightActions={[]}>
              <List.Item key={"incompleteTitle"} extra={extraSteppersHeader} className={"bg-gray-100"}>
                {"Ingredients"}
              </List.Item>
            </SwipeAction>
          ),
          completeTitle: (
            <List.Item key={"Completetitle"} extra={extraSteppersHeader} className="sticky top-[70px] z-10 bg-gray-100">
              {"Cart"}
            </List.Item>
          ),
        },
      ];
    } else if (activeTab === "recipe") {
      let recipeDateItems = shoppingListItems.reduce((hash, obj) => ({ ...hash, [obj.recipeDateId]: (hash[obj.recipeDateId] || []).concat(obj) }), {});
      let recipeItems = shoppingListItems.reduce((hash, obj) => ({ ...hash, [obj.recipeId]: (hash[obj.recipeId] || []).concat(obj) }), {});
      let otherItems = shoppingListItems.filter((i) => !i.recipeId && !i.recipeDateId);
      const recipeDates = Object.keys(recipeDateItems)
        .map((key) => {
          const recipe = shoppingRecipes.find((r) => r.id === key);
          const items = recipeDateItems[key];
          if (recipe) {
            return {
              incompleteTitle: (
                <SwipeAction
                  className="sticky top-[70px] z-10 bg-gray-100"
                  leftActions={[
                    {
                      text: "Remove",
                      onClick: () => confirmDelete(recipe.id, recipe.recipeName),
                      key: "remove",
                      color: "danger",
                    },
                  ]}
                  rightActions={[
                    {
                      text: "Edit",
                      onClick: () => console.log("edit and open the calendar"),
                      key: "edit",
                      color: "warning",
                    },
                  ]}
                >
                  <List.Item
                    arrow={false}
                    key={recipe.id}
                    className={"bg-gray-100"}
                    onClick={showRecipeHelp}
                    extra={
                      <div
                        className="flex flex-col text-center"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div>Servings</div>{" "}
                        <div>
                          <Stepper
                            showNumber
                            size="small"
                            min={1}
                            defaultValue={recipe.servings ?? 1}
                            max={1000}
                            aria-label={"Servings Adjustment"}
                            // onChange={handleQtyChange}
                            className="large-stepper"
                            onChange={(val) => {
                              handleChangeServings(recipe.id, val);
                            }}
                          />
                        </div>
                      </div>
                    }
                    description={`${dayjs(recipe.date.split("T")[0], "YYYY-MM-DD").calendar(undefined, {
                      sameDay: "[Today]",
                      lastDay: "[Yesterday]",
                      lastWeek: "[Last] dddd",
                      sameElse: "ddd, MMM D",
                      nextWeek: "dddd",
                      nextDay: "[Tomorrow]",
                    })}`}
                  >
                    <div className="truncated">{recipe?.recipeName}</div>
                  </List.Item>
                </SwipeAction>
              ),

              name: recipe?.recipeName,
              id: key,

              completed:
                items
                  ?.filter((i) => i.completed)
                  .map((item) => {
                    return {
                      title: item.ingredient,
                      id: item.id,
                      items: [item],
                      completed: item.completed,
                    };
                  }) ?? [],
              incomplete:
                items
                  ?.filter((i) => !i.completed)
                  .map((item) => {
                    return {
                      title: item.ingredient,
                      id: item.id,
                      items: [item],
                      completed: item.completed,
                    };
                  }) ?? [],
            };
          }
          return null;
        })
        .filter((g) => g)
        .sort((a, b) => a.name.localeCompare(b.name));

      const recipes = Object.keys(recipeItems)
        .map((key) => {
          if (!key || key === "null") return null;
          const recipe = shoppingRecipes.find((r) => r.recipeId === key) ?? { id: "unknown", recipeName: "unknown" };
          const items = recipeItems[key];
          if (recipe) {
            return {
              incompleteTitle: (
                <SwipeAction
                  className="sticky top-[70px] z-10 bg-gray-100"
                  leftActions={[
                    {
                      text: "Remove",
                      onClick: () => confirmDeleteRecipe(recipe.recipeId, recipe.recipeName),
                      key: "remove",
                      color: "danger",
                    },
                  ]}
                  rightActions={[
                    {
                      text: "Edit",
                      // onClick: () => console.log("edit and open the calendar"),
                      key: "edit",
                      color: "warning",
                    },
                  ]}
                >
                  <List.Item arrow={false} key={recipe.id} className={"bg-gray-100"} onClick={showRecipeHelp}>
                    <div className="truncated">{recipe?.recipeName}</div>
                  </List.Item>
                </SwipeAction>
              ),

              name: recipe?.recipeName,
              id: key,

              completed:
                items
                  ?.filter((i) => i.completed)
                  .map((item) => {
                    return {
                      title: item.ingredient,
                      id: item.id,
                      items: [item],
                      completed: item.completed,
                    };
                  }) ?? [],
              incomplete:
                items
                  ?.filter((i) => !i.completed)
                  .map((item) => {
                    return {
                      title: item.ingredient,
                      id: item.id,
                      items: [item],
                      completed: item.completed,
                    };
                  }) ?? [],
            };
          }
          return null;
        })
        .filter((g) => g)
        .sort((a, b) => a.name.localeCompare(b.name));

      const other = {
        incompleteTitle: (
          <List.Item arrow={false} key={"other"} className={"sticky top-[70px] z-10 bg-gray-100 bg-gray-100"} onClick={showRecipeHelp}>
            <div className="truncated">Other</div>
          </List.Item>
        ),

        name: "Other",
        id: "other",

        completed:
          otherItems
            ?.filter((i) => i.completed)
            .map((item) => {
              return {
                title: item.ingredient,
                id: item.id,
                items: [item],
                completed: item.completed,
              };
            }) ?? [],
        incomplete:
          otherItems
            ?.filter((i) => !i.completed)
            .map((item) => {
              return {
                title: item.ingredient,
                id: item.id,
                items: [item],
                completed: item.completed,
              };
            }) ?? [],
      };

      group = [...recipeDates, ...recipes, other];
    }
    return group;
  }, [shoppingListItems, activeTab, shoppingRecipes]);

  useEffect(() => {
    const selectedItems = [];
    groups.forEach((group) => {
      group.completed.forEach((item) => {
        selectedItems.push(`${group.id}-${item.id}`);
      });
    });
    setValue(selectedItems);
  }, [groups]);

  //   const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

  const confirmDelete = (id, name) => {
    confirmModal({
      title: "Remove Recipe Plan",
      content: (
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{`${name}`}</h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {`Are you sure you want to remove ${name || "this recipe"}? This will remove it from your shopping list and calendar.`}
            </p>
          </div>
        </div>
      ),
      actions: [
        [
          {
            key: "cancel",
            text: "Cancel",
            primary: true,
            onClick: closeConfirmModal,
          },
          {
            key: "delete",
            text: "Delete",
            danger: true,
            onClick: () => handleDeleteRecipeDate(id),
          },
        ],
      ],
    });
  };

  const confirmDeleteRecipe = (id, name) => {
    confirmModal({
      title: "Remove Recipe from Shopping List",
      content: (
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{`${name}`}</h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {`Are you sure you want to remove ${name || "this recipe"}? This will remove all items in this recipe from your shopping list.`}
            </p>
          </div>
        </div>
      ),
      actions: [
        [
          {
            key: "cancel",
            text: "Cancel",
            primary: true,
            onClick: closeConfirmModal,
          },
          {
            key: "delete",
            text: "Delete",
            danger: true,
            onClick: () => handleDeleteRecipe(id),
          },
        ],
      ],
    });
  };

  const confirmDeleteIngredient = (id, name) => {
    confirmModal({
      title: "Remove Item from Shopping List",
      content: (
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{`${name}`}</h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {`Are you sure you want to remove ${name || "this ingredient"}? This item will be removed from your shopping list.`}
            </p>
          </div>
        </div>
      ),
      actions: [
        [
          {
            key: "cancel",
            text: "Cancel",
            primary: true,
            onClick: closeConfirmModal,
          },
          {
            key: "delete",
            text: "Delete",
            danger: true,
            onClick: () => handleDeleteIngredient(id),
          },
        ],
      ],
    });
  };

  const handleDeleteIngredient = (id) => {
    closeConfirmModal();
    handleRemoveFromShoppingList(id);
  };

  const handleDeleteRecipeDate = useCallback(async (recipeDateId) => {
    closeConfirmModal();
    deleteDatePlan(recipeDateId);
    deleteRecipeDateId(recipeDateId);
    setRemovedGroups((prev) => [...prev, recipeDateId]);
  }, []);

  const handleDeleteRecipe = useCallback(async (recipeId) => {
    closeConfirmModal();
    deleteShoppingRecipe(recipeId);
    setRemovedGroups((prev) => [...prev, recipeId]);
  }, []);

  const handleAddItem = useCallback(() => {
    handleAddOneIngredientToShoppingList({ ingredient: addingItem });
    inputRef.current?.clearInput();
  }, [addingItem]);

  const ingredientLeftActions = [
    {
      key: "remove",
      text: "Remove",
      color: "danger",
      onClick: confirmDeleteIngredient,
    },
  ];

  const handleCompleteShoppingListItem = (data) => {
    console.log('itemId: ', data);
    completeShoppingListItem(data.map((item) => item.id));
  };

  const handleIncompleteShoppingListItem = (data) => {
    uncompleteShoppingListItem(data.map((item) => item.id));
  };

  const ingredientRightActions = [
    {
      key: "gotit",
      text: "Got It",
      color: "primary",
      primary: true,
      onClick: handleCompleteShoppingListItem,
    },
  ];

  const completeingredientRightActions = [
    {
      key: "oops",
      text: "Oops",
      color: "warning",
      onClick: handleIncompleteShoppingListItem,
    },
  ];

  const mainContent = (
    <>
      <List
      // style={{ "--font-size": "1rem", "--header-font-size": "1rem", "--border-top": "none", "--border-bottom": "none" }}
      >
        <CheckboxGroup
          value={value}
          onChange={(val) => {
            setValue(val);
          }}
        >
          <div className="sticky top-0 z-10 overflow-hidden bg-white">
            <List.Item
              extra={
                <div className="w-16 cursor-pointer p-4" onClick={hideAddIng}>
                  <XIcon className="h-full" />
                </div>
              }
              style={{ "--border-inner": "none" }}
              className={clsx(
                "absolute inset-x-0 -top-full z-10 flex h-full items-center text-lg opacity-0 transition-[opacity,top]",
                showAddIngredient ? "!top-0 opacity-100" : ""
              )}
            >
              <InputText
                withLabel={false}
                ref={inputRef}
                onBlur={hideAddIng}
                // onFocusOut={hideAddIng}
                onEnter={handleAddItem}
                setValue={setAddingItem}
                defaultValue={defaultValue}
              />
            </List.Item>
            <List.Item
              extra={
                <>
                  <div className="w-16 cursor-pointer p-4" onClick={showAddIng}>
                    <PlusIcon className="h-full" />
                  </div>
                </>
              }
            // className={clsx("flex-1")}
            >
              <CapsuleTabs
                className="segment nested-capsule-tabs"
                style={{ "--adm-color-border": "transparent" }}
                onChange={setActiveTab}
                activeKey={activeTab}
              >
                <CapsuleTabs.Tab title="Item" key="item" />
                <CapsuleTabs.Tab title="Recipe" key="recipe" />
                {/* <CapsuleTabs.Tab title="Store" key="stores" /> */}
              </CapsuleTabs>
            </List.Item>
          </div>

          {/* <List.Item className="checkbox-group"> */}
          {groups.map((group, gindex) => {
            if (removedGroups.includes(group.id)) return null;
            const completedItems = group.completed;
            const incompleteItems = group.incomplete;
            const incompleteContent = incompleteItems?.map((ingredient, index) => {
              const modifiedLeftActions = ingredientLeftActions?.map((action) => {
                return { ...action, onClick: () => action.onClick(ingredient.id, ingredient.title) };
              });
              const modifiedRightActions = ingredientRightActions?.map((action) => {
                return { ...action, onClick: () => action.onClick(ingredient.items) };
              });
              return (
                <ShoppingIngredient
                  key={index}
                  ingredient={ingredient}
                  group={group.id}
                  leftActions={modifiedLeftActions}
                  rightActions={modifiedRightActions}
                  extra={undefined}
                />
              );
            });

            const completedContent = completedItems?.map((ingredient, index) => {
              const modifiedLeftActions = ingredientLeftActions?.map((action) => {
                return { ...action, onClick: () => action.onClick(ingredient.id, ingredient.title) };
              });
              const modifiedRightActions = completeingredientRightActions?.map((action) => {
                return { ...action, onClick: () => action.onClick(ingredient.items) };
              });
              return (
                <ShoppingIngredient
                  key={index}
                  ingredient={ingredient}
                  group={group.id}
                  leftActions={modifiedLeftActions}
                  rightActions={modifiedRightActions}
                  extra={undefined}
                />
              );
            });
            return (
              <Fragment key={group.id}>
                {/* <List.Item className="sticky top-[70px] z-10 bg-gray-100" title={group.title} /> */}
                {group.incompleteTitle}

                {incompleteContent}
                {group.completeTitle}
                {completedContent}
              </Fragment>
            );
          })}

          {/* {groups.map((ingredient, index) => {
                    return <ShoppingIngredient ingredient={ingredient} key={ingredient.title} leftActions={[]} rightActions={[]} extra={undefined} />;
                  })} */}
          {/* </List.Item> */}
          {shoppingListItems.length === 0 && <List.Item>No items in list</List.Item>}
        </CheckboxGroup>
      </List>
    </>
  );

  const actions = [
    {
      key: "pantry",
      text: "Add to Pantry",
      primary: numCompleted > 0,
      color: numCompleted > 0 ? "primary" : undefined,
      onClick: handleAddToPantry,
      disabled: showAddIngredient,
    },
  ];
  return (
    <PopupWithNavbar
      className={"choose-cook-popup"}
      headerClass="bg-theme-300"
      open={open}
      onClose={closeShoppingList}
      shadow={false}
      title={"Shopping List"}
      navbarProps={{
        right: (
          <div className="w-16 p-4" onClick={openPantry}>
            <HomeIcon className="h-full" />
          </div>
        ),
        className: "relative bg-theme-300",
      }}
      actions={actions}
    >
      <div className="flex flex-1 flex-col overflow-y-auto" id="shopping-list-wrapper">
        <div className="nested-list sticky-items flex flex-1 flex-col">{mainContent}</div>
      </div>
    </PopupWithNavbar>
  );
}
