import { useFetcher } from "@remix-run/react";
import List from "antd-mobile/es/components/list";
import Result from "antd-mobile/es/components/result";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { useCallback, useMemo, useRef } from "react";
import { useForkedRecipeSelectorContext } from "~/components/context/forkedRecipesContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import InputText from "~/components/ui/input/InputText";
import { toTitleCase } from "~/utils/misc";
dayjs.extend(calendar);

export default function CollectionForm() {
  const newCollectionOpen = useForkedRecipeSelectorContext((state) => state.newCollectionOpen);
  const closeNewCollection = useForkedRecipeSelectorContext((state) => state.closeNewCollection);
  const activeEntity = useForkedRecipeSelectorContext((state) => state.activeEntity);

  const fetcher = useFetcher();
  const ref = useRef();

  const handleSubmitForm = useCallback(() => {
    const formdata = new FormData(ref.current);
    const formProps = Object.fromEntries(formdata);
    console.log(formProps);
    fetcher.submit(formdata, { method: "post", action: "/api/recipe/collection" });
    closeNewCollection();
  }, [fetcher]);
  const underDevelopment = (
    <div className="text-center">
      Manual Recipe Entry is Under Development and coming soon!
      <br />
      <br />
      Until then, Fork any recipe and make changes to any part of it. <br />
      <br />
      These changes are just for you, so go Forking wild, change the name, instructions, ingredients, amounts, etc.
    </div>
  );

  const content = useMemo(() => {
    if (activeEntity === "recipe") {
      return <Result status="waiting" title="Under Development" description={underDevelopment} />;
    } else if (["collection", "meal"].includes(activeEntity)) {
      return (
        <>
          <input type="hidden" name="action" value={`create-${activeEntity}`} />
          <List className="w-full bg-gray-100">
            <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Name"} />
            <List.Item className="">
              <InputText
                className="flex flex-1 font-medium leading-6 text-gray-900"
                name={`name`}
                title={"Name"}
                withLabel={false}
                placeholder={"Name"}
                borderless={true}
                type={"text"}
              // defaultValue={editingIngredient?.quantity}
              // ref={inputRef}
              />
            </List.Item>
            <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Description"} />
            <List.Item className="">
              <InputText
                className="flex flex-1 font-medium leading-6 text-gray-900"
                name={`description`}
                title={"Description"}
                withLabel={false}
                placeholder={"Description"}
                borderless={true}
                rows={2}
              // defaultValue={editingIngredient?.unit}
              // ref={inputRef}
              />
            </List.Item>

            {/* <div className="flex flex-none">
                    <Button color="danger" onClick={() => removeIngredient(ing)}>
                      <TrashEmptyIcon className="h-4 w-4" />
                    </Button>
                  </div> */}
          </List>
        </>
      );
    }
  }, [activeEntity]);

  return (
    <PopupWithNavbar
      className={"new-collection-popup"}
      open={newCollectionOpen}
      onClose={closeNewCollection}
      shadow={false}
      title={`New ${toTitleCase(activeEntity)}`}
      headerClass="bg-theme-300"
      actions={[
        {
          key: "submit",
          text: "Submit",
          color: "primary",
          onClick: handleSubmitForm,
        },
      ]}
    >
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex flex-1 flex-col overflow-y-auto">
          <fetcher.Form className={"nested-list sticky-items flex flex-1 flex-col"} ref={ref}>
            {content}
          </fetcher.Form>
        </div>
      </div>
    </PopupWithNavbar>
  );
}
