import { ActionFunction, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Links, Meta, Scripts, ScrollRestoration, useActionData, useLoaderData, useLocation, useMatches, useNavigation, useSearchParams } from "@remix-run/react";
// import antdMobileStyles from "antd-mobile/bundle/style.css?url";

import antdstyles from "antd-mobile/bundle/style.css?url";
import antdGlobalStyles from "antd-mobile/es/global/global.css?url";
import clsx from "clsx";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { allCookieCategories } from "./application/cookies/ApplicationCookies";
import { CookieCategory } from "./application/cookies/CookieCategory";
import CookieConsentBanner from "./components/cookies/CookieConsentBanner";
import ServerError from "./components/ui/errors/ServerError";
import FloatingLoader from "./components/ui/loaders/FloatingLoader";
import globalStyles from "./globals.css?url";
import forkedStyles from "./styles/forked.css?url";
import tailwindStyles from "./tailwind.css?url";

import { loadRootData } from "./utils/data/.server/rootData";
import { useRootData } from "./utils/data/useRootData";
import { getUser, updateUserProfile } from "./utils/db/users.db.server";
import AnalyticsHelper from "./utils/helpers/AnalyticsHelper";
import CookieHelper from "./utils/helpers/CookieHelper";
import { createUserSession, getUserInfo } from "./utils/session.server";
// import { useSWEffect } from "@remix-pwa/sw";
import ConfigProvider from "antd-mobile/es/components/config-provider";
import TagManager from "react-gtm-module";
import { AccountContextProvider } from "./components/context/accountContext";
import { CalendarContextProvider } from "./components/context/calendarContext";
import { CleanUpContextProvider } from "./components/context/cleanupContext";
import { CommandPaletteContextProvider } from "./components/context/commandPaletteContext";
import { CookRecipeContextProvider } from "./components/context/cookRecipeContext";
import { DashboardContextProvider } from "./components/context/dashboardContext";
import { ForkedRecipeContextProvider } from "./components/context/forkedRecipesContext";
import { useIsBot } from "./components/context/isBotContext";
import { PagesizeContextProvider } from "./components/context/pageSizeContext";
import { PantryContextProvider } from "./components/context/pantryContext";
import { PopoverContextProvider } from "./components/context/popoverContext";
import { RecipeContextProvider } from "./components/context/recipeContext";
import { RecipeSidebarContextProvider } from "./components/context/recipeSidebarContext";
import { SearchContextProvider } from "./components/context/searchContext";
import { ShoppingCartContextProvider } from "./components/context/shoppingCartContext";
import { ShoppingListContextProvider } from "./components/context/shoppingListContext";
import { TimerContextProvider } from "./components/context/timerContext";
import { UserPreferenceContextProvider } from "./components/context/userPreferencesContext";

import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next";
import enUS from "~/modules/recipe/helpers/antd.locale.en";
import PageMaintenanceMode from "./components/pages/PageMaintenanceMode";
import { UserPreferencesUpdatedDto } from "./modules/events/dtos/UserPreferencesUpdatedDto";
import EventsService from "./modules/events/services/.server/EventsService";
import FeatureFlagTracker from "./modules/featureFlags/components/FeatureFlagTracker";
import { serverTimingHeaders } from "./modules/metrics/utils/defaultHeaders.server";
import BannerBlock from "./modules/pageBlocks/components/blocks/marketing/banner/BannerBlock";
import PullToRefreshWrapper from "./modules/userpreference/components/PullToRefresh";
import AuthService from "./modules/users/services/AuthService";
import { useDidMountEffect } from "./utils/misc";
export { serverTimingHeaders as headers };

export const handle = { i18n: "translations" };

export const links = () => {
  return [
    // { rel: "stylesheet", href: antdMobileStyles },
    // { rel: "stylesheet", href: styles },
    // { rel: "stylesheet", href: globalStyles },
    { rel: "stylesheet", href: antdGlobalStyles },
    { rel: "stylesheet", href: antdstyles },
    { rel: "preconnect", href: "https://fonts.bunny.net" },
    { rel: "stylesheet", href: tailwindStyles },
    { rel: "stylesheet", href: globalStyles },

    { rel: "stylesheet", href: forkedStyles },
    // { rel: "preconnect", href: "https://hop.forked.cooking" },
    // { rel: "preconnect", href: "https://cse.google.com" },
    {
      rel: "preload",
      as: "stylesheet",
      href: "https://fonts.bunny.net/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap",
    },
    // { rel: "preconnect", href: "https://fonts.googleapis.com" },
    // { rel: "preconnect", href: "https://fonts.gstatic.com", crossorigin: true },
    {
      rel: "stylesheet",
      href: "https://fonts.bunny.net/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap",
    },
  ];
};
export const shouldRevalidate: ShouldRevalidateFunction = ({
  actionResult,
  currentParams,
  currentUrl,
  defaultShouldRevalidate,
  formAction,
  formData,
  formEncType,
  formMethod,
  nextParams,
  nextUrl,
}) => {
  if (currentUrl.pathname === "/logout") {
    return true;
  }
  //check if the array of shouldRevalidate includes either user or userRecipes
  if (Array.isArray(actionResult?.shouldRevalidate)) {
    const filteredArray = actionResult?.shouldRevalidate?.filter((value) => ["root", "user"].includes(value));
    if (filteredArray.length > 0) {
      return true;
    }
  }
  if (actionResult?.shouldRevalidate) {
    return false;
  }

  if (currentUrl.pathname === nextUrl.pathname) {
    return false;
  }

  return defaultShouldRevalidate;
};
export const loader = async ({ request, params }: LoaderFunctionArgs) => {
  return loadRootData({ request, params });
};

export const meta: MetaFunction<typeof loader> = ({ data }) => data?.metatags ?? [];

function Document({ children, lang = "en", dir = "ltr" }: { children: React.ReactNode; lang?: string; dir?: string }) {
  let isBot = useIsBot();
  const { t } = useTranslation();
  const location = useLocation();
  const rootData = useRootData();
  const [lastLocation, setLastLocation] = useState("");
  const matches = useMatches();
  const actionData = useActionData();
  const [searchParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const navigation = useNavigation();
  const [cdnUrl, setCDNUrl] = useState(rootData?.ENV?.CDN_IMG);
  const locationWithoutTrailingSlash = useMemo(() => {
    return location.pathname.endsWith("/") ? location.pathname.slice(0, -1) : location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    setCDNUrl(window.ENV.CDN_IMG);
  }, []);

  useDidMountEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  const tagManagerArgs = useMemo(() => {
    return {
      gtmId: rootData.appConfiguration?.analytics.gtmId,
      auth: rootData.appConfiguration?.analytics.gtmAuth,
      preview: rootData.appConfiguration?.analytics.gtmPreview,
    };
  }, [rootData.appConfiguration?.analytics.gtmAuth, rootData.appConfiguration?.analytics.gtmId, rootData.appConfiguration?.analytics.gtmPreview]);
  const tagManagerArgsString = useMemo(() => {
    return JSON.stringify(tagManagerArgs);
  }, [tagManagerArgs]);
  const initializeGtm = useCallback(() => {
    if (!initialized && !isBot) {
      TagManager.initialize(tagManagerArgs);
      setInitialized(true);
    }
  }, [initialized, tagManagerArgs, isBot]);

  const hasAnalyticsConsent = useMemo(() => {
    return CookieHelper.hasConsent(rootData.userSession, CookieCategory.ANALYTICS);
  }, [rootData.userSession]);
  useEffect(() => {
    // if (tagManagerArgsString && tagManagerArgsString.length > 0 && hasAnalyticsConsent) {
    initializeGtm();
    // }
  }, [tagManagerArgsString, hasAnalyticsConsent]);
  useEffect(() => {
    const searchParamRef = searchParams.get("ref");
    if (searchParamRef) {
      // need to set the ref cookie
      // need to set the searchParams to remove the ref
      const paramsClone = new URLSearchParams(searchParams.toString());
      paramsClone.delete("ref");
      setSearchParams(paramsClone, { replace: true });
    }
    if (lastLocation == location.pathname) {
      return;
    }
    const routeMatch = matches.find((m) => m.pathname == location.pathname);
    if (CookieHelper.hasConsent(rootData.userSession, CookieCategory.ANALYTICS)) {
      setLastLocation(location.pathname);
    }

    async function addView() {
      let params = searchParams ? "?" + searchParams.toString() : "";
      AnalyticsHelper.addPageView({
        url: location.pathname + params,
        route: routeMatch?.id,
        rootData,
      });
    }
    addView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLocation, location, rootData, actionData]);
  useEffect(() => {
    if (navigation.state === "submitting") {
      const formdata = navigation.formData;
      const action = formdata.get("action");
      if (action === "setCookieConsent") {
        const cookies = formdata.getAll("cookies[]");

        // gtag("consent", "default", {
        //   ad_storage: cookies.includes(CookieCategory.ADVERTISEMENT) ? "granted" : "denied",
        //   analytics_storage: cookies.includes(CookieCategory.ANALYTICS) ? "granted" : "denied",
        //   // functionality_storage: cookies.includes(CookieCategory.FUNCTIONAL) ? "granted" : "denied"
        // });
      }
    }
  }, [navigation]);

  // useSWEffect();
  return (
    <html lang={lang} dir={dir} key={rootData.userSession?.lng} className={clsx(rootData.userSession?.lightOrDarkMode === "dark" ? "dark" : "", "h-full w-full scroll-smooth antialiased")}>
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <Meta />
        <Links />
        <link rel="manifest" href="/resources/manifest.webmanifest" />
        <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no" />
        <meta name="theme-color" content={`${rootData.colors?.lightPrimaryColor}`} media="(prefers-color-scheme: light)" />
        <meta name="theme-color" content={`${rootData.colors?.darkPrimaryColor}`} media="(prefers-color-scheme: dark)" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        {/* <link rel="apple-touch-icon" type="image/png" sizes="167x167" href="/icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="/icons/apple-touch-icon-180.png" /> */}
        <link rel="apple-touch-icon" href={`${cdnUrl}media/icons/apple-icon-180.png`} />
        <link rel="icon" type="image/svg+xml" href={`${cdnUrl}media/icons/favicon.svg`} />
        <link rel="icon" type="image/png" href={`${cdnUrl}media/icons/favicon.png`} />
        <link rel={"canonical"} href={`https://www.forked.cooking${locationWithoutTrailingSlash}`} />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2048-2732.jpg`}
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2732-2048.jpg`}
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1668-2388.jpg`}
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2388-1668.jpg`}
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1536-2048.jpg`}
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2048-1536.jpg`}
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1668-2224.jpg`}
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2224-1668.jpg`}
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1620-2160.jpg`}
          media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2160-1620.jpg`}
          media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1284-2778.jpg`}
          media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2778-1284.jpg`}
          media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1170-2532.jpg`}
          media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2532-1170.jpg`}
          media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1125-2436.jpg`}
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2436-1125.jpg`}
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1242-2688.jpg`}
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2688-1242.jpg`}
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-828-1792.jpg`}
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1792-828.jpg`}
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1242-2208.jpg`}
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-2208-1242.jpg`}
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-750-1334.jpg`}
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1334-750.jpg`}
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-640-1136.jpg`}
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href={`${cdnUrl}media/icons/apple-splash-1136-640.jpg`}
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        {rootData.appConfiguration?.reviews?.trustpilot && (
          <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
        )}
        {rootData.appConfiguration?.auth.recaptcha.enabled && <script src="https://www.google.com/recaptcha/api.js" async defer></script>}
        {location.pathname.includes("/campaigns") && <link rel="stylesheet" href="https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css" />}

        {rootData.appConfiguration?.affiliates?.rewardful && (
          <Fragment>
            <script
              async
              id="rewardful-init"
              dangerouslySetInnerHTML={{
                __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
              }}
            />
            <script async src="https://r.wdfl.co/rw.js" data-rewardful={rootData.appConfiguration.affiliates.rewardful.apiKey}></script>
          </Fragment>
        )}


      </head>

      <body
        className={clsx("h-full min-h-full w-full flex-col scrollbar-hide"
          // location.pathname.startsWith("/app") || location.pathname.startsWith("/admin") ? "bg-slate-900" : "bg-white dark:bg-slate-900"
        )}
      >
        {rootData.impersonatingSession && (
          <BannerBlock
            item={{
              style: "top",
              text: t("account.session.impersonating", { 0: rootData.impersonatingSession.toUser.email, 1: rootData.impersonatingSession.fromUser.email }),
              cta: [{ text: t("account.session.logout"), href: "/logout" }],
            }}
          />
        )}
        {rootData.featureFlags?.includes("maintenance") && !location.pathname.startsWith("/admin") && !location.pathname.startsWith("/login") ? (
          <FeatureFlagTracker flag="maintenance">
            <PageMaintenanceMode />
          </FeatureFlagTracker>
        ) : (
          children
        )}

        {!rootData.debug && rootData.appConfiguration?.analytics.enabled && (
          <>
            {rootData.appConfiguration?.analytics.simpleAnalytics && (
              <>
                <script async defer src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
                <noscript>
                  <img
                    src="https://queue.simpleanalyticscdn.com/noscript.gif"
                    alt="privacy-friendly-simpleanalytics"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </noscript>
              </>
            )}

            {/* {rootData.appConfiguration?.analytics.plausibleAnalytics && (
              <>
                <script defer data-domain={rootData.domainName} src="https://plausible.io/js/script.js"></script>
              </>
            )} */}

            {/* {CookieHelper.hasConsent(rootData.userSession, CookieCategory.ADVERTISEMENT) && (
              <>
                {rootData.appConfiguration?.analytics.googleAnalyticsTrackingId && (
                  <>
                    <script async src={`https://www.googletagmanager.com/gtag/js?id=${rootData.appConfiguration?.analytics.googleAnalyticsTrackingId}`} />
                    <script
                      async
                      id="gtag-init"
                      dangerouslySetInnerHTML={{
                        __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${rootData.appConfiguration?.analytics.googleAnalyticsTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
                      }}
                    />
                  </>
                )}
              </>
            )} */}
          </>
        )}

        {rootData.chatWebsiteId &&
          (["/app", "/admin"].some((p) => location.pathname.startsWith(p)) || CookieHelper.hasConsent(rootData.userSession, CookieCategory.FUNCTIONAL)) && (
            <div
              dangerouslySetInnerHTML={{
                __html: `<script type="text/javascript">window.$crisp=[];window.CRISP_WEBSITE_ID="${rootData.chatWebsiteId}";(function(){d = document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>`,
              }}
            ></div>
          )}

        <FloatingLoader />
        <ScrollRestoration />
        {isBot ? null : <Scripts />}
        {rootData.appConfiguration?.cookies.enabled && <CookieConsentBanner />}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(rootData.ENV)}`,
          }}
        />
      </body>
    </html>
  );
}

export const action: ActionFunction = async ({ request }) => {
  const userInfo = await getUserInfo(request);
  const form = await request.formData();
  const action = form.get("action");
  const redirect = form.get("redirect")?.toString();
  if (action === "toggleLightOrDarkMode") {
    const current = userInfo.lightOrDarkMode ?? "dark";
    const lightOrDarkMode = current === "dark" ? "light" : "dark";
    return createUserSession(
      {
        ...userInfo,
        lightOrDarkMode,
      },
      redirect
    );
  }
  if (action === "setLocale") {
    const lng = form.get("lng")?.toString() ?? "";
    if (userInfo.userId) {
      const user = await getUser(userInfo.userId);
      if (user) {
        await updateUserProfile({ locale: lng }, user.id);
        await EventsService.create({
          event: "user.preferences.updated",
          tenantId: null,
          userId: user.id,
          data: {
            old: { locale: user.locale ?? "" },
            new: { locale: lng },
            user: { id: user.id, email: user.email },
          } satisfies UserPreferencesUpdatedDto,
        });
      }
    }
    return createUserSession(
      {
        ...userInfo,
        lng,
      },
      redirect
    );
  }
  if (action === "setCookieConsent") {
    const preserveParams = ["ref", "source", "utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"];
    const allowed = form.getAll("cookies[]").map((f) => f.toString());
    const cookies: { category: string; allowed: boolean }[] = [];
    allCookieCategories.forEach((item) => {
      const categoryName = CookieCategory[item];
      const isAllowed = allowed.filter((f) => f.toString() === categoryName.toString()).length > 0;
      cookies.push({ category: CookieCategory[item], allowed: isAllowed ?? item === CookieCategory.REQUIRED });
    });
    const searchParams = new URLSearchParams();
    preserveParams.forEach((param) => {
      const value = form.get(param)?.toString();
      if (value) {
        searchParams.set(param, value);
      }
    });
    return createUserSession(
      {
        ...userInfo,
        cookies,
      },
      redirect + "?" + searchParams.toString()
    );
  }
  if (action === "login") {
    return await AuthService.loginFromRequest(request, form);
  }
};

export default function App() {
  const { locale, preferences } = useLoaderData<typeof loader>();
  const { i18n } = useTranslation();
  useChangeLanguage(locale);
  return (
    <Document lang={locale} dir={i18n.dir()}>
      <AccountContextProvider>
        <UserPreferenceContextProvider defaults={{ settings: preferences }}>
          <ConfigProvider locale={enUS}>
            <PagesizeContextProvider>
              <PopoverContextProvider>
                <CalendarContextProvider>
                  <ShoppingCartContextProvider>
                    <CommandPaletteContextProvider>
                      <CookRecipeContextProvider>
                        <ForkedRecipeContextProvider>
                          <SearchContextProvider>
                            <PantryContextProvider>
                              <ShoppingListContextProvider>
                                <RecipeContextProvider>
                                  <TimerContextProvider>
                                    <RecipeSidebarContextProvider>
                                      <DashboardContextProvider>
                                        <CleanUpContextProvider>
                                          {/* <Suspense fallback={<div>Suspense</div>}> */}
                                          <PullToRefreshWrapper />
                                          {/* </Suspense> */}
                                        </CleanUpContextProvider>
                                      </DashboardContextProvider>
                                    </RecipeSidebarContextProvider>
                                  </TimerContextProvider>
                                </RecipeContextProvider>
                              </ShoppingListContextProvider>
                            </PantryContextProvider>
                          </SearchContextProvider>
                        </ForkedRecipeContextProvider>
                      </CookRecipeContextProvider>
                    </CommandPaletteContextProvider>
                  </ShoppingCartContextProvider>
                </CalendarContextProvider>
              </PopoverContextProvider>
            </PagesizeContextProvider>
          </ConfigProvider>
        </UserPreferenceContextProvider>
      </AccountContextProvider>
      <Toaster />
    </Document>
  );
}


export function ErrorBoundary() {
  return (
    <Document>
      <div className="mx-auto p-12 text-center">
        <ServerError />
      </div>
    </Document>
  );
}
