import { useEffect } from "react";
import { useCommandPaletteSelectorContext } from "../context/commandPaletteContext";
import { SidebarContextProvider } from "../context/sidebarContext";
import SidebarLayout from "../layouts/SidebarLayout";
import AppCommandPalette from "../ui/commandPalettes/AppCommandPalette";

export default function PullRefreshLayout({ layout, children }: Props) {
  const open = useCommandPaletteSelectorContext((state) => state.open);
  const openCommandPalette = useCommandPaletteSelectorContext((state) => state.openCommandPalette);
  const closeCommandPalette = useCommandPaletteSelectorContext((state) => state.closeCommandPalette);

  useEffect(() => {
    function onKeydown(event: any) {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        openCommandPalette(true);
      }
    }
    window.addEventListener("keydown", onKeydown);
    return () => {
      window.removeEventListener("keydown", onKeydown);
    };
  }, []);
  return (
    <>
      <SidebarContextProvider>
        <SidebarLayout layout={layout} onOpenCommandPalette={openCommandPalette}>
          {children}
        </SidebarLayout>
      </SidebarContextProvider>

      <AppCommandPalette isOpen={open} onClosed={closeCommandPalette} />
    </>
  );
}
