import { Transition } from "@headlessui/react";
import { useNavigation } from "@remix-run/react";
import Button from "antd-mobile/es/components/button";
import CenterPopup from "antd-mobile/es/components/center-popup";
import SpinLoading from "antd-mobile/es/components/spin-loading";
import clsx from "clsx";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";
import { useRecipeSelectorContext } from "~/components/context/recipeContext";
import { useSearchSelectorContext } from "~/components/context/searchContext";
import BrandIcon from "~/components/icons/BrandIcon";
import { useOnSearch } from "~/utils/data/useRecipeRouteData";
import { useInterval } from "~/utils/misc";

function ForkingRecipeModal() {
  const recipeFetcher = useRecipeSelectorContext((state) => state.recipeFetcher);
  const addBrokenUrl = useSearchSelectorContext((state) => state.addBrokenUrl);

  const [errorVisible, setErrorVisible] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);
  const navigation = useNavigation();
  const [recipeName, setRecipeName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const onSearch = useOnSearch();

  const wordList = useMemo(() => {
    return [
      "Loading this Recipe...",
      "Removing Ads...",
      "Simplifying Steps...",
      "Adding Ingredients...",
      "Finding Tools...",
      "Getting Nutrition...",
      "Tagging Categories...",
      "Adding Notes...",
      "Optimizing Images...",
      "Creating Timers...",
      "Adding Links...",
    ];
  }, []);

  const changeWordIndex = useCallback(() => {
    const nextIndex = wordIndex + 1;
    if (nextIndex >= wordList.length) {
      setWordIndex(0);
    } else {
      setWordIndex(nextIndex);
    }
  }, [wordIndex, wordList]);

  const closeError = () => {
    setErrorVisible(false);
    setSubmitting(false);
  };

  const forkLoading = (
    <div className="relative mb-4 h-24 w-24">
      <ClientOnly fallback={<BrandIcon className="absolute z-20 mb-4 p-2" />}>
        {() => (
          <>
            <BrandIcon className="absolute z-20 mb-4 p-2" />
            <SpinLoading className="z-10" style={{ "--size": "96px" }} />
          </>
        )}
      </ClientOnly>
    </div>
  );

  useEffect(() => {
    if (recipeFetcher?.state === "submitting") {
      setSubmitting(true);
    } else if (recipeFetcher.data?.error) {
      setSubmitting(false);
    }
  }, [recipeFetcher?.state]);

  useEffect(() => {
    if (!onSearch) {
      setSubmitting(false);
    }
  }, [onSearch]);
  const isSubmitting = useMemo(() => {
    return recipeFetcher.state === "submitting" || navigation.location?.pathname.match(/\/app\/\w+\/recipe\/\w+/)?.length > 0;
  }, [recipeFetcher.state, navigation.location]);

  useEffect(() => {
    if (!isSubmitting) {
      setWordIndex(0);
    }
  }, [isSubmitting]);

  const hasError = useMemo(() => {
    if (submitting) {
      return false;
    }
    return recipeFetcher.data?.error;
  }, [submitting, recipeFetcher.data?.error]);

  const goToUrl = useCallback(() => {
    window.open(recipeFetcher.data?.url, "_blank");
    setSubmitting(false);
    closeError();
  }, [recipeFetcher.data?.url]);

  useEffect(() => {
    setErrorVisible(hasError);
    if (recipeFetcher.data?.url) {
      addBrokenUrl(recipeFetcher.data?.url);
    }
  }, [hasError]);

  useEffect(() => {
    if (recipeFetcher.state === "submitting") {
      try {
        const data = Object.fromEntries(recipeFetcher?.submission?.formData ?? {});
        const rawData = data.data ? JSON.parse(data.data) : { name: "reparse" };
        setRecipeName(rawData.name);
      } catch (e) {
        console.log('error: ', e);
      }
    }
  }, [recipeFetcher.state]);

  const clearRecipeName = () => {
    setRecipeName("");
  };

  useInterval(changeWordIndex, isSubmitting ? 6000 : null);

  return (
    <>
      <CenterPopup
        // target={".forked-search-results"}
        // className={"!absolute"}
        destroyOnClose={true}
        visible={submitting}
        afterClose={clearRecipeName}
      >
        <div className="flex h-full w-full flex-1 flex-col items-center justify-center px-2 py-10 text-center">
          {/* SpinLoading does */}
          {forkLoading}
          <h2 className="line-clamp-2 font-lexend text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {recipeName}
            <br />
          </h2>
          <p className="mt-6 text-xl leading-8 text-gray-600">
            Sounds <span className="font-lexend font-bold tracking-tighter">forking</span> delicious!
          </p>

          <p className="mt-6 w-2/3 text-lg leading-8 text-gray-600">Hang tight for just a minute. </p>
          <div className="relative mt-6 h-10 w-full flex-none overflow-hidden truncate text-2xl">
            {wordList.map((word, index) => {
              return (
                <Transition
                  key={word}
                  show={index === wordIndex}
                  appear={true}
                  enter={clsx("transition-[transform,opacity] delay-100")}
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave={clsx("transition-[transform,opacity]")}
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 -translate-y-full"
                  className={clsx("absolute h-full w-full overflow-hidden text-theme-600 duration-1000")}
                >
                  {word}
                </Transition>
              );
            })}
          </div>
          {/* <div className="mt-10 flex items-center justify-center gap-x-6">{/* TODO: work on the loader<LoadingRecipe /> }</div> */}
        </div>
      </CenterPopup>
      <CenterPopup
        // target={".forked-search-results"}
        // className={"!absolute"}
        destroyOnClose={true}
        visible={errorVisible}
        onMaskClick={closeError}
      >
        <div className="flex h-full w-full flex-1 flex-col items-center justify-center px-2 py-10 text-center">
          {/* SpinLoading does */}
          <div className="relative mb-4 h-24 w-24">
            <BrandIcon className="absolute z-20 mb-4 p-2" />
          </div>
          <h2 className="line-clamp-2 font-lexend text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Well that's forking frustrating...
            <br />
          </h2>
          {/* TODO: add the bookmarklet here for users to grab and put in their browser */}
          <p className="mt-6 text-xl leading-8 text-gray-600">
            Looks like we aren't quite sure how to find a recipe on that page (yet!). We made a note and are working on it. In the meantime, would you like to:
          </p>
          <div>
            <Button onClick={goToUrl}>Go to: {recipeFetcher?.data?.name ?? recipeFetcher?.data?.url}</Button>
            <Button onClick={closeError}>Pick Something Else</Button>
          </div>
        </div>
      </CenterPopup>
    </>
  );
}

export default memo(ForkingRecipeModal);
