import { useFetcher } from "@remix-run/react";
import Button from "antd-mobile/es/components/button";
import List from "antd-mobile/es/components/list";
import NavBar from "antd-mobile/es/components/nav-bar";
import Popup from "antd-mobile/es/components/popup";
import clsx from "clsx";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { useCallback } from "react";
import { useShoppingCartSelectorContext } from "~/components/context/shoppingCartContext";
import AppLayout from "~/components/layouts/AppLayout";
import Ingredient from "./Ingredient";

dayjs.extend(calendar);

export default function ShoppingCart() {
  const shoppingCartFetcher = useFetcher();
  const open = useShoppingCartSelectorContext((state) => state.open);
  const closeShoppingCart = useShoppingCartSelectorContext((state) => state.closeShoppingCart);

  const handleSave = useCallback(() => {
    closeShoppingCart && closeShoppingCart();
  }, [closeShoppingCart]);

  const tabbar = (
    <div className="flex flex-none flex-col p-4">
      <Button color="primary" onClick={handleSave}>
        Submit Shopping Cart
      </Button>
    </div>
  );

  const listHeader = (
    <div className="flex items-center justify-between">
      <div className="flex-1 truncate">Shopping Cart</div>
    </div>
  );

  const navbar = (
    <NavBar className="relative bg-theme-300" style={{ "--height": "4rem" }} back={true} backArrow={true} right={null} onBack={closeShoppingCart}>
      <div className={clsx("flex h-full flex-col justify-center")}>Shopping Cart</div>
    </NavBar>
  );

  const leftActions = [
    {
      key: "pantry",
      text: "In Pantry",
      color: "primary",
    },
  ];
  const rightActions = [
    {
      key: "shop",
      text: "Add to Shopping List",
      color: "warning",
    },
  ];
  const ingredients = [];
  const ingredientList = ingredients.map((ingredient, index) => {
    const ingredientAdjusted = { ...ingredient, quantity: ingredient.quantity * ingredientRatio };
    return <Ingredient key={index} ingredient={ingredientAdjusted} rightActions={rightActions} leftActions={leftActions} />;
  });

  return (
    <Popup
      className={"shopping-cart-popup w-full sm:w-1/2 md:w-1/3"}
      visible={open}
      onMaskClick={closeShoppingCart}
      position="right"
      bodyStyle={{ width: "auto" }}
    >
      <AppLayout navbar={navbar}>
        <div className="flex flex-1 flex-col overflow-y-auto">
          <div className="flex flex-1 flex-col">
            <List header={listHeader}>{ingredientList}</List>
          </div>
          {tabbar}
        </div>
      </AppLayout>
    </Popup>
  );
}
