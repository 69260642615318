import { useState } from "react";
import { SubscriptionPriceDto } from "~/application/dtos/subscriptions/SubscriptionPriceDto";
import { SubscriptionProductDto } from "~/application/dtos/subscriptions/SubscriptionProductDto";
import { PricingModel } from "~/application/enums/subscriptions/PricingModel";
import { SubscriptionBillingPeriod } from "~/application/enums/subscriptions/SubscriptionBillingPeriod";
import currencies from "~/application/pricing/currencies";
import CurrencyToggle from "~/components/ui/toggles/CurrencyToggle";
import { useRootData } from "~/utils/data/useRootData";
import { TenantSubscriptionWithDetails } from "~/utils/db/tenantSubscriptions.db.server";
import { getYearlyDiscountNumber } from "~/utils/helpers/PricingHelper";
import clsx from "~/utils/shared/ClassesUtils";
import Plan from "./Plan";
import ToggleBillingPeriod from "./ToggleBillingPeriod";

interface Props {
  items: SubscriptionProductDto[];
  tenantSubscription?: TenantSubscriptionWithDetails | null;
  canSubmit?: boolean;
  className?: string;
  customOnClick?: () => void;
  activeSubscriptions?: string[];
  startTrial?: () => void;
}
export default function PlansInd({ items, tenantSubscription, canSubmit, className, customOnClick, activeSubscriptions, startTrial }: Props) {
  const { appConfiguration,usedTrial } = useRootData();
  const [products] = useState(items);
  const [currency, setCurrency] = useState(currencies.find((f) => f.default)?.value ?? "");

  const [billingPeriod, setBillingPeriod] = useState<SubscriptionBillingPeriod>(
    appConfiguration.subscription?.defaultBillingPeriod ?? SubscriptionBillingPeriod.MONTHLY
  );

  function toggleBillingPeriod() {
    if (billingPeriod === SubscriptionBillingPeriod.MONTHLY) {
      setBillingPeriod(SubscriptionBillingPeriod.YEARLY);
    } else {
      setBillingPeriod(SubscriptionBillingPeriod.MONTHLY);
    }
  }

  function getRecurringPrices() {
    let prices: SubscriptionPriceDto[] = [];
    products
      .filter((f) => f.model !== PricingModel.ONCE)
      .forEach((product) => {
        const recurringPrices = product.prices;
        prices = prices.concat(recurringPrices);
      });
    return prices;
  }

  function alreadyOwned(plan: SubscriptionProductDto) {
    const found = tenantSubscription?.products.find((f) => f.subscriptionProductId === plan.id);
    if (found) {
      return true;
    }
    return false;
  }

  function checkUpgradeDowngrade(plan: SubscriptionProductDto) {
    const existing = tenantSubscription?.products.find((f) => f)?.subscriptionProduct;
    if (existing) {
      if (plan.order > existing.order) {
        return { upgrade: true };
      } else {
        return { downgrade: true };
      }
    }
  }

  const multiplePlans = products.length > 1;
  const plans = [];
  products.forEach((plan) => {
    const monthlyprice = plan.prices.find((f) => f.billingPeriod === SubscriptionBillingPeriod.MONTHLY);
    const yearlyprice = plan.prices.find((f) => f.billingPeriod === SubscriptionBillingPeriod.YEARLY);
    let discount = undefined;
    if (monthlyprice?.price && yearlyprice?.price) {
      discount = 100 - (Number(yearlyprice.price) * 100) / (Number(monthlyprice.price) * 12);
    }

    plan.prices.forEach((price) => {
      plans.push({ plan, price, discount: price.billingPeriod === SubscriptionBillingPeriod.YEARLY ? discount : undefined });
    });
  });

  return (
    <><div className={clsx(className, items.length === 1 && "mx-auto max-w-2xl")}>
    <div className="flex items-center justify-between">
      <div>{currencies.filter((f) => !f.disabled).length > 1 && <CurrencyToggle value={currency} setValue={setCurrency} />}</div>
      <div>
        {getRecurringPrices().length > 0 && (
          <ToggleBillingPeriod
            size="sm"
            billingPeriod={billingPeriod}
            toggleBillingPeriod={toggleBillingPeriod}
            yearlyDiscount={getYearlyDiscountNumber(getRecurringPrices(), currency)}
          />
        )}
      </div>
    </div>
    <div
      className={clsx(
        "plans grid gap-6 lg:gap-3",
        items.length === 2 && "lg:grid-cols-2 xl:grid-cols-2",
        items.length === 3 && "lg:grid-cols-3 xl:grid-cols-3",
        items.length === 4 && "lg:grid-cols-4 xl:grid-cols-4",
        items.length === 5 && "lg:grid-cols-5 xl:grid-cols-5",
        items.length === 6 && "lg:grid-cols-3 xl:grid-cols-3",
        items.length === 7 && "lg:grid-cols-3 xl:grid-cols-3",
        items.length >= 8 && "lg:grid-cols-3 xl:grid-cols-3"
      )}
    >
      {products.map((plan, index) => {
        return (
          <Plan
            key={index}
            className={clsx((products.length === 1 || (products.length === 4 && index === 3)) && "lg:col-span-1")}
            product={plan}
            title={plan.title}
            description={plan.description ?? undefined}
            badge={'Includes 14 days free!'}
            // features={plan.features}
            features={[]}
            billingPeriod={billingPeriod}
            currency={currency}
            prices={plan.prices}
            activeSubscriptions={activeSubscriptions}
            model={plan.model}
            usageBasedPrices={plan.usageBasedPrices}
            alreadyOwned={alreadyOwned(plan)}
            // tenantSubscription={tenantSubscription}
            canSubmit={canSubmit}
            isUpgrade={checkUpgradeDowngrade(plan)?.upgrade}
            isDowngrade={checkUpgradeDowngrade(plan)?.downgrade}
            customOnClick={customOnClick}
          />
        );
      })}
    </div>
  </div>
  {/* add a section that says "OR" */}
  <div className="flex items-center justify-center my-10">
    <div className="flex-grow border-t-2 border-gray-200"></div>
    <div className="mx-4 text-gray-500">OR</div>
    <div className="flex-grow border-t-2 border-gray-200"></div>
  </div>

    <div className={clsx(className, items.length === 1 && "mx-auto max-w-2xl")}>
      <div
        className={clsx(
          "plans grid gap-6 lg:gap-3"
          
        )}
      >
        {!usedTrial && <div
              className={clsx(
                'lg:z-10 shadow-sm' ,
                'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    className={clsx(
                      'text-theme-600',
                      'text-lg font-semibold leading-8'
                    )}
                  >
                    Free Trial
                  </h3>
                  {/* <p className="rounded-full bg-theme-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-theme-600">
                      Most popular
                    </p> */}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600">14 days of our whole app, no restrictions, and no card required.</p>
                
              </div>
              <div className="mt-4 space-y-2">
                <button
                  type="button"
                  className="group flex w-full items-center justify-center space-x-2 rounded-md border border-theme-200 bg-theme-600 px-8 py-2 text-sm font-medium text-accent-700 hover:bg-theme-700 hover:text-accent-100 dark:border-theme-700 dark:bg-theme-900 dark:text-white hover:dark:bg-theme-800"
                  onClick={startTrial}
                >
                  <span>
                    <span className="text-white">Start Now</span>
                  </span>
                </button>
              </div>
            </div>}
        
            
        </div>
    </div>
    </>
  );
}
