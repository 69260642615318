import Checkbox from "antd-mobile/es/components/checkbox";
import List from "antd-mobile/es/components/list";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import Toast from "antd-mobile/es/components/toast";
import convert, { convertMany } from "convert";
import Fraction from "fraction.js";
import { useCallback, useMemo, useRef } from "react";
import { usePagesizeSelectorContext } from "~/components/context/pageSizeContext";
import { usePantrySelectorContext } from "~/components/context/pantryContext";
import { useShoppingListSelectorContext } from "~/components/context/shoppingListContext";
import CheckEmptyCircle from "~/components/ui/icons/CheckEmptyCircleIcon";
import { convertUnits, shortPluralUnits, shortUnits } from "~/utils/db/recipe-ingredient-parser-v2/units";

interface IngredientProps {
  ingredient: {
    title: string;
    items: {
      quantity: number;
      unit: string;
      ingredient: string;
      comment: string;
    }[];
  };
  leftActions: any[];
  rightActions: any[];
  group?: string;
}

export default function ShoppingIngredient({ ingredient, leftActions, rightActions, group }: IngredientProps) {
  const checkBoxRef = useRef();
  const sizes = usePagesizeSelectorContext((state) => state.sizes);

  const isLarge = useMemo(() => {
    return sizes.lg;
  }, [sizes.lg]);
  const pantryItems = usePantrySelectorContext((state) => state.pantryItems);
  const handleAddToPantry = usePantrySelectorContext((state) => state.handleAddToPantry);

  const ingredientRef = useRef();

  const completeShoppingListItem = useShoppingListSelectorContext((state) => state.completeShoppingListItem);
  const uncompleteShoppingListItem = useShoppingListSelectorContext((state) => state.uncompleteShoppingListItem);

  const { title, items, id } = ingredient;

  const pantryItem = useMemo(() => {
    return pantryItems.find((item) => item.ingredient === ingredient.title);
  }, [ingredient.title, pantryItems]);

  let converted;
  if (items.length > 1) {
    const quantityUnits = [...new Set(items.map((i) => `${i.unit}`))].filter((u) => u && u !== "null");
    const quantities = items
      .map((i) => {
        if (i.unit === "pinch") {
          return `${i.quantity / 8}teaspoon`;
        }
        if (i.unit === "large") {
          return `${i.quantity}`;
        }
        if (!i.unit) {
          return `${i.quantity}`;
        }
        if (!i.quantity) {
          return `1${i.unit}`;
        }
        return `${i.quantity}${i.unit}`;
      })
      .join(" ");
    if (quantityUnits.length > 1) {
      try {
        converted = convertMany(quantities).to("best", "imperial");
      } catch (e) {
        console.log("quantityUnits: ", quantityUnits);
        console.log(e);
      }
    } else {
      if (convertUnits[quantityUnits[0]]) {
        converted = convertMany(quantities).to(quantityUnits[0]);
        converted = { quantity: converted, unit: quantityUnits[0] };
      } else {
        converted = {
          quantity: items.reduce((total, item) => {
            if (item) {
              return total + item.quantity;
            }
            return total;
          }, 0),
          unit: quantityUnits[0],
        };
      }
    }
  } else {
    const { quantity, unit } = items[0];
    if (unit === "pinch") {
      converted = { quantity: quantity / 8, unit: "teaspoon" };
    } else {
      converted = { quantity, unit };
    }
  }
  const checkQuantity = converted?.quantity ?? converted;

  let quantity;
  if (checkQuantity > 0) {
    const quantityFraction = new Fraction(checkQuantity).toFraction(true);

    quantity = quantityFraction;
    if (quantity.split(" ").length > 0) {
      //need to wrap the second item in a span with a class
      const split = quantity.split(" ");
      //if either is a fraction, wrap it in a span with a class
      quantity = split.map((s) => {
        if (s.includes("/")) {
          return <span className="diagonal-fractions">{s}</span>;
        }
        return s;
      });
    }
  }
  //TODO: format the unit (small, large, plural)
  let unit = converted?.unit ?? null;
  if (unit) {
    if (convertUnits[unit]) {
      const lookup = convertUnits[unit];
      unit = checkQuantity > 1 ? shortPluralUnits[lookup] : shortUnits[lookup];
    } else if (shortUnits[unit]) {
      unit = checkQuantity > 1 ? shortPluralUnits[unit] : shortUnits[unit];
    } else if (unit === "undefined") {
      unit = null;
    }
  }

  const pantryAmount = useMemo(() => {
    if (!pantryItem) {
      return null;
    }
    const { quantity, unit } = pantryItem;
    const quantityFraction = new Fraction(quantity).toFraction(true);
    let qtyArr = [];
    if (quantityFraction.split(" ").length > 0) {
      //need to wrap the second item in a span with a class
      const split = quantityFraction.split(" ");
      //if either is a fraction, wrap it in a span with a class
      qtyArr = split.map((s) => {
        if (s.includes("/")) {
          return <span className="pr-1 diagonal-fractions">{s}</span>;
        }
        return s;
      });
    }
    return qtyArr;
  }, [pantryItem]);

  const pantryEnough = useMemo(() => {
    if (!pantryItem) {
      return false;
    }
    const { quantity, unit: pUnit } = pantryItem;
    if (!quantity || !pUnit) {
      return false;
    }
    try {
      if (unit) {
        //need to convert to compare
        const conversion = convert(quantity, pUnit).to(unit);
        return parseFloat(conversion) >= parseFloat(checkQuantity);
      } else {
        return parseFloat(quantity) >= parseFloat(checkQuantity);
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }, [checkQuantity, pantryItem, unit]);

  const pantryItemUnit = useMemo(() => {
    if (!pantryItem) {
      return null;
    }
    let unit = pantryItem?.unit;
    if (convertUnits[unit]) {
      const lookup = convertUnits[unit];
      unit = checkQuantity > 1 ? shortPluralUnits[lookup] : shortUnits[lookup];
    } else if (shortUnits[unit]) {
      unit = checkQuantity > 1 ? shortPluralUnits[unit] : shortUnits[unit];
    } else if (unit === "undefined") {
      unit = null;
    }
    return unit;
  }, [pantryItem?.unit]);

  const ingredientName = title;
  // const all = [unit, ingredientName].filter((n) => n).join(" ");

  const onClick = useCallback(() => {
    if (isLarge) {
      checkBoxRef.current?.toggle();
    } else {
      Toast.show({
        content: "Swipe left to complete, right to remove",
      });
    }
  }, [isLarge]);

  const handleChange = useCallback(
    (state) => {
      if (state) {
        //need to complete this ingredient
        completeShoppingListItem(ingredient.items.map((item) => item.id));
      } else {
        uncompleteShoppingListItem(ingredient.items.map((item) => item.id));
      }
    },
    [ingredient.items]
  );

  // const stepper = (
  //   <Stepper
  //     showNumber
  //     size="small"
  //     min={1}
  //     defaultValue={checkQuantity}
  //     // onChange={handleQtyChange}
  //     onChange={() => {
  //       console.log("stepper changed");
  //     }}
  //   />
  // );

  const extraSteppers = (
    <div className="flex w-24 flex-col items-center justify-center text-sm">
      <div>{quantity ?? ""}</div>
      {unit ?? ""}
    </div>
  );

  const handleClickPantry = useCallback(
    (e) => {
      //prevent the click from affecting the checkbox
      e.stopPropagation();
      if (pantryItem) {
        handleAddToPantry(pantryItem);
      }
    },
    [pantryItem]
  );

  const handleActionsRevealed = useCallback(
    (direction: "left" | "right") => {
      if (direction === "left") {
        if (leftActions.length === 1) {
          leftActions[0].onClick && leftActions[0].onClick();
        }
      } else {
        if (rightActions.length === 1) {
          rightActions[0].onClick && rightActions[0].onClick();
        }
      }
      ingredientRef.current?.close();
    },
    [leftActions, rightActions]
  );

  return (
    <SwipeAction leftActions={isLarge ? [] : leftActions} rightActions={isLarge ? [] : rightActions} ref={ingredientRef} onActionsReveal={handleActionsRevealed}>
      <List.Item
        prefix={
          isLarge && (
            <div onClick={(e) => e.stopPropagation()}>
              <Checkbox value={`${group ? group + "-" : ""}${id}`} ref={checkBoxRef} onChange={handleChange} />
            </div>
          )
        }
        className="text-lg"
        // description={ingredient.comment}
        onClick={onClick}
        arrow={false}
        extra={
          <>
            {/* {quantity} {unit} */}
            {extraSteppers}
            {/* {extra} */}
          </>
        }
        description={
          pantryItem && (
            <div className="flex items-center gap-1 text-sm" onClick={handleClickPantry}>
              {pantryEnough && <CheckEmptyCircle className="h-4 w-4 text-theme-400" />}
              {`In Pantry: `}
              {pantryAmount}
              {` ${pantryItemUnit}`}
            </div>
          )
        }
      >
        {ingredientName}
      </List.Item>
    </SwipeAction>
  );
}
