import CapsuleTabs from "antd-mobile/es/components/capsule-tabs";
import { Group as CheckboxGroup } from "antd-mobile/es/components/checkbox/group";

import List from "antd-mobile/es/components/list";
import { useCallback, useEffect, useMemo } from "react";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import CookingIngredient from "./CookingIngredient";

export default function CookingIngredientsNoPopup() {
  const recipe = useCookRecipeSelectorContext((state) => state.recipe);
  const currentRecipeId = useCookRecipeSelectorContext((state) => state.currentRecipeId);
  const currentServingRatio = useCookRecipeSelectorContext((state) => state.currentServingRatio);
  const ingredientsTab = useCookRecipeSelectorContext((state) => state.ingredientsTab);
  const setIngredientsTab = useCookRecipeSelectorContext((state) => state.setIngredientsTab);
  const ingredientsChecked = useCookRecipeSelectorContext((state) => state.ingredientsChecked);
  const setIngredientsChecked = useCookRecipeSelectorContext((state) => state.setIngredientsChecked);
  const ingredientsPrepped = useCookRecipeSelectorContext((state) => state.ingredientsPrepped);
  const setIngredientsPrepped = useCookRecipeSelectorContext((state) => state.setIngredientsPrepped);

  const ingredients = useMemo(() => {
    return recipe?.ingredients;
  }, [recipe?.ingredients]);

  const handleClick = useCallback(
    (ids) => {
      if (ingredientsTab === "prepping") {
        setIngredientsPrepped(currentRecipeId, ids);
      } else {
        setIngredientsChecked(currentRecipeId, ids);
      }
    },
    [ingredientsTab, currentRecipeId]
  );

  useEffect(() => {
    document.getElementById("ingredient-list-wrapper")?.scroll({ top: 0, behavior: "smooth" });
  }, [ingredientsTab]);

  const checkedIngredients = useMemo(() => {
    return ingredientsTab === "prepping" ? ingredientsPrepped : ingredientsChecked;
  }, [ingredientsTab, ingredientsPrepped, ingredientsChecked]);

  return (
    <div className="flex flex-1 flex-col overflow-y-auto pl-safe" id="ingredient-list-wrapper">
      <div className="nested-list sticky-items flex flex-1 flex-col">
        <List className={"ingredient-list"}>
          <CheckboxGroup value={checkedIngredients} onChange={handleClick}>
            <div className="sticky top-0 z-10 flex h-12 flex-col justify-center overflow-hidden bg-white lg:h-16">
              {/* <div className="flex items-center justify-center overflow-hidden bg-white pt-3">
                <h2 className="text-base text-xl text-black">Servings: </h2>
                <Stepper
                  value={currentRecipeServings}
                  onChange={setCurrentRecipeServings}
                  min={1}
                  max={1000}
                  aria-label={"Servings Adjustment"}
                  className="large-stepper"
                />
              </div> */}

              <CapsuleTabs className="segment medium px-4" activeKey={ingredientsTab} onChange={setIngredientsTab}>
                <CapsuleTabs.Tab className="p-0" title="Prepping" key="prepping" />
                <CapsuleTabs.Tab title="Cooking" key="cooking" />
              </CapsuleTabs>
            </div>
            {ingredients?.filter((ing) => !checkedIngredients.includes(ing.id)).length > 0 && (
              <List.Item className="sticky top-12 z-10 bg-gray-100 lg:top-16" title={ingredientsTab !== "prepping" ? "Upcoming" : "Not Picked"} />
            )}
            {ingredients
              ?.filter((ing) => !checkedIngredients.includes(ing.id))
              .map((ingredient) => {
                return (
                  <CookingIngredient
                    ingredient={{ ...ingredient, quantity: ingredient.quantity * currentServingRatio }}
                    leftActions={[]}
                    rightActions={[]}
                    key={ingredient.id}
                  />
                );
              })}
            {ingredients?.filter((ing) => checkedIngredients.includes(ing.id)).length > 0 && (
              <List.Item className="sticky top-12 z-10 bg-gray-100 lg:top-16" title={ingredientsTab !== "prepping" ? "Used" : "Picked"} />
            )}
            {ingredients
              ?.filter((ing) => checkedIngredients.includes(ing.id))
              .map((ingredient) => {
                return (
                  <CookingIngredient
                    ingredient={{ ...ingredient, quantity: ingredient.quantity * currentServingRatio }}
                    leftActions={[]}
                    rightActions={[]}
                    key={ingredient.id}
                  />
                );
              })}
          </CheckboxGroup>
        </List>
      </div>
    </div>
  );
}
