import List from "antd-mobile/es/components/list";
import ProgressBar from "antd-mobile/es/components/progress-bar";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { memo, useCallback, useMemo, useRef } from "react";
import clsx from "~/utils/shared/ClassesUtils";
import { useCookRecipeSelectorContext } from "../context/cookRecipeContext";
dayjs.extend(calendar);

function CurrentCookRecipe({ images, name, id, currentStep, steps, onClick, uuid, recipeDate, createdAt, numSteps }) {
  const openExistingRecipe = useCookRecipeSelectorContext((state) => state.openExistingRecipe);
  const cancelRecipe = useCookRecipeSelectorContext((state) => state.cancelRecipe);
  const completeRecipe = useCookRecipeSelectorContext((state) => state.completeRecipe);

  const recipeRef = useRef();

  const handleCancel = useCallback(() => {
    cancelRecipe(uuid);
  }, [uuid]);

  const handleComplete = useCallback(() => {
    completeRecipe(uuid);
  }, [uuid]);

  const handleClick = useCallback(
    (id) => {
      if (onClick) {
        onClick(id);
      }
      openExistingRecipe(uuid);
    },
    [uuid]
  );

  const leftActions = useMemo(
    () => [
      {
        key: "cancel",
        text: "Cancel",
        color: "danger",
        onClick: handleCancel,
      },
    ],
    []
  );
  const rightActions = [
    {
      key: "complete",
      text: "Complete",
      color: "primary",
      onClick: handleComplete,
    },
  ];

  const handleActionsRevealed = useCallback(
    (direction: "left" | "right") => {
      if (direction === "left") {
        if (leftActions.length === 1) {
          leftActions[0].onClick && leftActions[0].onClick();
        }
      } else {
        if (rightActions.length === 1) {
          rightActions[0].onClick && rightActions[0].onClick();
        }
      }
      recipeRef.current?.close();
    },
    [leftActions, rightActions]
  );

  const percentage = currentStep !== null && (numSteps || steps) ? Math.round(((currentStep + 1) / ((steps?.length ?? numSteps) + 2)) * 100) : null;
  const coverImage = Array.isArray(images) ? images[0] : images;
  const imageUrl = coverImage ? `${coverImage}?class=w40h40` : "https://cdn.forked.cooking/f14ae1f13645ba2d49389b840a3102b2.jpeg";
  const progress = percentage !== null && <ProgressBar percent={percentage} style={{ "--text-width": "45px" }} className="text-xs" />;
  const image = (
    <div className={clsx("flex h-10 w-10 min-w-full items-end rounded-lg bg-cover bg-center text-white")} style={{ backgroundImage: `url(${imageUrl})` }}></div>
  );
  let serveTime = null;
  if (recipeDate) {
    const date = recipeDate.date?.split("T")[0];
    const time = recipeDate.time;
    serveTime = `eat at ${dayjs(`${date} ${time}`).format("h:mm A")}`;
  }

  const updated = createdAt && (
    <div className="whitespace-nowrap">{`${dayjs(createdAt).calendar(undefined, {
      sameDay: "[Today] h:mma",
      lastDay: "[Yesterday] h:mma",
      lastWeek: "[Last] dddd",
    })}`}</div>
  );

  const title = (
    <div className="flex items-center justify-between">
      <div className="line-clamp-2 break-normal text-lg text-black">{name}</div>
      {updated}
    </div>
  );

  const listItemPrefix = image;
  return (
    <SwipeAction leftActions={leftActions} rightActions={rightActions} ref={recipeRef} onActionsReveal={handleActionsRevealed}>
      <List.Item className="!flex h-full break-all" onClick={() => handleClick(id)} prefix={listItemPrefix} title={title}>
        {/* <Ellipsis direction="end" rows={2} content={item.name} /> */}

        <div className="flex h-4 justify-between text-xs text-gray-400">
          <div>{recipeDate?.label}</div>
          <div>{serveTime}</div>
        </div>
        <div className="line-clamp-1">{progress}</div>
        <div className="flex justify-between text-xs text-gray-400">
          <div>{currentStep === 0 ? "Prep" : `Step ${currentStep}`}</div>
          <div>{""}</div>
          {/* TODO: add review stars, want user to complete by adding review */}
        </div>
      </List.Item>
    </SwipeAction>
  );
}

export default memo(CurrentCookRecipe);
