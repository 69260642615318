import { Transition } from "@headlessui/react";
import { useLocation, useMatches, useNavigate } from "@remix-run/react";
import Badge from "antd-mobile/es/components/badge";
import NavBar from "antd-mobile/es/components/nav-bar";
import Toast from "antd-mobile/es/components/toast";
import clsx from "clsx";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import { useCalendarSelectorContext } from "~/components/context/calendarContext";
import { useCommandPaletteSelectorContext } from "~/components/context/commandPaletteContext";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import { useForkedRecipeSelectorContext } from "~/components/context/forkedRecipesContext";
import { usePagesizeSelectorContext } from "~/components/context/pageSizeContext";
import { useRecipeSelectorContext } from "~/components/context/recipeContext";
import { useSearchSelectorContext } from "~/components/context/searchContext";
import { useShoppingListSelectorContext } from "~/components/context/shoppingListContext";
import UserAvatarBadge from "~/components/core/users/UserAvatarBadge";
import BrandIcon from "~/components/icons/BrandIcon";
import Forked from "~/modules/recipe/components/Forked";
import { useAppData } from "~/utils/data/useAppData";
import {BookHeart, CookingPot, Calendar, ShoppingBasket, Refrigerator} from 'lucide-react';
import {
  useAppTenantRoute,
  useCurrentCollection,
  useCurrentRecipe,
  useGuestRoute,
  useOnFrontend,
  useOnHomepage,
  useOnSearch,
  useOnShoppingList,
  useOnSplash,
} from "~/utils/data/useRecipeRouteData";
import { useRootData } from "~/utils/data/useRootData";
import { useDidMountEffect } from "~/utils/misc";
import CalendarIcon from "../icons/CalendarIcon";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import MagnifyingGlassIcon from "../icons/MagnifyingGlassIcon";
import ShoppingCartIcon from "../icons/ShoppingCartIcon";

function AppTenantNavbar({ }) {
  const dataFetcher = useRecipeSelectorContext((state) => state.dataFetcher);
  const appData = useAppData();
  const rootData = useRootData();
  const openAccount = useAccountSelectorContext((state) => state.openAccount);
  const [searching, setSearching] = useState(false);
  const setShouldInitiate = useSearchSelectorContext((state) => state.setShouldInitiate);
  const searchTerm = useSearchSelectorContext((state) => state.searchTerm);
  const setSearchTerm = useSearchSelectorContext((state) => state.setSearchTerm);
  const setGoogleResults = useSearchSelectorContext((state) => state.setGoogleResults);
  const initiated = useSearchSelectorContext((state) => state.initiated);
  const handleInitiate = useSearchSelectorContext((state) => state.handleInitiate);
  const setSearchActive = useSearchSelectorContext((state) => state.setSearchActive);
  const setSearchParams = useSearchSelectorContext((state) => state.setSearchParams);

  // const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSearch, setActiveSearch] = useState(false);
  const matches = useMatches();
  const sizes = usePagesizeSelectorContext((state) => state.sizes);
  const [prepped, setPrepped] = useState(false);

  const onFrontend = useOnFrontend();
  const onSplash = useOnSplash();
  const openCookRecipe = useCookRecipeSelectorContext((state) => state.openCookRecipe);
  const numRecipes = useCookRecipeSelectorContext((state) => state.numRecipes);

  const openChooseCook = useCookRecipeSelectorContext((state) => state.openChooseCook);
  const setThisRecipeId = useCookRecipeSelectorContext((state) => state.setThisRecipeId);
  const setThisCollectionId = useCookRecipeSelectorContext((state) => state.setThisCollectionId);
  const openUserCalendar = useCalendarSelectorContext((state) => state.openUserCalendar);
  const numUpcomingRecipes = useCalendarSelectorContext((state) => state.numUpcomingRecipes);

  const openShoppingList = useShoppingListSelectorContext((state) => state.openShoppingList);
  const shoppingListItems = useShoppingListSelectorContext((state) => state.shoppingListItems);

  const recipeData = useMemo(() => {
    return dataFetcher.data;
  }, [dataFetcher.data]);
  const openCommandPalette = useCommandPaletteSelectorContext((state) => state.openCommandPalette);

  const forkedRecipeIds = useForkedRecipeSelectorContext((state) => state.forkedRecipeIds);
  const forkedCollectionIds = useForkedRecipeSelectorContext((state) => state.forkedCollectionIds);

  // const navigation = useNavigation();
  const appTenantRoute = useAppTenantRoute();

  const recipeRoute = useGuestRoute();

  const onHomepage = useOnHomepage();

  useEffect(() => {
    if (!onSplash) {
      handleInitiate();
    }
  }, [onSplash]);

  const isLarge = useMemo(() => {
    return sizes.lg;
  }, [sizes.lg]);

  // const parentRoute = appTenantRoute?.pathname;
  // const dashboardRoute = useMemo(() => {
  //   return matches?.find((match) => match.id === "routes/app.$tenant/dashboard");
  // }, [matches]);
  const onDashboardIndex = useMemo(() => {
    return matches?.find((match) => match.id === "routes/app.$tenant/dashboard/index");
  }, [matches]);

  // const [showChat, setShowChat] = useState(false);
  const onRecipe = useMemo(() => {
    return (
      matches?.find((match) => match.id === "routes/app.$tenant/recipe/$urlId.$urlKey") ?? matches?.find((match) => match.id === "routes/recipe/$urlId.$urlKey")
    );
  }, [matches]);

  const onCollection = useMemo(() => {
    return (
      matches?.find((match) => match.id === "routes/app.$tenant/collection.$urlId.$urlKey") ??
      matches?.find((match) => match.id === "routes/collection.$urlId.$urlKey")
    );
  }, [matches]);
  const currentRecipe = useCurrentRecipe();
  const currentCollection = useCurrentCollection();
  const [recipeName, setRecipeName] = useState(currentRecipe?.name);
  const [collectionName, setCollectionName] = useState(currentCollection?.name);

  const onShoppingList = useOnShoppingList();

  useEffect(() => {
    if (onRecipe) {
      setRecipeName(currentRecipe?.name);
    }
  }, [onRecipe?.params.urlId, onRecipe?.params.urlKey]);

  useEffect(() => {
    if (onCollection) {
      console.log("currentCollection: ", currentCollection);
      setCollectionName(currentCollection?.name);
    }
  }, [onCollection?.params.urlId, onCollection?.params.urlKey]);

  const [forked, setForked] = useState(
    recipeData?.results?.forked || forkedRecipeIds?.includes(currentRecipe?.id) || forkedCollectionIds?.includes(currentCollection?.id)
  );

  const linkUrlPath = useMemo(() => {
    if (appTenantRoute) {
      return `${appTenantRoute?.pathname}/dashboard`;
    }
    return `/recipe`;
  }, [appTenantRoute]);

  const homePagePath = useMemo(() => {
    if (appTenantRoute) {
      return `${appTenantRoute?.pathname}/dashboard`;
    }
    return `/`;
  }, [appTenantRoute]);

  const linkUrlPathShort = useMemo(() => {
    if (appTenantRoute) {
      return `${appTenantRoute?.pathname}`;
    }
    return `/recipe`;
  }, [appTenantRoute, recipeRoute]);

  useEffect(() => {
    setForked(!!recipeData?.results?.forked);
  }, [recipeData?.results?.forked]);

  const onSearch = useOnSearch();
  function isCurrent(path: string): boolean {
    return location.pathname === path;
  }

  useEffect(() => {
    if (onSplash || !initiated) {
      return;
    }
    if ((!!onRecipe || !!onCollection) && !isLarge) {
      tearDownSearch();
      setSearching(false);
      setShouldInitiate(false);
    } else {
      // console.log("should initialize");
      setSearching(true);
      setTimeout(prepareSearchInput, 750);
      setShouldInitiate(true);
    }
  }, [onRecipe, onCollection, onSplash, isLarge, initiated]);

  useEffect(() => {
    if (currentRecipe) {
      setForked(currentRecipe.forked);
    }
  }, [currentRecipe?.forked]);

  useEffect(() => {
    const currentRecipeId = currentRecipe?.id;
    if (currentRecipeId) {
      setForked(forkedRecipeIds.includes(currentRecipeId));
    }
  }, [forkedRecipeIds]);

  useEffect(() => {
    if (currentCollection) {
      setForked(currentCollection.forked);
    }
  }, [currentCollection?.forked]);

  useEffect(() => {
    const collectionId = currentCollection?.id;
    if (collectionId) {
      setForked(forkedCollectionIds.includes(collectionId));
    }
  }, [forkedCollectionIds]);

  const prepareSearchInput = useCallback(() => {
    if (!prepped) {
      const searchInput = document.getElementById("gsc-i-id1");
      if (searchInput) {
        // focusSearch();
        setSearchTerm(searchInput.value);
        searchInput.addEventListener("blur", () => {
          setActiveSearch(false);
          setSearchActive(false);
        });
        searchInput.addEventListener("focus", () => {
          setActiveSearch(true);
          setSearchActive(true);
        });

        searchInput.addEventListener("input", (e) => {
          setSearchTerm(e.target?.value);
        });

        searchInput.placeholder="Search for your favorite recipes..."

        const clearButton = document.getElementById("gs_st50");
        if (clearButton) {
          clearButton.addEventListener("click", () => {
            setSearchTerm("");
            setGoogleResults([]);
            setSearchParams("");
          });
        }
        // setTimeout(focusSearch, 500);
      }
      setPrepped(true);
    }
  }, [prepped]);

  useDidMountEffect(() => {
    // get the searchInput element
    console.log("searchTerm: ", searchTerm);
    const searchInput = document.getElementById("gsc-i-id1");
    // if the searchInput element exists
    if (searchInput) {
      // if the value of the searchInput element is not the same as the searchTerm
      if (searchInput.value !== searchTerm) {
        // set the value of the searchInput element to the searchTerm
        searchInput.value = searchTerm;
        // type enter to search
        // focus on the searchInput element
        // find the button element with class gsc-search-button-v2
        const searchButton = document.querySelector(".gsc-search-button-v2");
        // if the searchButton element exists
        if (searchButton) {
          // click the searchButton element
          // searchButton.click();
        }
      }
    }
  }, [searchTerm]);

  const tearDownSearch = useCallback(() => {
    if (prepped) {
      const searchInput = document.getElementById("gsc-i-id1");
      if (searchInput) {
        searchInput.removeEventListener("focusout", () => {
          setActiveSearch(false);
          setSearchActive(false);
        });
        searchInput.removeEventListener("focus", () => {
          setActiveSearch(true);
          setSearchActive(true);
        });
        searchInput.removeEventListener("input", (e) => {
          setSearchTerm(e.target.value);
        });

        const clearButton = document.getElementById("gs_st50");
        if (clearButton) {
          clearButton.removeEventListener("click", () => {
            setSearchTerm("");
            setGoogleResults([]);
            setSearchParams("");
          });
        }
      }
      setPrepped(false);
    }
  }, [prepped]);

  const toggleSearching = useCallback(() => {
    if (!searching) {
      setTimeout(prepareSearchInput, 750);
      focusSearch();
      // console.log(`${linkUrlPath}/search`);
      navigate(`${linkUrlPath}/search`);
    }
    setSearching(!searching);
  }, [searching]);

  const focusSearch = useCallback(() => {
    // document.getElementById("gsc-i-id1")?.focus();
    setActiveSearch(true);
    setSearchActive(true);
  }, []);

  const clickSearch = () => {
    focusSearch();
    const inputElement = document.getElementById("gsc-i-id1");
    if (inputElement) {
      inputElement.focus();
      inputElement.setSelectionRange(0, 0);
    }
  };

  const backElement = (
    <>
      <div
        className={clsx(
          "anim-z lg:z-in absolute left-0 top-0 flex h-full w-16 items-center justify-center bg-white",
          !(!!onRecipe || !!onCollection) && "z-in z-10"
        )}
      >
        <span className="sr-only">{"Homepage"}</span>
        <BrandIcon className="h-10 w-10" />
      </div>

      <div
        className={clsx(
          "anim-z lg:z-out absolute left-0 top-0 flex h-full w-16 items-center justify-center bg-white",
          (!!onRecipe || !!onCollection) && "z-in"
        )}
      >
        <span className="sr-only">{"Back"}</span>
        <ChevronLeftIcon className="h-6" />
      </div>
    </>
  );
  const handleBack2 = useCallback(() => {
    if (!!onRecipe || !!onCollection) {
      if (isLarge) {
        if (onFrontend) {
          navigate(`/`);
        }
        navigate(`${homePagePath}`);
      }
      if (searchTerm.length > 0) {
        const searchParams = new URLSearchParams();
        searchParams.set("q", searchTerm);
        navigate({ pathname: `${linkUrlPath}/search`, search: "?" + searchParams.toString() });
      } else {
        // navigate(`${linkUrlPath}/search`);
        navigate(-1);
      }
    } else if (!onDashboardIndex && !onHomepage) {
      if (onFrontend) {
        navigate(`/`);
      }
      navigate(`${homePagePath}`);
    }
  }, [onRecipe, onCollection, onSearch, onDashboardIndex, onHomepage, searchTerm, linkUrlPath, onFrontend, homePagePath]);
  const handleBack = useCallback(() => {
    // is it the icon or the arrow
    if (isLarge) {
      // it's always the icon
      if (onFrontend) {
        navigate(`/`);
      } else {
        navigate(`${homePagePath}`);
      }
    } else {
      if (!!onRecipe || !!onCollection) {
        // it's the arrow
        navigate(-1);
      } else {
        // it's the icon
        if (onFrontend) {
          navigate(`/`);
        } else {
          navigate(`${homePagePath}`);
        }
      }
    }
  }, [onRecipe, onCollection, onSearch, onDashboardIndex, onHomepage, searchTerm, linkUrlPath, onFrontend, homePagePath]);

  const openShoppingCart = () => {
    openShoppingList();
  };

  const openCalendar = () => {
    openUserCalendar();
  };

  const handleClickCook = useCallback(() => {
    if (numRecipes > 0) {
      if (currentRecipe?.id) {
        setThisRecipeId(currentRecipe.id);
        setThisCollectionId(null);
      }
      openChooseCook();
    } else if (numRecipes > 0) {
      openCookRecipe();
    } else {
      Toast.show({ icon: "fail", content: "Please select a recipe to cook" });
    }
  }, [numRecipes, currentRecipe?.id]);

  useEffect(() => {
    if (!rootData.userSession.userId) {
      //need to clean the search input
      const searchInput = document.getElementById("gsc-i-id1");
      if (searchInput) {
        searchInput.value = "";
      }
    }
  }, [rootData.userSession.userId]);

  const handleClickAvatar = useCallback(() => {
    if (rootData.hasSub) {
      if (onFrontend) {
        navigate(`/app`);
      } else {
        navigate(`${appTenantRoute?.pathname}/chef/${appData?.user?.displayName}`);
        // openCommandPalette();
      }
    } else {
      openAccount("click", "navbar_account_link");
    }
  }, [rootData.hasSub, onFrontend, appTenantRoute]);

  const handleNavigateForkedHome = useCallback(() => {
    if (rootData.hasSub) {
      if (onFrontend) {
        navigate(`/`);
      } else {
        navigate(`${appTenantRoute?.pathname}/dashboard`);
        // openCommandPalette();
      }
    } else {
      navigate(`/`);
    }
  }, [rootData.hasSub, onFrontend, appTenantRoute]);

  // const titleWrapperClasses = "w-full justify-center break-normal truncate lg:line-clamp-2 lg:whitespace-normal text-base font-semibold text-gray-900";
  // const subtitleWrapperClasses = "w-full truncate";

  // const titleTransitionDuration = "duration-300";
  // const titleTransitionEnterDelay = "delay-300";
  const cart = (
    <div onClick={openShoppingCart} className={"flex"}>
      <Badge content={shoppingListItems.length} style={{ "--color": "var(--adm-color-success)", "--top": "1rem", "--right": "1rem" }}>
        <ShoppingBasket className="h-full w-16 p-4" />
      </Badge>
    </div>
  );
  const calendar = (
    <div onClick={openCalendar} className={"flex"}>
      <Badge content={numUpcomingRecipes.toString()} style={{ "--color": "var(--adm-color-success)", "--top": "1rem", "--right": "1rem" }}>
        <Calendar className="h-full w-16 p-4" />
      </Badge>
    </div>
  );
  const cooking = (
    <div onClick={handleClickCook} className={"flex"}>
      <Badge content={numRecipes > 0 && numRecipes} style={{ "--color": "var(--adm-color-success)", "--top": "1rem", "--right": "1rem" }}>
        <CookingPot className="h-full w-16 p-4" />
      </Badge>
    </div>
  );

  const showAvatar = useMemo(() => {
    if ((onRecipe || onCollection) && !isLarge) {
      return false;
    }
    return true;
  }, [onRecipe, onCollection, isLarge]);

  const extraLgActions = (
    <>
      <Transition
        show={isLarge && !!appTenantRoute}
        // enter={clsx("transition ease-in-out")}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        // leave={clsx("transition ease-in-out")}
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={clsx("flex h-full cursor-pointer items-center transition-opacity")}
      >
        {cooking}
        {calendar}
        {cart}
      </Transition>
      <div className="relative h-full w-16">
        <Transition
          show={showAvatar}
          // enter={clsx("transition ease-in-out")}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          // leave={clsx("transition ease-in-out")}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={clsx("absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center transition-opacity")}
        >
          <div onClick={handleClickAvatar} className={"flex h-full w-full items-center justify-center p-4"}>
            {rootData.user ? (
              rootData.user?.avatar ? (
                <img className="h-full w-full rounded-full" src={`${rootData.user?.avatar}?class=w40h40`} alt="User avatar" />
              ) : rootData.tenant?.icon ? (
                <img className="h-full w-full rounded-full" src={`${rootData.tenant?.icon}?class=w40h40`} alt="Tenant icon" />
              ) : rootData.tenant?.name ? (
                <span className="inline-flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-theme-900">
                  <span className="text-sm font-medium leading-none text-theme-200">{rootData.tenant?.name?.substring(0, 1)}</span>
                </span>
              ) : (
                <UserAvatarBadge avatar={undefined} />
              )
            ) : (
              <UserAvatarBadge avatar={undefined} />
            )}
          </div>
        </Transition>
        <Transition
          show={(!!onRecipe || !!onCollection) && !isLarge}
          // enter={clsx("transition ease-in-out")}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          // leave={clsx("transition ease-in-out")}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={clsx("absolute inset-0 flex h-full w-full cursor-pointer items-center justify-center transition-opacity lg:opacity-0")}
        >
          <div className={clsx("z-10 flex h-full p-4")} onClick={!onSearch || !searching ? toggleSearching : focusSearch}>
            <span className="sr-only">{"Search Recipes"}</span>
            <MagnifyingGlassIcon className="h-full rotate-90" />
          </div>
        </Transition>
      </div>
    </>
  );
  const indexTitle = (
    <div className="flex h-full items-center justify-center">
      <div className={clsx("rounded-sm px-3 py-1 text-base font-medium leading-6 transition duration-150 ease-in-out focus:outline-none")}>
        Your recipes, without the ads
      </div>
    </div>
  );
  const titles = useMemo(
    () => [
      { text: `${recipeName}`, show: !!onRecipe },
      { text: `${collectionName} Collection`, show: !!onCollection },
      { text: indexTitle, show: onHomepage },
    ],
    [recipeName, collectionName, onRecipe, onCollection, onHomepage]
  );
  const newTitle = useMemo(() => {
    const title = titles.find((title) => title.show);
    if (!title) return null;
    return (
      <h1
        // className={"w-full justify-center truncate break-normal text-base font-semibold text-gray-900 lg:line-clamp-2 lg:whitespace-normal lg:text-lg"}
        className={"w-full justify-center truncate whitespace-nowrap break-normal leading-6 lg:line-clamp-2 lg:whitespace-normal"}
      >
        {title.text}
      </h1>
    );
  }, [onHomepage, onRecipe, recipeName, onCollection, collectionName]);

  if (onSplash) {
    return null;
  }
  const rightElement = <div className={clsx("relative flex h-full items-center justify-end")}>{extraLgActions}</div>;

  const transitionTitle = (
    <>
      <div
        className={clsx(
          "searchbar anim-z absolute flex h-full w-full cursor-pointer items-center bg-white transition-opacity",
          !!onRecipe || !!onCollection ? "lg:z-in" : "z-in"
        )}
      >
        <div className={clsx("h-full w-full px-0 lg:px-64")}>
          <div className="absolute z-10 h-full" onClick={clickSearch}>
            <span className="sr-only">{"Search Recipes"}</span>
            <MagnifyingGlassIcon className="h-full rotate-90 p-4" />
          </div>
          <div className={clsx("z-9 h-full w-full ")}>
            <div className="gcse-search">
              <input className="gsc-input-box"></input>
            </div>
          </div>
        </div>
      </div>
      <div
        // show={(!!onRecipe || !!onCollection) && !isLarge}
        // // enter={clsx("transition ease-in-out")}
        // enterFrom="opacity-0"
        // enterTo="opacity-100"
        // // leave={clsx("transition ease-in-out")}
        // leaveFrom="opacity-100"
        // leaveTo="opacity-0"
        className={clsx(
          "pagetitle anim-z lg:z-out absolute flex h-full w-full cursor-pointer flex-col items-center justify-center bg-white transition-opacity",
          !!onRecipe || !!onCollection ? "z-in" : ""
        )}
      >
        <div
          key={"small-no-title"}
          // show={!isLarge}
          // // enter={clsx("transition ease-in-out", titleTransitionDuration, titleTransitionEnterDelay)}
          // enterFrom="opacity-0"
          // enterTo="opacity-100"
          // // leave={clsx("transition ease-in-out", titleTransitionDuration)}
          // leaveFrom="opacity-100"
          // leaveTo="opacity-0"
          className={clsx(
            // subtitleWrapperClasses,
            "flex w-full items-center justify-center !font-lexend text-3xl font-extrabold tracking-tighter transition-opacity "
          )}
        >
          <Forked
            forked={forked}
            id={currentRecipe?.id ?? currentCollection?.id}
            name={currentRecipe?.name ?? currentCollection?.name}
            type={currentCollection.id ? "collection" : "recipe"}
            image={`${currentRecipe ? currentRecipe.imageFilename : currentCollection.imageFilename}`}
            afterClick={setForked}
            buttonProps={{ className: "!p-1 h-9 border-0" }}
          />
          <div onClick={handleNavigateForkedHome}>Forked</div>
        </div>
        {newTitle}
        {/* {subtitle} */}
      </div>
    </>
  );

  const breadcrumbTitle = (
    <div className={clsx("anim-z lg:z-in absolute left-14 top-0 flex h-full flex-col justify-center")}>
      <div className="!font-lexend text-3xl font-extrabold tracking-tighter">Forked</div>
      {/* <BreadcrumbSimpleApp
        className="not-prose w-full"
        home="/"
        menu={[
          { title: "Docs", routePath: "/docs" },
          { title: "Components", routePath: "/docs/components" },
          {
            title: "Breadcrumbs",
            routePath: "/docs/components/breadcrumbs",
          },
        ]}
      /> */}
    </div>
  );

  return (
    <NavBar
      className={clsx(" relative")}
      style={{ "--height": "4rem" }}
      back={breadcrumbTitle}
      backArrow={backElement}
      right={rightElement}
      onBack={handleBack}
    >
      <div className={clsx("flex h-full flex-col justify-center")}>{transitionTitle}</div>
    </NavBar>
  );
}

export default memo(AppTenantNavbar);
