import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useFetcher, useSubmit } from "@remix-run/react";
import ActionSheet from "antd-mobile/es/components/action-sheet";
import Button from "antd-mobile/es/components/button";
import clsx from "clsx";
import debounce from "lodash/debounce";
import { CheckIcon, Edit2Icon } from "lucide-react";
import {
  Fragment,
  JSXElementConstructor,
  Key,
  MouseEventHandler,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import { usePagesizeSelectorContext } from "~/components/context/pageSizeContext";
import { useRecipeSelectorContext } from "~/components/context/recipeContext";
import Accordion from "~/components/layouts/accordion/Accordion";
import DotsVerticalFilledIcon from "~/components/ui/icons/DotsVerticalFilledIcon";
import AlarmIconFilled from "~/components/ui/icons/notifications/AlarmIconFilled";
import InputText, { RefInputText } from "~/components/ui/input/InputText";
import { useRootData } from "~/utils/data/useRootData";
import StepIngredients from "./StepIngredients";

export default function CheckRecipeStep({
  id,
  recipeId,
  length,
  position,
  removeStep,
  addStep,
  description,
  notes,
  ingredients,
  editing,
  checked,
  active,
  handleNext,
  handleOpen,
  stepText,
  overRideRatio,
}) {
  const ratio = useRecipeSelectorContext((state) => state.ratio);

  const [initialNotes, setInitialNotes] = useState(notes ?? "");
  const [actualDescription, setActualDescription] = useState(description);
  const [actualNotes, setActualNotes] = useState(notes ?? "");
  const popOverRef = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(notes && notes !== "");
  const [noteStatus, setNoteStatus] = useState("");
  const [hasSubmittedNotes, setHasSubmittedNotes] = useState(false);

  const ref = useRef<RefInputText>(null);
  const stepIngredientsRef = useRef(null);
  const submit = useSubmit();
  const notesFetcher = useFetcher();

  useEffect(() => {
    setActualDescription(description);
  }, [description]);

  const commentIsDifferent = useMemo(() => {
    return actualNotes !== initialNotes;
  }, [actualNotes, initialNotes]);

  const changeDescription = useCallback(
    (description) => {
      // const form = new FormData();
      // form.set("action", "update-step");
      // form.set("id", id);
      // form.set("description", description);
      // // form.set("position", position);
      // submit(form, {
      //   method: "post",
      // });
    },
    [id, submit, position]
  );

  const changeNotes = useCallback(
    (note) => {
      if (note !== initialNotes) {
        // console.log("note: ", note);
        // console.log("notes: ", initialNotes);
        const form = new FormData();
        form.set("action", "add-notes");
        form.set("id", id);
        form.set("recipeId", recipeId);
        form.set("note", note);
        form.set("position", position);
        notesFetcher.submit(form, {
          method: "post",
          action: "/api/recipe/notes",
        });
      }
    },
    [id, recipeId, notesFetcher, initialNotes]
  );

  useEffect(() => {
    if (!id) {
      if (!ref.current?.input.current?.value || ref.current?.input.current?.value === "") {
        setTimeout(() => {
          ref.current?.input.current?.focus();
          ref.current?.input.current?.setSelectionRange(0, 0);
        }, 100);
      }
    }
  }, [id, ref]);

  const debouncedChangeDescription = useMemo(() => {
    return debounce(changeDescription, 750);
  }, [changeDescription]);

  const debouncedChangeNotes = useMemo(() => {
    return debounce(changeNotes, 750);
  }, [changeNotes]);

  // const changePosition = useCallback(
  //   (position) => {
  //     const form = new FormData();
  //     form.set("action", "update-step");
  //     form.set("id", id);
  //     form.set("description", actualDescription);
  //     form.set("position", position);
  //     submit(form, {
  //       method: "post",
  //     });
  //   },
  //   [id, submit, actualDescription]
  // );

  // const debouncedChangePosition = useMemo(() => {
  //   return debounce(changePosition, 100);
  // }, [changePosition]);

  useEffect(() => {
    if (description !== actualDescription && editing) {
      debouncedChangeDescription(actualDescription);
    }
  }, [actualDescription, description]);

  useEffect(() => {
    if (initialNotes !== actualNotes) {
      debouncedChangeNotes(actualNotes);
    }
  }, [actualNotes, initialNotes]);

  // useEffect(() => {
  //   if (position && position !== originalPosition) {
  //     // changePosition(position);
  //   }
  // }, [position]);

  const handleDescriptionChange = (val) => {
    setActualDescription(val);
  };

  const handleNotesChange = (val) => {
    setActualNotes(val);
  };

  const handleRemoveStep = () => {
    removeStep && removeStep(id);
  };

  const handleAddStep = () => {
    addStep && addStep(position + 1);
  };

  const handleEdit = () => {
    console.log("stepIngredientsRef: ", stepIngredientsRef.current);
    stepIngredientsRef.current?.openEdit();
  };

  const openPopover = (targetRef: string) => {
    popOverRef.current = targetRef;
    setPopoverOpen(true);
  };

  const toggleNotes = useCallback(() => {
    setNotesOpen(!notesOpen);
  }, [notesOpen]);

  useEffect(() => {
    if (notesFetcher.state === "idle") {
      if (hasSubmittedNotes) {
        setNoteStatus("Saved");
      }
    } else if (notesFetcher.state !== "idle") {
      setNoteStatus("Saving...");
      setHasSubmittedNotes(true);
    }
  }, [notesFetcher.state]);

  const actions = [
    {
      icon: <AlarmIconFilled className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />,
      onClick: handleEdit,
      label: `Edit Step ${position + 1}`,
    },
    {
      icon: <AlarmIconFilled className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />,
      onClick: handleEdit,
      label: `Add Ingredient to Step ${position + 1}`,
    },
    {
      icon: <AlarmIconFilled className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />,
      onClick: () => console.log("report error"),
      label: `Report error`,
      danger: true,
    },

    // {
    //   icon: <XIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />,
    //   onClick: handleRemoveStep,
    //   label: `Remove Step ${position + 1}`,
    // },
  ];

  //create a dialog to mark an ingredient on a step as incorrect
  const stepCollapseTitle = (
    <div className="px-3 py-3" onClick={!active ? handleOpen : undefined}>
      <input type={"hidden"} name={`steps[${id}].position`} value={position} />
      <div className="flex items-center space-x-3">
        <div className="flex-shrink-0">
          <span className="flex-shrink-0">
            <span className={clsx("flex h-10 w-10 items-center justify-center rounded-full border-2 border-theme-500", checked && "bg-theme-500")}>
              <span className="font-extrabold text-theme-500">
                {checked ? <CheckIcon className="h-5 w-5 text-white" /> : stepText ? stepText : position + 1}
              </span>
            </span>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <h3 className="text-lg font-medium text-gray-900">
            {editing && (
              <InputText
                className="flex flex-1 font-medium text-gray-900"
                name={`title`}
                title={"Title"}
                value={actualDescription}
                withLabel={false}
                placeholder={"Title"}
                borderless={true}
                setValue={handleDescriptionChange}
                rows={2}
                onEnter={handleAddStep}
                ref={ref}
              />
            )}
            {!editing && actualDescription}
          </h3>
          {notes && <p className="text-sm text-gray-500">{notes}</p>}
        </div>

        {/* <div onClick={handleEdit}>
          <EditIconFilled className="h-5 w-5" />
        </div> */}
        {/* <StepEdit actions={actions} /> */}
      </div>
    </div>
  );

  return (
    // <Transition
    //   appear
    //   show={true}
    //   as={Fragment}
    //   enter="transition ease duration-300 delay-200"
    //   enterFrom="opacity-0"
    //   enterTo="opacity-100"
    //   leave="transition ease duration-300"
    //   leaveFrom="opacity-100"
    //   leaveTo="opacity-0"
    // >
    <section aria-label={`Recipe Step ${position + 1}`}>
      <div className={clsx("recipe-step nested-list rounded-lg shadow-sm", active ? "bg-theme-100" : "bg-white")}>
        <Accordion open={active} title={stepCollapseTitle} showArrow={false}>
          <div className="flex flex-col">
            <div className="-mt-px flex divide-x divide-gray-200">
              {/* TODO: add photos to steps <div className="flex w-0 flex-1">
                <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                  <div className="flex -space-x-1 overflow-hidden">
                    <img
                      className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <img
                      className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <img
                      className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                      src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                      alt=""
                    />

                    <PlusIcon className="inline-block h-6 w-6 rounded-full bg-white ring-2 ring-white" aria-hidden="true" />
                  </div>
                </div>
              </div> */}
              {id !== "na" && (
                <div className="-ml-px flex w-0 flex-1">
                  <div
                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                    onClick={handleEdit}
                  >
                    <Edit2Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    Edit
                  </div>
                </div>
              )}
              <div className="-ml-px flex w-0 flex-1">
                <div className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                  <Button color={"warning"} size="middle" onClick={handleNext}>
                    Done
                  </Button>
                </div>
              </div>
            </div>

            <StepIngredients
              stepId={id}
              recipeId={recipeId}
              ingredients={ingredients}
              ratio={overRideRatio ?? ratio}
              step={position + 1}
              stepText={actualDescription}
              ref={stepIngredientsRef}
              afterEditDescription={setActualDescription}
            />
          </div>
        </Accordion>
      </div>
    </section>
    // </Transition>
  );
}

const StepEdit = ({ actions }) => {
  const sizes = usePagesizeSelectorContext((state) => state.sizes);

  const rootData = useRootData();
  const openAccount = useAccountSelectorContext((state) => state.openAccount);

  const handler = useRef();

  const hasSub = useMemo(() => {
    return rootData?.hasSub;
  }, [rootData.hasSub]);

  const closeActionSheet = () => {
    handler.current?.close();
  };

  const openActionSheet = () => {
    handler.current = ActionSheet.show({
      actions: actions.map((action, index) => {
        return {
          text: action.label,
          key: index,
          onClick: action.onClick,
        };
      }),
      onAction: closeActionSheet,
    });
  };

  const isLarge = useMemo(() => {
    return sizes.lg;
  }, [sizes.lg]);
  if (!isLarge) {
    return (
      <div className="flex flex-shrink-0 self-center">
        <div className="relative z-10 inline-block text-left">
          <div className="flex items-center rounded-full text-gray-400 hover:text-gray-600" onClick={openActionSheet}>
            <span className="sr-only">Open Ingredient Options</span>
            <DotsVerticalFilledIcon className="h-5 w-5" aria-hidden="true" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-shrink-0 self-center">
      <Menu as="div" className="relative z-10 inline-block text-left">
        <div>
          {hasSub ? (
            <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600">
              <span className="sr-only">Open Ingredient Options</span>
              <DotsVerticalFilledIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          ) : (
            <Button className="flex items-center rounded-full border-none text-gray-400 hover:text-gray-600" onClick={openAccount}>
              <span className="sr-only">Open Ingredient Options</span>
              <DotsVerticalFilledIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          )}
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {actions.map(
                (
                  action: {
                    onClick: MouseEventHandler<HTMLDivElement> | undefined;
                    icon: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined;
                    label: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined;
                    disclosure: boolean | undefined;
                  },
                  index: Key | null | undefined
                ) => {
                  if (action.disclosure) {
                    return (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <Disclosure.Button className={clsx(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "flex w-full px-4 py-2 text-sm")}>
                            {action.icon}
                            <span>{action.label}</span>
                          </Disclosure.Button>
                        )}
                      </Menu.Item>
                    );
                  }
                  return (
                    <Menu.Item key={index}>
                      {({ active }) => {
                        return (
                          <div className={clsx(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "flex px-4 py-2 text-sm")} onClick={action.onClick}>
                            {action.icon}
                            <span>{action.label}</span>
                          </div>
                        );
                      }}
                    </Menu.Item>
                  );
                }
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
