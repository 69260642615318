import { useFetcher } from "@remix-run/react";
import Button from "antd-mobile/es/components/button";
import ResultPage from "antd-mobile/es/components/result-page";
import Swiper from "antd-mobile/es/components/swiper";

import { memo, useCallback, useMemo, useRef } from "react";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import { useShoppingListSelectorContext } from "~/components/context/shoppingListContext";
import { useTimerSelectorContext } from "~/components/context/timerContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import FireFilledIcon from "~/components/ui/icons/FireFilledIcon";
import GearIcon from "~/components/ui/icons/GearIcon";
import TwoColumnLayout from "~/components/ui/layouts/TwoColumnLayout";
import { useRootData } from "../../../utils/data/useRootData";
import CheckRecipeStep from "./CheckRecipeStep";
import CookingIngredientsNoPopup from "./CookingIngredientNoPopup";
import CookingIngredients from "./CookingIngredients";
import CookingTimer from "./CookingTimer";
import RecipeTimer from "./RecipeTimer";
import { closeConfirmModal, confirmModal } from "./confirmModal";

const timerRegex = RegExp(/(\d+)(?:\s*(?:-|to)\s*(\d+))?\s*(hrs|hr|hours|hour|minutes|minute|seconds|second|secs|sec|h|m|s)/g);

function CookRecipe() {
  const open = useCookRecipeSelectorContext((state) => state.open);
  const closeCookRecipe = useCookRecipeSelectorContext((state) => state.closeCookRecipe);
  const currentRecipeId = useCookRecipeSelectorContext((state) => state.currentRecipeId);
  const setCurrentStep = useCookRecipeSelectorContext((state) => state.setCurrentStep);
  const completeRecipe = useCookRecipeSelectorContext((state) => state.completeRecipe);
  const openIngredients = useCookRecipeSelectorContext((state) => state.openIngredients);
  const addIngredientChecked = useCookRecipeSelectorContext((state) => state.addIngredientChecked);
  const removeIngredientChecked = useCookRecipeSelectorContext((state) => state.removeIngredientChecked);
  const addIngredientPrepped = useCookRecipeSelectorContext((state) => state.addIngredientPrepped);
  const removeIngredientPrepped = useCookRecipeSelectorContext((state) => state.removeIngredientPrepped);
  const ingredientsChecked = useCookRecipeSelectorContext((state) => state.ingredientsChecked);
  const setIngredientsPrepped = useCookRecipeSelectorContext((state) => state.setIngredientsPrepped);
  const recipe = useCookRecipeSelectorContext((state) => state.recipe);
  const currentStep = useCookRecipeSelectorContext((state) => state.currentStep);
  const currentRecipeServings = useCookRecipeSelectorContext((state) => state.currentRecipeServings);
  const currentServingRatio = useCookRecipeSelectorContext((state) => state.currentServingRatio);

  const handleAddOneOrAllToShoppingList = useShoppingListSelectorContext((state) => state.handleAddOneOrAllToShoppingList);
  const resetTimer = useTimerSelectorContext((state) => state.resetTimer);

  const swipeRef = useRef(null);
  const ratingForm = useRef(null);
  const checkedRef = useRef();
  const coverImage = Array.isArray(recipe?.images) ? recipe.images[0] : recipe?.images;
  const ratingFetcher = useFetcher();
  const { hasSub } = useRootData();
  const openAccount = useAccountSelectorContext((state) => state.openAccount);

  checkedRef.current = ingredientsChecked;

  const handlePrepIngredient = useCallback(
    (ids) => {
      setIngredientsPrepped(currentRecipeId, ids);
    },
    [setIngredientsPrepped, currentRecipeId]
  );

  const imageUrl = coverImage
    ? `${coverImage}?class=h48`
    : recipe?.tempImage
      ? recipe?.tempImage
      : "https://cdn.forked.cooking/f14ae1f13645ba2d49389b840a3102b2.jpeg";

  const swipeNext = useCallback(() => {
    //TODO: if active timer, need to confirm they want to reset it or ignore it.

    if (currentStep > 1 && !hasSub) {
      openAccount("popup", "cook_recipe");
    } else {
      resetTimer();
      swipeRef?.current?.swipeNext();
    }
  }, [currentStep, hasSub]);

  const handleCheckboxClick = useCallback(
    (val: boolean, id: string) => {
      console.log("checkedRef.current: ", checkedRef.current);
      console.log("id: ", id);
      if (checkedRef.current?.includes(id)) {
        removeIngredientChecked(id, currentRecipeId);
      } else {
        addIngredientChecked(id, currentRecipeId);
      }
    },
    [currentRecipeId]
  );

  const handlePreppedCheckboxClick = (val: boolean, id: string) => {
    if (val) {
      addIngredientPrepped(id, currentRecipeId);
    } else {
      removeIngredientPrepped(id, currentRecipeId);
    }
  };

  const swipePrev = () => {
    resetTimer();
    swipeRef?.current?.swipePrev();
  };

  const completeAndClose = useCallback(() => {
    completeRecipe(recipe?.uuid);
    closeCookRecipe();
    closeConfirmModal();
  }, [recipe?.uuid]);

  const saveAndClose = () => {
    closeCookRecipe();
    closeConfirmModal();
  };

  const handleRatingSubmit = () => {
    completeAndClose();
    const formVals = ratingForm.current.getVals();
    //only submit the review if they have rated it
    if (formVals.userRating) {
      const form = new FormData();
      if (recipe.userRating) {
        form.set("action", "update-review");
      } else {
        form.set("action", "submit-review");
      }
      form.set("rating", formVals.userRating);
      form.set("comment", formVals.comment);
      form.set("notes", formVals.notes);
      form.set("noteIsPublic", formVals.noteIsPublic);
      form.set("isPublic", formVals.isPublic);
      form.set("recipeId", recipe.id);
      form.set("makeAgain", formVals.makeAgain);
      form.set("tags", formVals.tags.join(","));
      ratingFetcher.submit(form, {
        method: "post",
        action: "/api/recipe/review",
      });
    }
  };

  const handleClose = () => {
    confirmModal({
      title: "Save Cooking Progress?",
      content: (
        <div className="flex flex-col items-center">
          <div className="mt-2">Come back to this point anytime by clicking the fire icon.</div>
          <FireFilledIcon className="mt-2 h-8 w-8" />
        </div>
      ),
      actions: [
        [
          { key: "stop", text: "End", danger: true, onClick: completeAndClose },
          {
            key: "save",
            text: "Save",
            onClick: saveAndClose,
            primary: true,
          },
        ],
      ],
    });
    // saveAndClose();
  };

  const imageSwiper = (
    <>
      <div className="flex items-center">
        <Swiper
          // trackOffset={20}
          // slideSize={80}
          style={{
            "--border-radius": "8px",
          }}
          defaultIndex={0}
          allowTouchMove={true}
        >
          <Swiper.Item key={"1"}>
            <ResultPage.Card className="ring-4 ring-white">
              <img className="h-32 w-full rounded-lg object-cover lg:h-48" src={imageUrl} alt={recipe?.name} draggable={false} />
            </ResultPage.Card>
          </Swiper.Item>
          {/* <Swiper.Item key={"2"}>
            <ResultPage.Card className="ring-4 ring-white">
              <img className="h-32 w-full rounded-lg object-cover lg:h-48" src={imageUrl} alt={recipe?.name} draggable={false} />
            </ResultPage.Card>
          </Swiper.Item>
          <Swiper.Item key={"3"}>
            <ResultPage.Card className="ring-4 ring-white">
              <img className="h-32 w-full rounded-lg object-cover lg:h-48" src={imageUrl} alt={recipe?.name} draggable={false} />
            </ResultPage.Card>
          </Swiper.Item>
          <Swiper.Item key={"4"}>
            <ResultPage.Card className="ring-4 ring-white">
              <img className="h-32 w-full rounded-lg object-cover lg:h-48" src={imageUrl} alt={recipe?.name} draggable={false} />
            </ResultPage.Card>
          </Swiper.Item> */}
        </Swiper>
        {/* <ImageUploader
          className="flex items-center justify-center"
          value={[]}
          onChange={() => console.log("changefilelist")}
          upload={() => console.log("upload")}
        >
          <PlusIcon className="h-6 w-6" />
        </ImageUploader> */}
      </div>
    </>
  );

  const ratio = currentServingRatio;

  const resultCardClass = "mt-2 text-base text-black max-w-lg w-full";
  const ingredientList = (
    <div className="h-full cursor-pointer" onClick={openIngredients}>
      <GearIcon className="h-full w-16 p-4" />
    </div>
  );

  const handleIndexChange = useCallback(
    (index: number) => {
      console.log("handleIndexChange: ", index);
      setCurrentStep(recipe?.id, index);
    },
    [recipe?.id]
  );
  const handleAddShopping = useCallback(
    (ingredient) => {
      handleAddOneOrAllToShoppingList(ingredient, recipe?.id);
    },
    [recipe?.id]
  );

  const ingredientRightActions = useMemo(() => {
    return [
      {
        key: "shop",
        text: "Add to Shopping List",
        color: "warning",
        onClick: hasSub ? handleAddShopping : openAccount,
      },
    ];
  }, [handleAddShopping, hasSub]);

  return (
    <PopupWithNavbar
      className={"cooking-recipe-popup w-full"}
      headerClass="bg-theme-300"
      // destroyOnClose={true}
      open={open}
      onClose={handleClose}
      title={recipe?.name}
      bodyClassName={"w-full"}
      navbarProps={{ right: ingredientList, className: "relative bg-theme-300" }}
      shadow={false}
      subtitle={<div>{`Servings - ${currentRecipeServings}`}</div>}
    >
      <TwoColumnLayout
        wrapperClasses="collection-wrapper flex-1 flex sm:overflow-hidden"
        sideContents={
          <div className="flex flex-1 flex-col">
            <CookingIngredientsNoPopup />
            <RecipeTimer min={"1"} max={"5"} unit={"min"} />
          </div>
        }
        sideTitle={"User"}
        mainClasses="order-last flex flex-1"
        sideClasses="order-first hidden sm:flex w-1/3 lg:w-80 xl:w-96"
      >
        <div className="flex flex-1 flex-col space-y-6 overflow-y-auto bg-gray-100 p-2 md:p-6">
          <CheckRecipeStep
            key={`prep`}
            id={"na"}
            recipeId={recipe?.id}
            length={recipe?.steps?.length + 1}
            // removeStep={handleRemoveStep}
            description={"Let's prep! Use the 'prepping' tab to mark ingredients that you have gathered."}
            stepText={<span className="flex text-xs">Prep</span>}
            // originalPosition={parseInt(step.position)}
            // originalPosition={index}
            // addStep={handleAddStep}
            ingredients={[]}
            editing={false}
            active={currentStep === null}
            checked={currentStep > 0}
            handleNext={() => handleIndexChange(1)}
            handleOpen={() => handleIndexChange(0)}
          // notes={step.note}
          />
          {recipe?.steps?.map((step, index) => {
            const stepKey = index + 1;
            return (
              <CheckRecipeStep
                key={`${stepKey}`}
                id={step.id}
                recipeId={recipe?.id}
                length={recipe?.steps.length + 1}
                // removeStep={handleRemoveStep}
                description={step.text}
                position={index}
                // originalPosition={parseInt(step.position)}
                // originalPosition={index}
                // addStep={handleAddStep}
                ingredients={step.ingredients}
                overRideRatio={ratio}
                editing={false}
                notes={step.note}
                active={currentStep === stepKey}
                checked={currentStep > stepKey}
                handleNext={(e) => {
                  e.stopPropagation();
                  handleIndexChange(stepKey + 1);
                }}
                handleOpen={() => handleIndexChange(stepKey)}
              />
            );
          })}
          {currentStep === recipe?.steps?.length + 1 && (
            <Button color={"warning"} className="shadow-sm" onClick={completeAndClose}>
              Complete
            </Button>
          )}
        </div>
      </TwoColumnLayout>
      <CookingIngredients />
      <CookingTimer />
    </PopupWithNavbar>
  );
}

export default memo(CookRecipe);
