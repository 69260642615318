import List from "antd-mobile/es/components/list";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { memo, useCallback, useMemo, useRef } from "react";
import { closeConfirmModal, confirmModal } from "~/modules/recipe/components/confirmModal";
import clsx from "~/utils/shared/ClassesUtils";
import { useCalendarSelectorContext } from "../context/calendarContext";
import { useCookRecipeSelectorContext } from "../context/cookRecipeContext";
dayjs.extend(calendar);

function UpcomingRecipe({ label, servings, date, time, placeholder, recipe, id, recipeId, recipeName }) {
  const recipeRef = useRef();
  const cookRecipe = useCookRecipeSelectorContext((state) => state.cookRecipe);
  const recipes = useCookRecipeSelectorContext((state) => state.recipes);
  const setThisRecipeId = useCookRecipeSelectorContext((state) => state.setThisRecipeId);
  const setThisCollectionId = useCookRecipeSelectorContext((state) => state.setThisCollectionId);

  const datesList = useCalendarSelectorContext((state) => state.datesList);
  const deleteDatePlan = useCalendarSelectorContext((state) => state.deleteDatePlan);

  const deletePlan = (id) => {
    deleteDatePlan(id, recipeId);
    closeConfirmModal();
  };

  const handleDeletePlan = useCallback(
    (id) => {
      const date = datesList.find((date) => date.id === id);
      confirmModal({
        title: `Delete ${date.label ? date.label + " " : ""}from ${dayjs(date.date.split("T")[0], "YYYY-MM-DD").calendar(undefined, {
          sameDay: "[Today]",
          lastDay: "[Yesterday]",
          lastWeek: "[Last] dddd",
          sameElse: "ddd, MMM D",
          nextWeek: "dddd",
          nextDay: "[Tomorrow]",
        })}`,
        content: "Are you sure you want to delete this meal plan? This will automatically be removed from your shopping list.",
        actions: [
          [
            {
              key: "cancel",
              text: "Cancel",
              onClick: closeConfirmModal,
            },
            {
              key: "delete",
              text: "Delete",
              danger: true,
              onClick: () => deletePlan(id),
            },
          ],
        ],
      });
    },
    [datesList]
  );

  const recipeLookup = useMemo(() => {
    if (recipe) {
      return recipe;
    }
    return recipes.find((r) => r.id === recipeId);
  }, [recipe, recipeId, recipes]);

  // const handleCancel = useCallback(() => {
  //   cancelRecipe(uuid);
  // }, [uuid]);

  // const handleComplete = useCallback(() => {
  //   completeRecipe(uuid);
  // }, [uuid]);

  const handleClick = useCallback(() => {
    // if (onClick) {
    //   onClick(id);
    // }
    const thisRecipe = recipeLookup;
    const recipeWithDate = { ...thisRecipe, recipeDate: { date, time } };
    console.log("recipeWithDate", recipeWithDate);
    setThisRecipeId(recipeId);
    setThisCollectionId(null);
    cookRecipe(recipeWithDate, id);
  }, [id, recipe, date, time, recipeId, recipeLookup]);

  const leftActions = useMemo(
    () => [
      {
        key: "delete",
        text: "Delete",
        color: "danger",
        onClick: handleDeletePlan,
      },
    ],
    []
  );
  const rightActions = [
    {
      key: "edit",
      text: "Edit",
      color: "primary",
      // onClick: handleComplete,
    },
  ];

  const handleActionsRevealed = useCallback(
    (direction: "left" | "right") => {
      if (direction === "left") {
        if (leftActions.length === 1) {
          leftActions[0].onClick && leftActions[0].onClick(id);
        }
      } else {
        if (rightActions.length === 1) {
          rightActions[0].onClick && rightActions[0].onClick(id);
        }
      }
      recipeRef.current?.close();
    },
    [leftActions, rightActions, id]
  );

  const coverImage = Array.isArray(recipeLookup?.images) ? recipeLookup?.images[0] : recipeLookup?.images;
  const imageUrl = coverImage ? `${coverImage}?class=w40h40` : "https://cdn.forked.cooking/f14ae1f13645ba2d49389b840a3102b2.jpeg";

  const image = (
    <div className={clsx("flex h-10 w-10 min-w-full items-end rounded-lg bg-cover bg-center text-white")} style={{ backgroundImage: `url(${imageUrl})` }}></div>
  );
  let serveTime = null;
  if (date && time) {
    const rDate = date.split("T")[0];
    const rTime = time === "Invalid Date" ? "" : time;

    const dayjsDate = dayjs(`${rDate} ${rTime}`);

    serveTime = `eat ${dayjsDate.calendar(undefined, {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: "ddd, MMM D",
      nextWeek: "dddd",
      nextDay: "[Tomorrow]",
    })}`;
    //if time is not empty, add at time
    if (rTime && rTime !== "") {
      serveTime += ` at ${dayjsDate.format("h:mm A")}`;
    }
  }

  const listItemPrefix = image;
  return (
    <SwipeAction leftActions={leftActions} rightActions={rightActions} ref={recipeRef} onActionsReveal={handleActionsRevealed}>
      <List.Item
        className="!flex h-full break-all"
        onClick={handleClick}
        // description={<div className="line-clamp-1">{hostname}</div>}
        prefix={listItemPrefix}
        title={<div className="line-clamp-2 break-normal text-lg text-black">{recipeLookup?.name ?? recipeName}</div>}
      >
        {/* <Ellipsis direction="end" rows={2} content={item.name} /> */}

        <div className="flex h-4 justify-between text-xs text-gray-400">
          <div>{label}</div>
          <div>{placeholder ? null : serveTime}</div>
        </div>
      </List.Item>
    </SwipeAction>
  );
}

export default memo(UpcomingRecipe);
