import CapsuleTabs from "antd-mobile/es/components/capsule-tabs";
import { Group as CheckboxGroup } from "antd-mobile/es/components/checkbox/group";

import List from "antd-mobile/es/components/list";
import Stepper from "antd-mobile/es/components/stepper";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import CookingIngredient from "./CookingIngredient";

function CookingIngredients() {
  const recipe = useCookRecipeSelectorContext((state) => state.recipe);
  const currentRecipeId = useCookRecipeSelectorContext((state) => state.currentRecipeId);
  const currentServingRatio = useCookRecipeSelectorContext((state) => state.currentServingRatio);
  const ingredientsTab = useCookRecipeSelectorContext((state) => state.ingredientsTab);
  const setIngredientsTab = useCookRecipeSelectorContext((state) => state.setIngredientsTab);
  const ingredientsChecked = useCookRecipeSelectorContext((state) => state.ingredientsChecked);
  const setIngredientsChecked = useCookRecipeSelectorContext((state) => state.setIngredientsChecked);
  const ingredientsPrepped = useCookRecipeSelectorContext((state) => state.ingredientsPrepped);
  const setIngredientsPrepped = useCookRecipeSelectorContext((state) => state.setIngredientsPrepped);
  const ingredientsOpen = useCookRecipeSelectorContext((state) => state.ingredientsOpen);
  const closeIngredients = useCookRecipeSelectorContext((state) => state.closeIngredients);
  const currentRecipeServings = useCookRecipeSelectorContext((state) => state.currentRecipeServings);
  const setCurrentRecipeServings = useCookRecipeSelectorContext((state) => state.setCurrentRecipeServings);

  const ingredients = useMemo(() => {
    return recipe?.ingredients;
  }, [recipe?.ingredients]);

  const handleClick = useCallback(
    (ids) => {
      if (ingredientsTab === "prepping") {
        console.log("currentRecipeId : ", currentRecipeId);
        setIngredientsPrepped(currentRecipeId, ids);
      } else {
        setIngredientsChecked(currentRecipeId, ids);
      }
    },
    [ingredientsTab, currentRecipeId]
  );

  useEffect(() => {
    document.getElementById("ingredient-list-wrapper")?.scroll({ top: 0, behavior: "smooth" });
  }, [ingredientsTab]);

  const checkedIngredients = useMemo(() => {
    return ingredientsTab === "prepping" ? ingredientsPrepped : ingredientsChecked;
  }, [ingredientsTab, ingredientsPrepped, ingredientsChecked]);

  return (
    <PopupWithNavbar
      className={"cooking-ingredient-popup"}
      // destroyOnClose={true}
      headerClass="bg-theme-300"
      open={ingredientsOpen}
      onClose={closeIngredients}
      subtitle={recipe?.name}
      title={"All Ingredients"}
      navbarProps={{ className: "relative bg-theme-300" }}
      shadow={false}
    >
      <div className="flex flex-1 flex-col overflow-y-auto" id="ingredient-list-wrapper">
        <div className="nested-list sticky-items flex flex-1 flex-col">
          <List className={"ingredient-list"}>
            <CheckboxGroup value={checkedIngredients} onChange={handleClick}>
              <div className="sticky top-0 z-10 flex flex-col overflow-hidden bg-white">
                <div className="flex items-center justify-center overflow-hidden bg-white pt-3">
                  <h2 className="text-base text-xl text-black">Servings: </h2>
                  <Stepper
                    value={currentRecipeServings ?? 1}
                    onChange={setCurrentRecipeServings}
                    min={1}
                    max={1000}
                    aria-label={"Servings Adjustment"}
                    className="large-stepper"
                  />
                </div>

                <CapsuleTabs className="segment" activeKey={ingredientsTab} onChange={setIngredientsTab}>
                  <CapsuleTabs.Tab className="p-0" title="Prepping" key="prepping" />
                  <CapsuleTabs.Tab title="Cooking" key="cooking" />
                </CapsuleTabs>
              </div>
              <List.Item className="sticky top-[110px] z-10 bg-gray-100" title={ingredientsTab !== "prepping" ? "Upcoming" : "Not Picked"} />
              {/* {ingredientsTab !== "prepping" && <List.Item className="sticky top-[70px] z-10 bg-gray-100" title={"Upcoming"} />} */}
              {ingredients
                ?.filter((ing) => !checkedIngredients.includes(ing.id))
                .map((ingredient) => {
                  return (
                    <CookingIngredient
                      ingredient={{ ...ingredient, quantity: ingredient.quantity * currentServingRatio }}
                      leftActions={[]}
                      rightActions={[]}
                      key={ingredient.id}
                    />
                  );
                })}
              <List.Item className="sticky top-[110px] z-10 bg-gray-100" title={ingredientsTab !== "prepping" ? "Used" : "Picked"} />
              {/* {ingredientsTab !== "prepping" && <List.Item className="sticky top-[70px] z-10 bg-gray-100" title={"Used"} />} */}
              {ingredients
                ?.filter((ing) => checkedIngredients.includes(ing.id))
                .map((ingredient) => {
                  return (
                    <CookingIngredient
                      ingredient={{ ...ingredient, quantity: ingredient.quantity * currentServingRatio }}
                      leftActions={[]}
                      rightActions={[]}
                      key={ingredient.id}
                    />
                  );
                })}
            </CheckboxGroup>
          </List>
        </div>
      </div>
    </PopupWithNavbar>
  );
}

export default memo(CookingIngredients);
