import { useMatches } from "@remix-run/react";
import Badge from "antd-mobile/es/components/badge";
import Button from "antd-mobile/es/components/button";
import Calendar from "antd-mobile/es/components/calendar";
import Divider from "antd-mobile/es/components/divider";
import List from "antd-mobile/es/components/list";
import PickerView from "antd-mobile/es/components/picker-view";
import Selector from "antd-mobile/es/components/selector";
import Stepper from "antd-mobile/es/components/stepper";
import Toast from "antd-mobile/es/components/toast";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { NonVirtualRecipeListItem } from "~/components/charts/RecipeListItem";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import { useCalendarSelectorContext } from "~/components/context/calendarContext";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import { useForkedRecipeSelectorContext } from "~/components/context/forkedRecipesContext";
import { useRecipeSelectorContext } from "~/components/context/recipeContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import { useRootData } from "~/utils/data/useRootData";
import RecipePlan from "./RecipePlan";
import { closeConfirmModal, confirmModal } from "./confirmModal";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(calendar);

function RecipeCalendar() {
  const [dateSelected, setDateSelected] = useState(dayjs().startOf("day").toDate());
  const [timeSelected, setTimeSelected] = useState(undefined);
  const [placeholder, setPlaceholder] = useState(false);
  const open = useCalendarSelectorContext((state) => state.open);
  const closeCalendar = useCalendarSelectorContext((state) => state.closeCalendar);
  const monthRecipes = useCalendarSelectorContext((state) => state.datesList);
  const getMonthRecipes = useCalendarSelectorContext((state) => state.getMonthRecipes);
  const deleteDatePlan = useCalendarSelectorContext((state) => state.deleteDatePlan);
  const createPlan = useCalendarSelectorContext((state) => state.createPlan);
  const createPlans = useCalendarSelectorContext((state) => state.createPlans);

  const adjustedServings = useRecipeSelectorContext((state) => state.adjustedServings);
  const thisRecipe = useCookRecipeSelectorContext((state) => state.thisRecipe);
  const thisCollectionId = useCookRecipeSelectorContext((state) => state.thisCollectionId);
  const userCollections = useForkedRecipeSelectorContext((state) => state.userCollections);

  const openAccount = useAccountSelectorContext((state) => state.openAccount);

  const [label, setLabel] = useState([]);
  const [recipeIds, setRecipeIds] = useState([]);
  const [recipeServings, setRecipeServings] = useState({});
  const matches = useMatches();
  const rootData = useRootData();

  const onRecipe = useMemo(() => {
    return matches?.find((match) => match.id === "routes/app.$tenant/recipe/$urlId.$urlKey");
  }, [matches]);

  const onCollection = useMemo(() => {
    return matches?.find((match) => match.id === "routes/app.$tenant/collection.$urlId.$urlKey");
  }, [matches]);

  const currentRecipe = useMemo(() => {
    if (thisRecipe) return thisRecipe;

    return {};
  }, [onRecipe, thisRecipe]);

  const currentCollection = useMemo(() => {
    return userCollections.find((collection) => collection.id === thisCollectionId);
  }, [thisCollectionId, onCollection]);

  useEffect(() => {
    if (currentCollection) {
      setRecipeIds(currentCollection?.recipes.map((recipe) => recipe.recipeId));
      setRecipeServings(
        currentCollection?.recipes.reduce((acc, recipe) => {
          acc[recipe.recipeId] = recipe.servings;
          return acc;
        }, {})
      );
    } else {
      setRecipeIds([]);
      setRecipeServings({});
    }
  }, [currentCollection?.recipes]);

  const currentDayRecipes = useMemo(() => {
    if (placeholder) {
      return monthRecipes.filter((recipe) => {
        return (
          dayjs(recipe.date.split("T")[0], "YYYY-MM-DD").isSameOrBefore(dateSelected, "day") &&
          dayjs(recipe.date.split("T")[0], "YYYY-MM-DD").isSameOrAfter(dayjs().startOf("day"), "day")
        );
      });
    }
    return monthRecipes.filter((recipe) => {
      return dayjs(recipe.date.split("T")[0], "YYYY-MM-DD").isSame(dateSelected, "day");
    });
  }, [dateSelected, monthRecipes, placeholder]);

  const monthDaysWithRecipes = useMemo(() => {
    return monthRecipes.reduce((acc, recipe) => {
      const recipeDate = recipe.date.split("T")[0];
      if (!acc[recipeDate]) acc[recipeDate] = 1;
      else acc[recipeDate] += 1;
      return acc;
    }, {});
  }, [monthRecipes]);

  //when the calendar is visible, need to get the users recipes for that month
  useEffect(() => {
    if (open) {
      getMonthRecipesCal(dayjs(dateSelected).year(), dayjs(dateSelected).month() + 1);
    }
  }, [open]);

  const getMonthRecipesCal = (year, month) => {
    getMonthRecipes(year, month);
  };

  //when the calendar changes month, get the recipes for that month

  const submitSchedule = useCallback(() => {
    createPlan(dayjs(dateSelected).format("YYYY-MM-DD"), timeSelected, currentRecipe.id, adjustedServings, label, placeholder);
  }, [timeSelected, dateSelected, currentRecipe.id, adjustedServings, placeholder]);

  const submitSchedules = useCallback(
    (recipeIds, recipeServings) => {
      const filteredServings = Object.keys(recipeServings).reduce((acc, recipeId) => {
        if (recipeIds.includes(recipeId)) {
          acc[recipeId] = recipeServings[recipeId];
        }
        return acc;
      }, {});

      createPlans(dayjs(dateSelected).format("YYYY-MM-DD"), timeSelected, filteredServings, label, placeholder);
    },
    [timeSelected, dateSelected, placeholder]
  );

  const deleteSchedule = (id) => {
    deleteDatePlan(id);
    closeConfirmModal();
  };

  const handleItemDelete = useCallback(
    (id) => {
      const recipe = monthRecipes.find((recipe) => recipe.id === id);
      confirmModal({
        title: `Delete ${recipe.recipeName + " "}from ${dayjs(dateSelected).calendar(undefined, {
          sameDay: "[Today]",
          lastDay: "[Yesterday]",
          lastWeek: "[Last] dddd",
          sameElse: "ddd, MMM D",
          nextWeek: "dddd",
          nextDay: "[Tomorrow]",
        })}`,
        content: "Are you sure you want to delete this meal plan? This will automatically be removed from your shopping list.",
        actions: [
          [
            {
              key: "cancel",
              text: "Cancel",
              onClick: closeConfirmModal,
            },
            {
              key: "delete",
              text: "Delete",
              danger: true,
              onClick: () => deleteSchedule(id),
            },
          ],
        ],
      });
    },
    [monthRecipes]
  );

  const handleSave = useCallback(() => {
    if (!rootData.hasSub) {
      openAccount("popup", "plan");
    } else {
      submitSchedules(recipeIds, recipeServings);
      closeCalendar();
    }
  }, [recipeServings, recipeIds, rootData.hasSub]);

  const handleSaveOne = useCallback(() => {
    if (!rootData.hasSub) {
      openAccount("popup", "plan");
    } else {
      closeCalendar();
      submitSchedule();
    }
  }, [currentRecipe.id, dateSelected, timeSelected, adjustedServings, label, placeholder, rootData.hasSub]);

  const handleChangeTime = (value) => {
    setTimeSelected(value);
  };
  const tabbar = useMemo(() => {
    return (
      <div className="z-10 flex flex-none flex-col p-4">
        <Button color="primary" onClick={currentCollection ? handleSave : handleSaveOne}>
          Add to {placeholder ? (dayjs(dateSelected).diff(dayjs(), "day") <= 7 ? "This Week" : "Next Week") : `${dayjs(dateSelected).format("MMMM DD, YYYY")}`}
        </Button>
      </div>
    );
  }, [placeholder, dateSelected, currentRecipe.id, currentCollection?.id, recipeIds, recipeServings, label, timeSelected]);

  const dateRecipeIndicator = useCallback(
    (date) => {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      const numRecipes = monthDaysWithRecipes[formattedDate];
      if (numRecipes) {
        return <Badge content={Badge.dot} color={"var(--adm-color-warning)"} />;
      }
      return null;
    },
    [monthDaysWithRecipes]
  );

  const listHeader = (title, extra) => (
    <div className="flex items-center justify-between">
      <div className="flex-1 truncate">{title}</div>
      <div className="flex-none text-sm text-gray-400">{extra}</div>
    </div>
  );

  const hours = useMemo(() => {
    return [...Array(12).keys()].map((hour) => {
      return { label: String(hour + 1).padStart(2, "0"), value: hour + 1 };
    });
  }, []);

  const minutes = useMemo(() => {
    return [...Array(4).keys()].map((min) => {
      const mins = 15 * min;
      return { label: String(mins).padStart(2, "0"), value: mins };
    });
  }, []);

  const mealTimes = useMemo(() => {
    return [
      { label: "Breakfast", value: "breakfast", time: "07:00 am" },
      { label: "Lunch", value: "lunch", time: "12:00 pm" },
      { label: "Dinner", value: "dinner", time: "06:00 pm" },
      { label: "Snack", value: "snack", time: "04:00 pm" },
    ];
  }, []);

  const commitmentIssues = useMemo(() => {
    return [
      { label: "This Week", value: "this_week" },
      { label: "Next Week", value: "next_week" },
    ];
  }, []);

  const otherMealsTitle = useMemo(() => {
    if (placeholder) {
      if (dayjs(dateSelected).diff(dayjs(), "day") <= 7) {
        return "Other Meals Coming Up This Week";
      }
      return "Other Meals Coming Up";
    }
    return "Other Meals Planned for " + dayjs(dateSelected).format("MMMM DD");
  }, [dateSelected, placeholder]);

  const handleSetLabel = useCallback(
    (label) => {
      if (label.length > 0) {
        const time = mealTimes.find((time) => label.includes(time.value));
        // break up the time.time into pieces by : and space
        const [hour, minute, ampm] = time.time.split(/:| /);
        setTimeSelected([parseInt(hour), parseInt(minute), ampm]);
      } else {
        setTimeSelected(undefined);
      }
      setLabel(label);
    },
    [mealTimes]
  );

  const handleCommitmentIssues = (value) => {
    setPlaceholder(true);
    if (value.includes("this_week")) {
      handleScheduleThisWeek();
    } else if (value.includes("next_week")) {
      handleScheduleNextWeek();
    }
  };

  const handleScheduleThisWeek = () => {
    //get a week from today
    const weekFromToday = dayjs().startOf().add(7, "day").toDate();
    setDateSelected(weekFromToday);
    setTimeSelected(undefined);
    setLabel([]);
    setPlaceholder(true);
  };
  const handleScheduleNextWeek = () => {
    //get a week from today
    const weekFromToday = dayjs().startOf().add(14, "day").toDate();
    setDateSelected(weekFromToday);
    setTimeSelected(undefined);
    setLabel([]);
    setPlaceholder(true);
  };

  const handleCalendarChange = (date) => {
    // console.log("date", date);
    setDateSelected(date);
    setPlaceholder(false);
  };

  const removeRecipe = (id) => {
    setRecipeIds((prev) => prev.filter((recipeId) => recipeId !== id));
  };

  const addRecipe = (id) => {
    setRecipeIds((prev) => [...prev, id]);
  };

  const handleServingsChange = (id, servings) => {
    setRecipeServings((prev) => {
      return { ...prev, [id]: servings };
    });
  };
  const handleClose = () => {
    closeCalendar();
    setRecipeIds([]);
    setDateSelected(dayjs().startOf("day").toDate());
    setTimeSelected(undefined);
    setPlaceholder(false);
    setLabel([]);
  };

  return (
    <PopupWithNavbar
      className={"calendar-popup"}
      headerClass="bg-theme-300"
      open={open}
      onClose={handleClose}
      title={"When are you cooking?"}
      // bodyClassName="w-full sm:w-auto md:w-96"
      subtitle={currentRecipe?.name ?? currentCollection?.name}
    >
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="nested-list sticky-items flex flex-1 flex-col">
          <List className="z-10 bg-gray-100">
            {currentCollection && (
              <List.Item
                className="sticky top-0 z-20 bg-gray-100"
                title={"Collection Recipes"}
                extra={<div className="flex w-32 justify-center text-base">Servings</div>}
              />
            )}
            {currentCollection &&
              currentCollection.recipes?.map((recipe) => {
                const disabled = !recipeIds.includes(recipe.recipeId);
                return (
                  <NonVirtualRecipeListItem
                    key={recipe.id}
                    item={recipe}
                    disabled={disabled}
                    onServingsChange={handleServingsChange}
                    leftActions={
                      !disabled
                        ? [
                          {
                            key: "remove",
                            text: "Remove",
                            color: "danger",
                            onClick: () => removeRecipe(recipe.recipeId),
                          },
                        ]
                        : []
                    }
                    rightActions={
                      disabled
                        ? [
                          {
                            key: "add",
                            text: "Add",
                            color: "primary",
                            onClick: () => addRecipe(recipe.recipeId),
                          },
                        ]
                        : []
                    }
                  />
                );
              })}
            <List.Item className="sticky top-0 z-20 bg-gray-100" title={"Schedule for "} />
            <List.Item>
              <Calendar
                selectionMode="single"
                renderLabel={dateRecipeIndicator}
                defaultValue={dateSelected}
                onChange={handleCalendarChange}
                onPageChange={getMonthRecipesCal}
                className="z-10"
              />
              <Divider className="mt-0">or Add To</Divider>
              <Selector
                columns={2}
                // style={{ gridTemplateColumns: "repeat(auto-fit, minmax(165px, 1fr))" }}
                // className="z-10"
                value={
                  !placeholder
                    ? []
                    : dayjs().add(7, "day").isSameOrAfter(dateSelected)
                      ? ["this_week"]
                      : dayjs().add(14, "day").isSameOrAfter(dateSelected)
                        ? ["next_week"]
                        : []
                }
                onChange={handleCommitmentIssues}
                options={commitmentIssues.map((meal) => {
                  return { label: meal.label, value: meal.value };
                })}
              />
            </List.Item>
            <List.Item className="sticky top-0 z-20 bg-gray-100" title={otherMealsTitle} />
            <List className="z-10">
              {currentDayRecipes.map((otherMeal, index) => {
                return (
                  <RecipePlan
                    key={index}
                    date={otherMeal.date.split("T")[0]}
                    time={otherMeal.time}
                    meal={otherMeal.label}
                    recipeName={otherMeal.recipeName}
                    leftActions={[
                      {
                        key: "delete",
                        text: "Delete Plan",
                        color: "danger",
                        onClick: () => deleteSchedule(otherMeal.id),
                      },
                    ]}
                    // totalTime={totalTime}
                    onClick={() => {
                      Toast.show("Swipe Right to Delete");
                    }}
                    servings={otherMeal.servings}
                  />
                );
                //   return (
                //     <SwipeAction
                //       key={index}
                //       rightActions={[
                //         {
                //           key: "delete",
                //           text: "Delete Plan",
                //           color: "danger",
                //           onClick: () => deleteSchedule(otherMeal.id),
                //         },
                //       ]}
                //     >
                //       <List.Item
                //         className="text-lg"
                //         // style={{ "--padding-left": "0px" }}
                //         onClick={() => {
                //           Toast.show("Swipe Right to Delete");
                //         }}
                //         extra={
                //           <>
                //             {otherMeal.label}
                //             <Tag round>{otherMeal.servings}</Tag>
                //           </>
                //         }
                //         //dayjs subtracts the total time from the date
                //         // description={time ? `start cooking at ${dayjs(`${date} ${time}`).subtract(totalTime, "m").format("h:mm A")}` : null}
                //         // title={otherMeal.recipeName}
                //       >
                //         {otherMeal.recipeName}
                //       </List.Item>
                //     </SwipeAction>
                //   );
                // })
              })}

              {currentDayRecipes.length === 0 && (
                <List.Item
                  className="z-10 text-lg"
                  // extra={date.meal}
                  //dayjs subtracts the total time from the date
                  title={`No meals planned${placeholder ? "" : " for this day"}`}
                />
              )}
            </List>
            {!thisCollectionId && <List.Item className="sticky top-0 z-20 bg-gray-100" title={"Options"} />}
            {!thisCollectionId && (
              <List.Item
                className="z-10 text-lg"
                // extra={date.meal}
                //dayjs subtracts the total time from the date
                extra={
                  <Stepper
                    value={adjustedServings ?? 1}
                    defaultValue={1}
                    aria-label={"Servings Adjustment"}
                    min={1}
                    max={1000}
                    className="large-stepper"
                  // onChange={(value) => {
                  //   console.log("change servings: ", value);
                  // }}
                  />
                }
              >
                Servings
              </List.Item>
            )}
            {/* {listHeader("Serving Time", "")} */}
            <List.Item className="sticky top-0 z-20 bg-gray-100" title="Serving Time" />
            <List.Item className="z-10" style={{ "--border-inner": "none" }}>
              <Selector
                columns={4}
                // style={{ gridTemplateColumns: "repeat(auto-fit, minmax(165px, 1fr))" }}
                // className="z-10"
                value={label}
                onChange={handleSetLabel}
                options={mealTimes.map((meal) => {
                  return { label: meal.label, value: meal.value, description: `Serve at ${meal.time}` };
                })}
              />
            </List.Item>
            <List.Item className="z-0">
              <PickerView
                style={{ "--height": "200px" }}
                // className="z-0"
                onChange={handleChangeTime}
                value={timeSelected}
                columns={[
                  hours,
                  minutes,
                  [
                    { label: "am", value: "am" },
                    { label: "pm", value: "pm" },
                  ],
                ]}
                mouseWheel={true}
              />
            </List.Item>
          </List>
        </div>
        {tabbar}
      </div>
    </PopupWithNavbar>
  );
}

export default memo(RecipeCalendar);

// <AppLayout navbar={navbar}>
// <div
//   className="flex flex-1 flex-col overflow-y-auto"
//   style={{ WebkitOverflowScrolling: "touch", overflowY: "scroll", height: "100%", pointerEvents: "all" }}
// >
//   <div className="flex flex-1 flex-col">
//     <div className="sm:p-8">
//       <Calendar
//         selectionMode="single"
//         renderLabel={dateRecipeIndicator}
//         defaultValue={dateSelected}
//         onChange={setDateSelected}
//         onPageChange={getMonthRecipes}
//         className="sm:max-w-md sm:shadow"
//       />
//     </div>

//     <List className="bg-gray-100" header={listHeader("Other Meals Planned for " + dayjs(dateSelected).format("MMMM DD"), "All Day")}>
//       {currentDayRecipes.map((otherMeal, index) => {
//         return (
//           <List.Item
//             key={index}
//             className="text-lg"
//             // extra={date.meal}
//             //dayjs subtracts the total time from the date
//             description={otherMeal.label}
//           >
//             <div className="flex">
//               <div className="flex-1">{otherMeal.recipeName}</div>
//               <div className="flex-none text-right text-gray-500">{dayjs(otherMeal.date).format("hh:mm A")}</div>
//             </div>
//           </List.Item>
//         );
//       })}
//       {currentDayRecipes.length === 0 && (
//         <List.Item
//           className="text-lg"
//           // extra={date.meal}
//           //dayjs subtracts the total time from the date
//         >
//           No meals planned for this day
//         </List.Item>
//       )}
//     </List>
//     <List className="bg-gray-100" header={listHeader("Options", "")}>
//       <List.Item
//         className="text-lg"
//         // extra={date.meal}
//         //dayjs subtracts the total time from the date
//         extra={
//           <Stepper
//             defaultValue={1}
//             onChange={(value) => {
//               console.log(value);
//             }}
//           />
//         }
//       >
//         Servings
//       </List.Item>
//     </List>
//     <List className="bg-gray-100" header={listHeader("Serving Time", "")}>
//       <PickerView
//         onChange={(value) => {
//           console.log("picker", value);
//         }}
//         columns={[
//           // [...Array(12)].map((hour) => {
//           //   return { label: String(hour).padStart(2, "0"), value: hour + 1 };
//           // }),
//           // [...Array(60)].map((min) => {
//           //   return { label: String(min).padStart(2, "0"), value: min };
//           // }),
//           hours,
//           minutes,
//           [
//             { label: "am", value: "am" },
//             { label: "pm", value: "pm" },
//           ],
//         ]}
//         mouseWheel={true}
//       />
//     </List>
//   </div>
//   {tabbar}
// </div>
// </AppLayout>
