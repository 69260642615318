import Checkbox from "antd-mobile/es/components/checkbox";
import List from "antd-mobile/es/components/list";
import { useRef } from "react";

interface CollectionProps {
  collection: {
    name: string;
    description: string;
    numRecipes: number;
  };
  leftActions: any[];
  rightActions: any[];
  group?: string;
  disabled?: boolean;
}

export default function CollectionCheckbox({ collection, leftActions, rightActions, group, disabled }: CollectionProps) {
  const checkBoxRef = useRef();

  const ingredientRef = useRef();

  // const all = [unit, ingredientName].filter((n) => n).join(" ");

  const onClick = () => {
    checkBoxRef.current?.toggle();
  };

  // const stepper = (
  //   <Stepper
  //     showNumber
  //     size="small"
  //     min={1}
  //     defaultValue={checkQuantity}
  //     // onChange={handleQtyChange}
  //     onChange={() => {
  //       console.log("stepper changed");
  //     }}
  //   />
  // );

  return (
    <List.Item
      prefix={
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox value={`${collection.id}`} ref={checkBoxRef} disabled={disabled} />
        </div>
      }
      className="text-lg"
      // description={ingredient.comment}
      onClick={disabled ? null : onClick}
      arrow={false}
      extra={collection.numRecipes}
      description={collection.description}
    >
      {collection.name}
    </List.Item>
  );
}
