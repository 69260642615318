import { useCallback, useEffect, useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { useRootData } from "~/utils/data/useRootData";
import { useCalendarSelectorContext } from "./calendarContext";
import { useCookRecipeSelectorContext } from "./cookRecipeContext";
import { useDashboardSelectorContext } from "./dashboardContext";
import { useForkedRecipeSelectorContext } from "./forkedRecipesContext";
import { usePantrySelectorContext } from "./pantryContext";
import { useSearchSelectorContext } from "./searchContext";
import { useShoppingListSelectorContext } from "./shoppingListContext";

const CleanUpContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useCleanUpContextVals = () => {
  const [hasChanged, setHasChanged] = useState(false);
  const rootData = useRootData();
  const clearSearch = useSearchSelectorContext((state) => state.clearSearch);
  const cleanPantryItems = usePantrySelectorContext((state) => state.cleanPantryItems);
  const cleanShoppingLists = useShoppingListSelectorContext((state) => state.cleanShoppingLists);

  const cleanDates = useCalendarSelectorContext((state) => state.cleanDates);
  const clearCookingContext = useCookRecipeSelectorContext((state) => state.clearCookingContext);

  const cleanupForkedContext = useForkedRecipeSelectorContext((state) => state.cleanupForkedContext);

  const clearDashboard = useDashboardSelectorContext((state) => state.clearDashboard);

  useEffect(() => {
    if (rootData.userSession?.userId) {
      setHasChanged(true);
    } else {
      console.log("should cleanup");
      cleanUp();
    }
  }, [rootData.userSession?.userId]);

  const cleanUp = useCallback(() => {
    console.log("called cleanup");
    // if (hasChanged) {
    //only need to cleanup if there was a user logged in before
    clearSearch();
    cleanPantryItems();
    cleanShoppingLists();
    cleanDates();
    clearCookingContext();
    cleanupForkedContext();
    clearDashboard();
    // }
  }, [hasChanged]);

  return {};
};

const CleanUpContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const cleanUpContext = useCleanUpContextVals(props);

  return <CleanUpContext.Provider value={{ ...cleanUpContext }}>{props.children}</CleanUpContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useCleanUpContext = () => useContext(CleanUpContext);
const useCleanupSelectorContext = (selector: any) => {
  return useContextSelector(CleanUpContext, selector);
};
export { CleanUpContextProvider, useCleanUpContext, useCleanupSelectorContext };
