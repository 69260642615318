import { useFetchers, useNavigate, useNavigation } from "@remix-run/react";
import CenterPopup from "antd-mobile/es/components/center-popup";
import ResultPage from "antd-mobile/es/components/result-page";
import Swiper from "antd-mobile/es/components/swiper";

import Toast from "antd-mobile/es/components/toast";
import { MailOpenIcon, PartyPopper } from "lucide-react";
import { Suspense, lazy, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetcher, useLocation } from "react-router-dom";
import { useAccountContext, useAccountSelectorContext } from "~/components/context/accountContext";
import PlansIndButton from "~/components/core/settings/subscription/plans/PlansIndButton";
import BrandIcon from "~/components/icons/BrandIcon";
import Login from "~/components/login/login";
import LoadingButton from "~/components/ui/buttons/LoadingButton";
import CheckIcon from "~/components/ui/icons/CheckIcon";
import ChevronLeftIcon from "~/components/ui/icons/ChevronLeftIcon";
import ExclamationTriangleIcon from "~/components/ui/icons/ExclamationTriangleIcon";
import XIcon from "~/components/ui/icons/XIcon";
import { gtag, gtagEvent } from "~/utils/analytics/gtag";
import { deleteLoggingIn, setLoggingIn } from "~/utils/app/page-restoration";
import { useAppTenantRoute } from "~/utils/data/useRecipeRouteData";
import { useRootData } from "~/utils/data/useRootData";
import { useDidMountEffect } from "~/utils/misc";
import clsx from "~/utils/shared/ClassesUtils";


const PaymentElement = lazy(() => import("~/modules/recipe/components/PaymentElement"));

// loadStripe.setLoadParameters({ advancedFraudSignals: false });

export default function CreateAccountPrompt() {
  const open = useAccountSelectorContext((state) => state.open);
  const closeAccount = useAccountSelectorContext((state) => state.closeAccount);
  const appTenantRoute = useAppTenantRoute();
  const fetcher = useFetcher();
  const [subscriptions, setSubscriptions] = useState([]);
  const [pricesWithTrial, setPricesWithTrials] = useState([]);

  //when the fetcher returns, need to set the state
  useEffect(() => {
    if (fetcher.data) {
      setSubscriptions(fetcher.data.subscriptions);
      setPricesWithTrials(fetcher.data.pricesWithTrial);
    }
  }, [fetcher.data]);

  //once the component mounts, need to get the subscriptions from the server
  useEffect(() => {
    if (open) {
      const form = new FormData();
      form.append("action", "getSubscriptions");
      fetcher.submit(form, { method: "POST", action: "api/subscriptions/list" });
    }
  }, [open]);

  return (
    <CenterPopup
      // target={".forked-search-results"}
      // className={"!absolute"}
      destroyOnClose={true}
      visible={open}
      className={"account-login z-[1001]"}
      onMaskClick={appTenantRoute ? null : closeAccount}
      // onMaskClick={closeAccount}
      // style={{ "--max-width": "100vw" }}
      bodyClassName={"!overflow-hidden"}
      getContainer={() => document.body}
    >
      <CreateAccount subscriptions={subscriptions} pricesWithTrial={pricesWithTrial} />
      {/* <div className="h-96">
        <Elements stripe={stripePromise} options={options}>
          <PaymentElement />
        </Elements>
      </div> */}
    </CenterPopup>
  );
}

export function CreateAccount({ subscriptions, pricesWithTrial }) {
  const { open, forgotPassword, closeAccount, clearAccountForgotPassword, currentInterest } = useAccountContext();
  const swipeRef = useRef(null);
  const fetcher = useFetcher();
  const dataFetcher = useFetcher();
  const subscriptionFetcher = useFetcher();
  const startTrialFetcher = useFetcher();
  const location = useLocation();
  const rootData = useRootData();
  const navigation = useNavigation();
  const forgotFetcher = useFetcher();
  const [userData, setUserData] = useState({});
  const { t } = useTranslation();

  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [subscriptionProductId, setSubscriptionProductId] = useState(null);
  const fetchers = useFetchers();

  useEffect(() => {
    if (fetchers.length === 0) return;
    const fetcher = fetchers.filter((f) => f.formAction === "/login")[0];

    if (fetcher?.state === "submitting") {
      const hasSub = fetcher?.formData.get("hasSub");
      const formData = fetcher.formData;
      const obj = Object.fromEntries(formData.entries());

      if (!hasSub || hasSub === "false") {
        // console.log("going to swipe next");
        // swipeNext();
        swipeRef?.current?.swipeTo(2);
      }
      setUserData(obj);
    }
  }, [fetchers]);

  const {
    userSession: { userId },
    hasSub,
    user,
    usedTrial,
    tenant,
  } = rootData;
  const [currentStep, setCurrentStep] = useState(currentInterest === "navbar_account_link" ? 1 : !userId ? 0 : 2);

  useDidMountEffect(() => {
    if (open) {
      gtagEvent("signupProgress", {
        step: currentStep,
      });
      // if (currentStep === 1) {
      //   //user is signed up
      //   gtagEvent("sign_up", {});
      // }
    }
  }, [currentStep]);

  const handleSuccess = (transaction_id) => {
    gtag({ ecommerce: null });
    gtagEvent("purchase", {
      ecommerce: {
        currency: "USD",
        value: 0,
        transaction_id,
        items: [
          {
            item_name: "Personal Plan",
          },
        ],
      },
    });
  };

  const interestContent = useMemo(() => {
    switch (currentInterest) {
      case "plan":
        return {
          title: "Tired of the meal planning struggle?",
          subtitle: "Say goodbye to the stress and hello to effortless meal planning with Forked!",
          notes: ["Weekly Menus Organized in a Snap", "Smart Shopping Lists", "Add Entire Meal Collections at Once"],
        };
      case "pantry":
      case "ingredient_pantry":
        return {
          title: "Want to keep track of what you have?",
          subtitle: "Forked simplifies your pantry and fridge inventory.",
          notes: ["Add items to your pantry and fridge", "See at a glance what you already have for a recipe", "Never run out of essentials unexpectedly"],
        };
      case "collection":
        return {
          title: "You like to Marie Kondo your recipes?",
          subtitle: "So do we, which is why Forked makes grouping recipes into collections easy.",
          notes: [
            <>
              <strong>Organize with ease</strong>, group recipes and collections.
            </>,
            <>
              <strong>Create a personal collection</strong>, like "Dad's Favorite Snacks"
            </>,
            <>
              <strong>Share!</strong> If you want others to see your collections, make them public!
            </>,
          ],
        };
      case "cook_recipe":
        return {
          title: "Want a simplified cooking experience?",
          subtitle: "Forked's cook mode is a no frills, simple cooking experience.",
          notes: [
            <>
              <strong>Distraction-Free Cooking</strong>. Immersive and clutter-free experience.
            </>,
            <>
              <strong>Step-by-Step Guidance</strong>. Never lose your place again.
            </>,
            <>
              <strong></strong>
            </>,
          ],
        };
      case "recipe_short":
        return {
          title: "There's more to see with a Forked account!",
          subtitle: "More pictures, more notes, more everything!",
          notes: [
            <>
              <strong>More mouth-watering pictures</strong> to help you get inspiration.
            </>,
            <>
              <strong>Plan meals</strong>. Save recipes to your calendar.
            </>,
            <>
              <strong>Smart Shopping Lists</strong> to make sure you never forget an ingredient.
            </>,
          ],
        };
      case "ingredient_shopping_list":
        return {
          title: "Tired of forgetting ingredients?",
          subtitle: "Forked simplifies your shopping list.",
          notes: [
            <>
              <strong>Add all recipe ingredients</strong> to your shopping list with one click.
            </>,
            <>
              <strong>Add one-off or individual items</strong> to your shopping list.
            </>,
            <>
              <strong>Satisfying swipe to complete</strong> items on your shopping list.
            </>,
          ],
        };
      case "save-notes":
        return {
          title: "Add Your Notes! Sign Up Today",
          subtitle: "Create a Forked account to start jotting down your recipe ideas!",
          notes: [
            <>
              <strong>Add your own notes</strong> to any recipe, making it the way you like
            </>,
            <>
              <strong>Edit recipe steps and ingredients</strong> to match your taste preferences and dietary needs
            </>,
            <>
              <strong>Cancel anytime</strong>, no hidden fees or strings attached
            </>,
          ],
        };
      // case "free_trial":
      //   return <Calendar />;
      default:
        return {
          title: "There's more to see with a Forked account!",
          subtitle: "More pictures, more notes, more everything!",
          notes: [
            <>
              <strong>More mouth-watering pictures</strong> to help you get inspiration.
            </>,
            <>
              <strong>Meal Planning</strong>. Save recipes to your calendar.
            </>,
            <>
              <strong>Smart Shopping Lists</strong> to make sure you never forget an ingredient.
            </>,
          ],
        };
    }
  }, [currentInterest]);

  useEffect(() => {
    //if user is logged in and still on the first step go to the next step
    if (userId && currentStep === 0) {
      swipeNext();
    }
  }, [userId]);

  useEffect(() => {
    if (open) {
      const form = new FormData();
      form.set("currentPage", location.pathname);
      fetcher.submit(form, {
        method: "POST",
        action: "/api/cookie/set",
      });
      setLoggingIn(true);
      const otherForm = new FormData();
      dataFetcher.submit(otherForm, {
        method: "POST",
        action: "/api/subscriptions",
      });
    } else {
      deleteLoggingIn();
    }
  }, [open]);

  useEffect(() => {
    if (subscriptionFetcher.data && subscriptionFetcher.data.success) {
      setSubscriptionProductId(subscriptionFetcher.data.subscriptionProductId);
      setSetupIntentClientSecret(subscriptionFetcher.data.setupIntent);
      setCustomerId(subscriptionFetcher.data.customer);
      setCustomerEmail(subscriptionFetcher.data.customerEmail);
      swipeNext();
    }
  }, [subscriptionFetcher.data]);

  useEffect(() => {
    if (startTrialFetcher.data && startTrialFetcher.data.success) {
      if (startTrialFetcher.data.emailVerification) {
        swipeTo(4);
      } else {
        closeAccount();
      }
    }
  }, [startTrialFetcher.data]);

  const handleSelectSubscription = useCallback(
    (subscription) => {
      const form = new FormData();
      form.set("paymentId", subscription["product-id"]);
      form.set("billingPeriod", subscription["billing-period"]);
      // setUserData as formData
      Object.keys(userData).forEach((key) => {
        form.set(key, userData[key]);
      });
      subscriptionFetcher.submit(form, {
        method: "POST",
        action: "/api/subscriptions/create",
      });
    },
    [userData]
  );

  const handleStartTrial = useCallback(() => {
    const form = new FormData();

    Object.keys(userData).forEach((key) => {
      form.set(key, userData[key]);
    });
    startTrialFetcher.submit(form, {
      method: "POST",
      action: "/api/subscriptions/start-trial",
    });
  }, [userData]);

  const swipeNext = () => {
    swipeRef?.current?.swipeNext();
  };
  const swipePrev = () => {
    swipeRef?.current?.swipePrev();
  };

  const swipeTo = (index = 0) => {
    swipeRef?.current?.swipeTo(index);
  };

  const handlePaymentBack = () => {
    setSubscriptionProductId(null);
    setSetupIntentClientSecret(null);
    setCustomerId(null);
    setCustomerEmail(null);
    swipePrev();
  };

  const resultCardClass = "mt-2 text-base text-black max-w-lg w-full";
  const numTrials = pricesWithTrial?.length ?? 0;
  const titleText = usedTrial ? `Sign Up` : `${numTrials > 0 ? t("account.register.startTrial", [Math.min(...pricesWithTrial?.map((p) => p.trialDays))]) : "Create your account"}`;

  useEffect(() => {
    if (forgotFetcher.data?.success) {
      closeAccount();
      clearAccountForgotPassword();
      Toast.show({
        icon: "success",
        content: "Sent password reset email.",
        position: "top",
      });
    }
  }, [forgotFetcher]);

  const forgotPasswordContent = (
    <div className="mt-6">
      <forgotFetcher.Form method="post" className="space-y-6" action={"/forgot-password"}>
        {/* <div className="text-left font-medium">{t("account.reset.headline")}</div> */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-400" htmlFor="email">
            {t("account.shared.email")}
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:z-10 focus:border-theme-500 focus:outline-none focus:ring-theme-500 dark:border-gray-700 dark:bg-gray-900 dark:text-slate-200 sm:text-sm"
              placeholder="email@address.com"
              required
            />
          </div>
        </div>
        <div>
          <LoadingButton
            className="flex w-full justify-center rounded-md border border-transparent bg-theme-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-theme-500 focus:ring-offset-2 dark:bg-gray-900"
            type="submit"
          >
            {t("account.reset.button")}
          </LoadingButton>
        </div>
        <div id="form-error-message">
          {forgotFetcher.data?.error ? (
            <div className="flex items-center justify-center space-x-2 text-sm text-red-500 dark:text-red-300" role="alert">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <div>{forgotFetcher.data.error}</div>
            </div>
          ) : null}
        </div>
      </forgotFetcher.Form>
    </div>
  );

  return (
    <>
      <Swiper
        className={"login-modal h-full max-h-screen"}
        loop={false}
        allowTouchMove={false}
        defaultIndex={currentStep}
        onIndexChange={setCurrentStep}
        ref={swipeRef}
        indicator={() => null}
      >
        <Swiper.Item key={0}>
          <div
            className="flex h-full items-start justify-center"
          // style={{
          //   paddingBottom: "env(safe-area-inset-bottom)",
          // }}
          >
            <ResultPage
              className="min-h-full"
              status="success"
              icon={
                <div className="mb-4 h-24 w-24">
                  <BrandIcon className="z-20 mb-4 p-2" color1="fill-white" />
                </div>
              }
              title={
                <>
                  <h2 className="line-clamp-3 font-lexend text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{interestContent.title}</h2>
                  {/* <p className="mt-2 cursor-pointer text-sm text-gray-600">
                    <span className="font-medium text-gray-600 hover:text-gray-500">
                      {numTrials > 0 ? t("account.register.startTrial", [Math.min(...pricesWithTrial?.map((p) => p.trialDays))]) : "Start your free trial"}
                    </span>
                  </p> */}
                </>
              }
              description={<p className="mt-6 text-xl leading-8 text-gray-900">{interestContent.subtitle}</p>}
              // secondaryButtonText={
              //   <>
              //     <XIcon className="h-6 w-6" />
              //   </>
              // }
              // onSecondaryButtonClick={closeAccount}
              primaryButtonText={"Free Trial"}
              onPrimaryButtonClick={swipeNext}
              secondaryButtonText={"Sign In"}
              onSecondaryButtonClick={swipeNext}
            >
              <div className="flex flex-col items-center">
                <div className="fixed left-0 top-0 flex h-16 w-16 flex-col items-center justify-center p-4" onClick={closeAccount}>
                  <XIcon className="h-full w-full" />
                </div>
                <ResultPage.Card className={resultCardClass}>
                  {interestContent.content ? (
                    interestContent.content
                  ) : (
                    <div className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                      {interestContent.notes?.map((note, index) => {
                        return (
                          <div className="flex items-center font-semibold text-gray-900" key={index}>
                            <CheckIcon className="h-10 w-10 flex-none text-theme-600" aria-hidden="true" />
                            <div className="flex-1 px-2">{note}</div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </ResultPage.Card>

                {/* <ResultPage.Card className={clsx(resultCardClass, "nested-list")}>
          <RecipeTimer min={isCurrentStep && min} max={isCurrentStep && max} unit={isCurrentStep && unit} />
    </ResultPage.Card> */}
              </div>
            </ResultPage>
          </div>
        </Swiper.Item>
        {/* <Swiper.Item key={1}></Swiper.Item>
        <Swiper.Item key={2}></Swiper.Item> */}
        <Swiper.Item key={1}>
          <div
            className="flex h-full items-start justify-center"
          // style={{
          //   paddingBottom: "env(safe-area-inset-bottom)",
          // }}
          >
            <ResultPage
              className="min-h-full"
              status="success"
              icon={
                <div className="mb-4 h-24 w-24">
                  <BrandIcon className="z-20 mb-4 p-2" color1="fill-white" />
                </div>
              }
              title={
                <>
                  <h2 className="line-clamp-3 font-lexend text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {t("account.login.title")}
                    <div className="relative my-3">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="m-auto w-full border-t border-black" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="bg-theme-300 px-2">Or</span>
                      </div>
                    </div>
                    {titleText}
                  </h2>
                  {/* <p className="mt-2 cursor-pointer text-sm text-gray-600">
                    <span className="font-medium text-gray-600 hover:text-gray-500">
                      {numTrials > 0 ? t("account.register.startTrial", [Math.min(...pricesWithTrial?.map((p) => p.trialDays))]) : "Start your free trial"}
                    </span>
                  </p> */}
                </>
              }
              description={
                <p className="mt-6 text-xl leading-8 text-gray-900">
                  We are so <span className="font-lexend font-bold tracking-tighter">forking</span> excited to have you!
                </p>
              }
              // secondaryButtonText={
              //   <>
              //     <XIcon className="h-6 w-6" />
              //   </>
              // }
              // onSecondaryButtonClick={closeAccount}
              primaryButtonText={user?.email && "Subscribe"}
              onPrimaryButtonClick={user?.email && swipeNext}
            // secondaryButtonText={
            //   <>
            //     <ChevronLeftIcon className="h-6 w-6" />
            //     Login
            //   </>
            // }
            // onSecondaryButtonClick={() => console.log("close")}
            >
              <div className="flex flex-col items-center">
                <div className="fixed left-0 top-0 flex h-16 w-16 flex-col items-center justify-center p-4" onClick={closeAccount}>
                  <XIcon className="h-full w-full" />
                </div>
                <ResultPage.Card className={resultCardClass}>
                  {user?.email ? <LoggedIn user={user} tenant={tenant} /> : forgotPassword ? forgotPasswordContent : <Login />}
                  {/* {logoutLoading ||
                    (true && (
                      <div className="relative mb-4 h-24 w-24">
                        <ClientOnly fallback={<BrandIcon className="absolute z-20 mb-4 p-2" />}>
                          {() => (
                            <>
                              <BrandIcon className="absolute z-20 mb-4 p-2" />
                              <SpinLoading className="z-10" style={{ "--size": "96px" }} />
                            </>
                          )}
                        </ClientOnly>
                      </div>
                    ))} */}
                </ResultPage.Card>

                {/* <ResultPage.Card className={clsx(resultCardClass, "nested-list")}>
          <RecipeTimer min={isCurrentStep && min} max={isCurrentStep && max} unit={isCurrentStep && unit} />
    </ResultPage.Card> */}
              </div>
            </ResultPage>
          </div>
        </Swiper.Item>

        <Swiper.Item key={2}>
          <div
            className="flex h-full items-start justify-center"
          // style={{
          //   paddingBottom: "env(safe-area-inset-bottom)",
          // }}
          >
            <ResultPage
              className="footer-navigation min-h-full"
              status="success"
              title={
                <>
                  <h2 className="line-clamp-2 font-lexend text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{usedTrial ? 'Signup Today!' : 'Start Your Free Trial Today!'}</h2>
                </>
              }
              // primaryButtonText={"Start Free Trial"}
              // onPrimaryButtonClick={swipeNext}
              // secondaryButtonText={"Previous"}
              // onSecondaryButtonClick={swipePrev}
              icon={
                <div className="mb-4 h-24 w-24">
                  <BrandIcon className="z-20 mb-4 p-2" color1="fill-white" />
                </div>
              }
              secondaryButtonText={
                <>
                  <ChevronLeftIcon className="h-6 w-6" />
                  Login
                </>
              }
              onSecondaryButtonClick={swipePrev}
            >
              {/* {subscriptionFetcher.state !== "idle" && <SpinLoading className="z-10" style={{ "--size": "96px" }} />} */}
              <div className="absolute z-10 -m-3 flex h-full flex-col items-center justify-center">
                {/* <SpinLoading className="z-10" style={{ "--size": "96px" }} /> */}
              </div>
              <div className="flex flex-col items-center">
                <ResultPage.Card className={clsx(resultCardClass, "bg-transparent backdrop-blur")}>
                  <PlansIndButton items={subscriptions} canSubmit={true} customOnClick={handleSelectSubscription} startTrial={handleStartTrial} />

                </ResultPage.Card>
              </div>
            </ResultPage>
          </div>
        </Swiper.Item>
        <Swiper.Item key={3}>
          <div
            className="flex h-full items-start justify-center"
          // style={{
          //   paddingBottom: "env(safe-area-inset-bottom)",
          // }}
          >
            <Suspense fallback={<div>Loading...</div>}>
              {setupIntentClientSecret && (
                <PaymentElement
                  clientSecret={setupIntentClientSecret}
                  swipePrev={handlePaymentBack}
                  resultCardClass={resultCardClass}
                  customerId={customerId}
                  subscriptionProductId={subscriptionProductId}
                  afterSuccess={handleSuccess}
                  customerEmail={customerEmail}
                />
              )}
            </Suspense>
          </div>
        </Swiper.Item>
        <Swiper.Item key={4}>
          <div
            className="flex h-full items-start justify-center"
          // style={{
          //   paddingBottom: "env(safe-area-inset-bottom)",
          // }}
          >
            <ResultPage
              className="footer-navigation min-h-full"
              status="success"
              title={
                <>
                  <h2 className="line-clamp-2 font-lexend text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Check Your Email!</h2>
                </>
              }
              // primaryButtonText={"Start Free Trial"}
              // onPrimaryButtonClick={swipeNext}
              // secondaryButtonText={"Previous"}
              // onSecondaryButtonClick={swipePrev}
              icon={
                <div className="mb-4 h-24 w-24">
                  <BrandIcon className="z-20 mb-4 p-2" color1="fill-white" />
                </div>
              }
            // secondaryButtonText={
            //   <>
            //     <ChevronLeftIcon className="h-6 w-6" />
            //     Login
            //   </>
            // }
            // onSecondaryButtonClick={swipePrev}
            >
              <div className="flex flex-col items-center">
                <ResultPage.Card className={resultCardClass}>
                  <div className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                    <div className="flex items-center font-semibold text-gray-900">
                      <PartyPopper className="h-10 w-10 flex-none text-theme-600" aria-hidden="true" />
                      <div className="flex-1 px-2">Congratulations! You are almost there!</div>
                    </div>
                    <div className="flex items-center font-semibold text-gray-900">
                      <MailOpenIcon className="h-10 w-10 flex-none text-theme-600" aria-hidden="true" />
                      <div className="flex-1 px-2">Check your email for a verification link to complete your account setup.</div>
                    </div>
                    <div className="flex items-center text-2xl font-semibold text-gray-900">
                      <div className="flex-1 px-2 text-center">We're Forking Excited to Have You!</div>
                    </div>
                  </div>
                </ResultPage.Card>
              </div>
            </ResultPage>
          </div>
        </Swiper.Item>
      </Swiper>
    </>
  );
}

const LoggedIn = ({ user, tenant }: { user: any; tenant: any }) => {
  const navigate = useNavigate();
  // const logoutFetcher = useFetcher();
  const handleLogout = () => {
    navigate("/logout");
  };
  return (
    <div className="flex flex-col items-center">
      <ul role="list" className="w-full">
        <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            {user?.avatar ? (
              <img className="h-6 w-6 rounded-full" src={user?.avatar} alt="User avatar" />
            ) : tenant?.icon ? (
              <img className="h-6 w-6 rounded-full" src={tenant?.icon} alt="Tenant icon" />
            ) : (
              <span className="flex h-6 w-6 items-center justify-center rounded-full bg-theme-600">
                <BrandIcon />
                {/* <span className="text-sm font-medium leading-none text-theme-200">
                    
                    {appData.currentTenant?.name.substring(0, 1)}
                  </span> */}
              </span>
            )}
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : `${user?.email}`}
                </h3>
                {/* <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">{person.role}</span> */}
              </div>
              {user?.firstName && user?.lastName && user?.email ? <p className="mt-1 truncate text-sm text-gray-500">{user?.email}</p> : null}
            </div>
            <form action="/logout" className="flex" method="post">
              <button className="inline-block flex-shrink-0 rounded-md bg-gray-100 px-3 py-1 text-xs font-medium text-gray-500" type="submit">
                Log Out
              </button>
            </form>

            {/* <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={person.imageUrl} alt="" /> */}
          </div>
          {/* <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <div
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  onClick={handleLogout}
                >
                  <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Log Out
                </div>
              </div>
            </div>
          </div> */}
        </li>
      </ul>
      {/* <h2 className="font-lexend text-3xl font-bold tracking-tight text-gray-900 line-clamp-2 sm:text-4xl">
        {user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : `${user?.email}`}
        <br />
      </h2>
      <p className="mt-2 cursor-pointer text-sm text-gray-600">
        <span className="font-medium text-gray-600 hover:text-gray-500" onClick={handleLogout}>
          Log out
        </span>
      </p> */}
    </div>
  );
};
