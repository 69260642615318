import Button from "antd-mobile/es/components/button";
import List from "antd-mobile/es/components/list";
import NavBar from "antd-mobile/es/components/nav-bar";
import Space from "antd-mobile/es/components/space";
import clsx from "clsx";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import { memo, useCallback, useMemo } from "react";
import CurrentCookRecipe from "~/components/charts/CurrentCookRecipe";
import { useCalendarSelectorContext } from "~/components/context/calendarContext";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import ChevronLeftIcon from "~/components/ui/icons/ChevronLeftIcon";
dayjs.extend(calendar);

function ChooseCookRecipe() {
  const chooseOpen = useCookRecipeSelectorContext((state) => state.chooseOpen);
  const closeChooseCook = useCookRecipeSelectorContext((state) => state.closeChooseCook);
  const recipeCurrentSteps = useCookRecipeSelectorContext((state) => state.recipeCurrentSteps);
  const recipes = useCookRecipeSelectorContext((state) => state.recipes);
  const cookRecipeFinal = useCookRecipeSelectorContext((state) => state.cookRecipeFinal);
  const thisRecipe = useCookRecipeSelectorContext((state) => state.thisRecipe);

  const datesList = useCalendarSelectorContext((state) => state.datesList);

  const recipeWithStep = useMemo(
    () =>
      recipeCurrentSteps
        ?.map((r) => {
          const recipe = recipes.find((r2) => r2.id === r.recipeId);
          if (recipe) {
            return { ...r, ...recipe };
          }
          return null;
        })
        ?.filter((r) => r !== null),
    [recipeCurrentSteps, recipes]
  );

  const theseRecipes = recipeWithStep?.filter((r) => {
    return r.recipeId === thisRecipe?.id;
  });

  const notTheseRecipes = recipeWithStep?.filter((r) => {
    return r.recipeId !== thisRecipe?.id;
  });

  const activeDates = useMemo(() => {
    return recipeWithStep?.map((r) => r.recipeDateId);
  }, [recipeWithStep]);

  const thisWeekRecipes = useMemo(() => {
    return datesList?.filter((r) => {
      return !activeDates.includes(r.id) && dayjs(r.date).isSame(dayjs(), "week");
    });
  }, [datesList, activeDates]);
  const nextWeekRecipes = useMemo(() => {
    return datesList?.filter((r) => {
      return !activeDates.includes(r.id) && dayjs(r.date).isSame(dayjs().add(1, "week"), "week");
    });
  }, [datesList, activeDates]);

  const navbar = (
    <NavBar className="relative bg-theme-300" style={{ "--height": "4rem" }} back={true} backArrow={true} right={null} onBack={closeChooseCook}>
      <div className={clsx("flex h-full flex-col justify-center")}>Where were we?</div>
    </NavBar>
  );

  const handleStartNew = useCallback(() => {
    cookRecipeFinal(thisRecipe);
  }, [cookRecipeFinal, thisRecipe]);

  //   const extraSteppersHeader = (
  //     <div className="flex space-x-3 text-center">
  //       <div className="w-24 pr-1">Pantry</div>
  //       <div className="w-24 pr-1">Cart</div>
  //     </div>
  //   );
  return (
    <PopupWithNavbar className={"choose-cook-popup"} headerClass="bg-theme-300" open={chooseOpen} onClose={closeChooseCook} shadow={false} title={"Cooking"}>
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="nested-list flex flex-1 flex-col items-center">
          <List key={"pantry_list"} className="sticky-items w-full bg-gray-100">
            {theseRecipes?.length > 0 && (
              <List.Item
                className="sticky top-0 z-10 bg-gray-100"
                title={"Continue Cooking"}
                extra={
                  <Button size="mini" onClick={handleStartNew}>
                    <Space align="center">
                      Start New <ChevronLeftIcon className="h-4 w-4 rotate-180" />
                    </Space>
                  </Button>
                }
              />
            )}
            {reverse(
              sortBy(theseRecipes, (val) => {
                return dayjs(val.createdAt).unix();
              })
            ).map((item) => {
              return <CurrentCookRecipe key={item.uuid} {...item} images={item.imageFilename} />;
            })}
            <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Active Recipes"} />
            {reverse(
              sortBy(notTheseRecipes, (val) => {
                return dayjs(val.createdAt).unix();
              })
            ).map((item) => {
              return <CurrentCookRecipe key={item.uuid} {...item} images={item.imageFilename} />;
            })}
            {/* <List.Item className="sticky top-0 z-10 bg-gray-100" title={"This Week"} />
            {thisWeekRecipes?.map((item) => {
              return <UpcomingRecipe key={item.id} {...item} />;
            })}
            <List.Item className="sticky top-0 z-10 bg-gray-100" title={"Next Week"} />
            {nextWeekRecipes?.map((item) => {
              return <UpcomingRecipe key={item.id} {...item} />;
            })} */}
          </List>
          {/* <List key={"pantry_list"} className="w-full bg-gray-100">
          
        </List> */}
        </div>
      </div>
    </PopupWithNavbar>
  );
}

export default memo(ChooseCookRecipe);
