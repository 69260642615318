import { useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";

const ShoppingCartContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useShoppingCartContextVals = () => {
  const [open, setOpen] = useState(false);

  const openShoppingCart = () => {
    setOpen(true);
  };

  const closeShoppingCart = () => {
    setOpen(false);
  };

  return {
    open,
    setOpen,
    openShoppingCart,
    closeShoppingCart,
  };
};

const ShoppingCartContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const shoppingCartContext = useShoppingCartContextVals(props);

  return <ShoppingCartContext.Provider value={{ ...shoppingCartContext }}>{props.children}</ShoppingCartContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useShoppingCartContext = () => useContext(ShoppingCartContext);
const useShoppingCartSelectorContext = (selector: any) => {
  return useContextSelector(ShoppingCartContext, selector);
};
export { ShoppingCartContextProvider, useShoppingCartContext, useShoppingCartSelectorContext };
