import Checkbox from "antd-mobile/es/components/checkbox";
import List from "antd-mobile/es/components/list";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import Fraction from "fraction.js";
import { useCallback, useRef } from "react";

interface IngredientProps {
  ingredient: {
    quantity: number;
    unit: string;
    ingredient: string;
    comment: string;
  };
  leftActions: any[];
  rightActions: any[];
  extra?: any;
}

export default function CookingIngredient({ ingredient, leftActions, rightActions, extra }: IngredientProps) {
  const checkBoxRef = useRef();
  const ingredientRef = useRef();
  const quantityFraction = new Fraction(ingredient.quantity).toFraction(true);
  let quantity;
  if (ingredient.quantity > 0) {
    quantity = quantityFraction;
    if (quantity.split(" ").length > 0) {
      //need to wrap the second item in a span with a class
      const split = quantity.split(" ");
      //if either is a fraction, wrap it in a span with a class
      quantity = split.map((s) => {
        if (s.includes("/")) {
          return <span className="diagonal-fractions">{s}</span>;
        }
        return s;
      });
    }
  }
  //TODO: format the unit (small, large, plural)
  const unit = ingredient.unit ?? null;
  const ingredientName = ingredient.ingredient;
  const all = [unit, ingredientName].filter((n) => n).join(" ");

  const onClick = () => {
    checkBoxRef.current?.toggle();
  };

  const handleActionsRevealed = useCallback(
    (direction: "left" | "right") => {
      if (direction === "left") {
        if (leftActions.length === 1) {
          leftActions[0].onClick && leftActions[0].onClick();
        }
      } else {
        if (rightActions.length === 1) {
          rightActions[0].onClick && rightActions[0].onClick();
        }
      }
      ingredientRef.current?.close();
    },
    [leftActions, rightActions]
  );

  return (
    <SwipeAction leftActions={leftActions} rightActions={rightActions} ref={ingredientRef} onActionsReveal={handleActionsRevealed}>
      <List.Item
        prefix={
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox value={ingredient.id} ref={checkBoxRef} />
          </div>
        }
        className="break-normal text-base font-semibold"
        description={<div className="text-sm">{ingredient.comment}</div>}
        onClick={onClick}
        arrow={false}
        extra={extra}
      >
        {quantity} {all}
      </List.Item>
    </SwipeAction>
  );
}
