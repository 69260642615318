import { useFetcher } from "@remix-run/react";
import CapsuleTabs from "antd-mobile/es/components/capsule-tabs";
import { Group as CheckboxGroup } from "antd-mobile/es/components/checkbox/group";
import List from "antd-mobile/es/components/list";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import { useForkedRecipeSelectorContext } from "~/components/context/forkedRecipesContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import PlusIcon from "~/components/ui/icons/PlusIcon";
import CollectionCheckbox from "./CollectionCheckbox";
dayjs.extend(calendar);

function AddCollection() {
  const addCollectionOpen = useForkedRecipeSelectorContext((state) => state.addCollectionOpen);
  const closeAddCollection = useForkedRecipeSelectorContext((state) => state.closeAddCollection);
  const userCollections = useForkedRecipeSelectorContext((state) => state.userCollections);
  const userMeals = useForkedRecipeSelectorContext((state) => state.userMeals);
  const openNewCollection = useForkedRecipeSelectorContext((state) => state.openNewCollection);
  const setActiveEntity = useForkedRecipeSelectorContext((state) => state.setActiveEntity);

  const thisRecipe = useCookRecipeSelectorContext((state) => state.thisRecipe);
  const thisCollectionId = useCookRecipeSelectorContext((state) => state.thisCollectionId);
  const thisCollection = useMemo(() => {
    return userCollections.find((c) => c.id === thisCollectionId);
  }, [thisCollectionId, userCollections]);
  const [activeTab, setActiveTab] = useState("collection");
  const parentIds = useMemo(() => {
    return thisCollection?.parentCollections?.map((p) => p.collectionId);
  }, [thisCollection?.parentCollections]);

  const [value, setValue] = useState(thisRecipe?.collections ?? parentIds ?? []);

  useEffect(() => {
    if (parentIds) {
      setValue(parentIds);
    }
  }, [parentIds]);

  const fetcher = useFetcher();
  const ref = useRef();

  useEffect(() => {
    if (thisRecipe?.collections) {
      setValue(thisRecipe.collections);
    }
  }, [thisRecipe?.collections]);

  const handleSubmitForm = useCallback(() => {
    const form = new FormData();
    const type = thisRecipe ? "recipe" : "collection";
    form.set("action", `add-${type}-to-collection`);
    form.set("recipeId", thisRecipe?.id);
    form.set("collectionId", thisCollection?.id);
    form.set("collectionIds", value.join(","));
    fetcher.submit(form, { method: "post", action: "/api/recipe/collection" });
    closeAddCollection();
  }, [fetcher, value]);

  const handleClickPlus = useCallback(() => {
    setActiveEntity(activeTab);
    openNewCollection();
  }, [activeTab]);

  const data = useMemo(() => {
    if (activeTab === "collection") {
      return userCollections.filter((c) => c.id !== thisCollectionId && c.public !== 1);
    } else if (activeTab === "meal") {
      return userMeals.filter((c) => c.id !== thisCollectionId);
    }
  }, [activeTab, userCollections, userMeals, thisCollectionId]);

  return (
    <PopupWithNavbar
      className={"add-collection-popup"}
      open={addCollectionOpen}
      onClose={closeAddCollection}
      shadow={false}
      title={`Choose Collection`}
      headerClass="bg-theme-300"
      subtitle={thisRecipe?.name ?? thisCollection?.name}
      actions={[
        {
          key: "submit",
          text: "Submit",
          color: "primary",
          onClick: handleSubmitForm,
        },
      ]}
    >
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="nested-list sticky-items flex flex-1 flex-col ">
          <List>
            <div className="sticky top-0 z-10 overflow-hidden bg-white">
              <List.Item
                extra={
                  <div className="w-16 cursor-pointer p-4" onClick={handleClickPlus}>
                    <PlusIcon className="h-full" />
                  </div>
                }
              // className={clsx("flex-1")}
              >
                <CapsuleTabs
                  className="segment nested-capsule-tabs"
                  style={{ "--adm-color-border": "transparent" }}
                  onChange={setActiveTab}
                  activeKey={activeTab}
                >
                  <CapsuleTabs.Tab title="Collections" key="collection" />
                  {/* <CapsuleTabs.Tab title="Meals" key="meal" /> */}
                  {/* <CapsuleTabs.Tab title="Store" key="stores" /> */}
                </CapsuleTabs>
              </List.Item>
            </div>
            <SwipeAction className="sticky top-[70px] z-10 bg-gray-100" leftActions={[]} rightActions={[]}>
              <List.Item key={"incompleteTitle"} className={"bg-gray-100"}>
                {"Collections"}
              </List.Item>
            </SwipeAction>
            <CheckboxGroup
              value={value}
              onChange={(val) => {
                setValue(val);
              }}
            >
              {data.map((item) => {
                return <CollectionCheckbox collection={item} key={item.id} leftActions={[]} rightActions={[]} disabled={item.id === thisCollectionId} />;
              })}
            </CheckboxGroup>
          </List>

          {/* <List key={"pantry_list"} className="w-full bg-gray-100">
            
          </List> */}
        </div>
      </div>
    </PopupWithNavbar>
  );
}
export default memo(AddCollection);
