import Input from "antd-mobile/es/components/input";
import PickerView from "antd-mobile/es/components/picker-view";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePagesizeSelectorContext } from "~/components/context/pageSizeContext";
import { useTimerSelectorContext } from "~/components/context/timerContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";

export default function CookingTimer() {
  const open = useTimerSelectorContext((state) => state.open);
  const closeTimer = useTimerSelectorContext((state) => state.closeTimer);
  const timer = useTimerSelectorContext((state) => state.timer);
  const setTimer = useTimerSelectorContext((state) => state.setTimer);
  const [timeSelected, setTimeSelected] = useState(timer);
  const sizes = usePagesizeSelectorContext((state) => state.sizes);

  useEffect(() => {
    setTimeSelected(timer);
  }, [timer]);
  const isLarge = useMemo(() => {
    return sizes.md;
  }, [sizes.md]);
  const addTimes = useMemo(() => {
    let times = [
      { label: "1 min", time: [0, 1, 0] },
      { label: "30 min", time: [0, 30, 0] },
      { label: "1 hr", time: [1, 0, 0] },
    ];
    //if the timer is not all 0, then the timer should be the middle option
    if (timer[0] || timer[1] || timer[2]) {
      const timeInSec = timer[0] * 3600 + timer[1] * 60 + timer[2];
      const min = Math.floor(timeInSec * 0.8);
      const max = Math.floor(timeInSec * 1.2);
      //split min seconds to h, m, s
      const minH = Math.floor(min / 3600);
      const minM = Math.floor((min - minH * 3600) / 60);
      const minS = min - minH * 3600 - minM * 60;
      //split max seconds to h, m, s
      const maxH = Math.floor(max / 3600);
      const maxM = Math.floor((max - maxH * 3600) / 60);
      const maxS = max - maxH * 3600 - maxM * 60;

      times[0] = { label: `${minH ? minH + " hr" : ""} ${minM ? minM + " min" : ""} ${minS ? minS + " sec" : ""}`.trim(), time: [minH, minM, minS] };
      times[1] = { label: `${timer[0] ? timer[0] + " hr" : ""} ${timer[1] ? timer[1] + " min" : ""} ${timer[2] ? timer[2] + " sec" : ""}`.trim(), time: timer };
      times[2] = { label: `${maxH ? maxH + " hr" : ""} ${maxM ? maxM + " min" : ""} ${maxS ? maxS + " sec" : ""}`.trim(), time: [maxH, maxM, maxS] };
    }

    return times;
  }, [timer]);

  const handleInputChange = useCallback(
    (index, value) => {
      const newTime = [...timeSelected];
      newTime[index] = parseInt(value);
      setTimeSelected(newTime);
    },
    [timeSelected]
  );

  const hours = useMemo(() => {
    return [...Array(20).keys()].map((hour) => {
      return { label: String(hour).padStart(2, "0"), value: hour };
    });
  }, []);

  const minutes = useMemo(() => {
    return [...Array(59).keys()].map((min) => {
      return { label: String(min).padStart(2, "0"), value: min };
    });
  }, []);

  const seconds = useMemo(() => {
    return [...Array(4).keys()].map((min) => {
      const mins = 15 * min;
      return { label: String(mins).padStart(2, "0"), value: mins };
    });
  }, []);

  const handleChangeTime = (value) => {
    setTimeSelected(value);
  };

  const handleCancel = () => {
    closeTimer();
  };

  const handleSave = useCallback(() => {
    setTimer(timeSelected);
    closeTimer();
  }, [timeSelected]);
  return (
    <PopupWithNavbar
      className={"cooking-timer-popup"}
      headerClass="bg-theme-300"
      // destroyOnClose={true}
      open={open}
      onClose={closeTimer}
      title={"Set Timer"}
      navbarProps={{ className: "relative bg-theme-300" }}
      shadow={false}
      position={isLarge ? "right" : "bottom"}
      actions={[
        // {
        //   key: "cancel",
        //   text: "exit",
        //   onClick: handleCancel,
        // },
        {
          key: "save",
          text: "Save",
          // danger: true,
          primary: true,
          color: "primary",
          onClick: handleSave,
        },
      ]}
    >
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="nested-list flex flex-1 flex-col">
          {/* <div className="grid grid-flow-col justify-between gap-2 bg-gray-100 py-2">
            <div className="p-2 text-center text-base">Presets</div> */}
          {/* <div className="p-2 text-center text-base">edit</div> */} {/** EDIT was meant for user to add their own preferences**/}
          {/* </div> */}
          {/* <div className="grid grid-cols-3 gap-2 p-2">
            {addTimes.map((time, index) => {
              return (
                <Button key={index} style={{ "--background-color": "var(--adm-color-box)", "--border-width": "0" }} onClick={() => setTimeSelected(time.time)}>
                  {time.label}
                </Button>
              );
            })}
          </div> */}
          <div className="grid grid-cols-3 gap-2 bg-gray-100 py-2">
            <div className="p-2 text-center text-base">Hours</div>
            <div className="p-2 text-center text-base">Minutes</div>
            <div className="p-2 text-center text-base">Seconds</div>
          </div>
          <div className="z-10 grid h-14 grid-cols-3 gap-2 p-2">
            {timeSelected?.map((time, index) => {
              return (
                <Input
                  value={String(time).padStart(2, "0")}
                  type="number"
                  key={index}
                  max={index === 0 ? 20 : 59}
                  min={0}
                  onChange={(val) => handleInputChange(index, val)}
                  style={{ "--text-align": "center", "--background-color": "var(--adm-color-box)", "--border-width": "0" }}
                />
              );
            })}
          </div>
          <div className="z-0 h-52 overflow-hidden">
            <PickerView
              style={{ "--height": "208px" }}
              // className="z-0"
              onChange={handleChangeTime}
              value={timeSelected}
              columns={[hours, minutes, seconds]}
              mouseWheel={true}
            />
          </div>
        </div>
      </div>
    </PopupWithNavbar>
  );
}
