import Button from "antd-mobile/es/components/button";
import { Collapse, CollapsePanel } from "antd-mobile/es/components/collapse/collapse";
import List from "antd-mobile/es/components/list";
import ProgressCircle from "antd-mobile/es/components/progress-circle";
import Slider from "antd-mobile/es/components/slider";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTimerSelectorContext } from "~/components/context/timerContext";
import ChevronLeftIcon from "~/components/ui/icons/ChevronLeftIcon";
import PauseIcon from "~/components/ui/icons/PauseIcon";
import RefreshIcon from "~/components/ui/icons/RefreshIcon";
import clsx from "~/utils/shared/ClassesUtils";

type RecipeTimerProps = {
  min: string;
  max: string;
  unit: string;
};

export default function RecipeTimer({ min, max, unit }: RecipeTimerProps) {
  const openTimer = useTimerSelectorContext((state) => state.openTimer);
  const timer = useTimerSelectorContext((state) => state.timer);
  const isActive = useTimerSelectorContext((state) => state.isActive);
  const startTimer = useTimerSelectorContext((state) => state.startTimer);
  const startTime = useTimerSelectorContext((state) => state.startTime);
  const remainingTime = useTimerSelectorContext((state) => state.remainingTime);
  const percent = useTimerSelectorContext((state) => state.percent);
  const endTime = useTimerSelectorContext((state) => state.endTime);
  const pauseTimer = useTimerSelectorContext((state) => state.pauseTimer);
  const resumeTimer = useTimerSelectorContext((state) => state.resumeTimer);
  const addToTimer = useTimerSelectorContext((state) => state.addToTimer);
  const resetTimer = useTimerSelectorContext((state) => state.resetTimer);
  const setTimer = useTimerSelectorContext((state) => state.setTimer);
  const formattedUnit = useMemo(() => {
    if (["min", "mins", "m", "minute", "minutes"].includes(unit)) return "minutes";
    if (["sec", "secs", "s", "second", "seconds"].includes(unit)) return "seconds";
    if (["hour", "hours", "h", "hrs", "hr"].includes(unit)) return "hours";
    return null;
  }, [unit]);
  const [collapseOpen, setCollapseOpen] = useState(null);

  const formattedMin = min ? parseInt(min) : null;
  const formattedMax = max ? parseInt(max) : min ? parseInt(min) : null;

  useEffect(() => {
    if (formattedMin || formattedMax) {
      switch (formattedUnit) {
        case "minutes":
          setTimer([0, formattedMin, 0]);
          break;
        case "seconds":
          setTimer([0, 0, formattedMin]);
          break;
        case "hours":
          setTimer([formattedMin, 0, 0]);
          break;
      }
    } else {
      setTimer([0, 0, 0]);
    }
  }, [formattedMin, formattedMax, formattedUnit]);

  const addTimes = [
    { label: "1min", value: [0, 1, 0] },
    { label: "3min", value: [0, 3, 0] },
    { label: "5min", value: [0, 5, 0] },
  ];

  const remainingTimeFormatted = useMemo(() => {
    const date = new Date(0);
    date.setSeconds(remainingTime);
    return date.toISOString().substring(11, 19);
  }, [remainingTime]);

  const handleClickPlay = useCallback(
    (e) => {
      e.stopPropagation();
      const [hours, minutes, seconds] = timer;
      if (hours === 0 && minutes === 0 && seconds === 0) {
        openTimer();
        return;
      }
      if (isActive) {
        pauseTimer();
      } else if (remainingTime > 0) {
        resumeTimer();
      } else {
        startTimer();
      }
    },
    [isActive, pauseTimer, resumeTimer, remainingTime, startTimer, timer]
  );

  const timerProgressCircle = (size, trackWidth) => {
    return (
      <ProgressCircle
        style={{
          "--fill-color": "var(--adm-color-success)",
          "--size": `${size}px`,
          "--track-width": `${trackWidth}px`,
        }}
        percent={percent}
      >
        <div style={{ height: `${size / 2}px` }}>
          {isActive ? <PauseIcon className="h-full w-full" /> : <ChevronLeftIcon className={clsx("h-full w-full rotate-180")} />}
        </div>
      </ProgressCircle>
    );
  };
  const timerHeader = (
    <List style={{ "--font-size": "1rem", "--header-font-size": "1rem", "--border-top": "none", "--border-bottom": "none" }}>
      <List.Item
        title={"Timer"}
        extra={
          <div className="flex h-6 items-center gap-2" onClick={handleClickPlay}>
            {timerProgressCircle(24, 2)}
            <div>{remainingTimeFormatted}</div>
          </div>
        }
      />
    </List>
  );

  return (
    <Collapse className="p-0-content swipable-collapse" activeKey={isActive ? "1" : collapseOpen} onChange={setCollapseOpen}>
      <CollapsePanel title={timerHeader} key="1">
        <List style={{ "--font-size": "1rem", "--header-font-size": "1rem", "--border-top": "none", "--border-bottom": "none" }}>
          <List.Item
            prefix={<div onClick={handleClickPlay}>{timerProgressCircle(48, 3)}</div>}
            extra={
              <div onClick={resetTimer}>
                <RefreshIcon className="h-6 w-6" />
              </div>
            }
          >
            <div className="flex h-4 justify-between text-xs text-gray-400">
              <div>{startTime ? dayjs(startTime).format("h:mm a") : null}</div>
              <div>{endTime ? dayjs(endTime).format("h:mm a") : null}</div>
            </div>
            <Slider
              className={"w-full"}
              value={percent}
              style={{ "--fill-color": "var(--adm-color-success)" }}
            // onAfterChange={toastValue}
            />
            <div className="flex justify-between">
              <div onClick={openTimer}>{timer ? timer?.map((time) => String(time).padStart(2, "0")).join(":") : "Click to set Time"}</div>
              <div>{remainingTimeFormatted}</div>
            </div>
          </List.Item>
          <List.Item>
            <div className="grid grid-cols-3 gap-2">
              {/* Map through preset additional times */}
              <Button color="primary" onClick={handleClickPlay}>
                {isActive ? "Pause" : "Start"}
              </Button>
              <Button color="default" onClick={openTimer}>
                Set
              </Button>
              <Button color="danger" onClick={resetTimer}>
                Reset
              </Button>
              {/* {addTimes.map((time, index) => {
                return (
                  <Button key={index} style={{ "--background-color": "var(--adm-color-box)", "--border-width": "0" }} onClick={() => addToTimer(...time.value)}>
                    +{time.label}
                  </Button>
                );
              })} */}
            </div>
          </List.Item>
        </List>
      </CollapsePanel>
    </Collapse>
  );
}
