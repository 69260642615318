import { Outlet } from "@remix-run/react";
import Dialog from "antd-mobile/es/components/dialog";
import PullToRefresh from "antd-mobile/es/components/pull-to-refresh";
import { memo, useEffect, useMemo, useState } from "react";
import PullRefreshLayout from "~/components/app/PullRefreshLayout";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import { usePagesizeSelectorContext } from "~/components/context/pageSizeContext";
import { useSearchSelectorContext } from "~/components/context/searchContext";
import AppLayout2 from "~/components/layouts/AppLayout";
import PageLayout from "~/components/layouts/PageLayout";
import AppTenantTabbar from "~/components/ui/footers/AppTenantTabbar";
import AppTenantNavbar from "~/components/ui/headers/AppTenantNavbar";
import Achievements from "~/modules/recipe/components/Achievements";
import AddCollection from "~/modules/recipe/components/AddCollection";
import ChooseCookRecipe from "~/modules/recipe/components/ChooseCookRecipe";
import CollectionForm from "~/modules/recipe/components/CollectionForm";
import CookRecipe from "~/modules/recipe/components/CookRecipe";
import CreateAccountPrompt from "~/modules/recipe/components/CreateAccountPrompt.1";
import ForkingRecipeModal from "~/modules/recipe/components/ForkingRecipeModal";
import RecipeCalendar from "~/modules/recipe/components/RecipeCalendar";
import ShoppingCart from "~/modules/recipe/components/ShoppingCart";
import UserCalendar from "~/modules/recipe/components/UserCalendar";
import UserShopping from "~/modules/recipe/components/UserShopping";
import { closeConfirmModal } from "~/modules/recipe/components/confirmModal";
import { gtag } from "~/utils/analytics/gtag";
import { deleteCooking, deleteCurrentCooking } from "~/utils/app/page-restoration";
import { useOnFrontend, useOnProfile, useOnRecipeList, useOnTraining } from "~/utils/data/useRecipeRouteData";
import { useRootData } from "~/utils/data/useRootData";
import clsx from "~/utils/shared/ClassesUtils";

// import RecipeCalendar from "~/modules/recipe/components/RecipeCalendar";
// const RecipeCalendar = lazy(() => import("~/modules/recipe/components/RecipeCalendar"));
// import ShoppingCart from "~/modules/recipe/components/ShoppingCart";
// const ShoppingCart = lazy(() => import("~/modules/recipe/components/ShoppingCart"));

// // import UserCalendar from "~/modules/recipe/components/UserCalendar";
// const UserCalendar = lazy(() => import("~/modules/recipe/components/UserCalendar"));

// // import UserShopping from "~/modules/recipe/components/UserShopping";
// const UserShopping = lazy(() => import("~/modules/recipe/components/UserShopping"));

function PullToRefreshWrapper() {
  const closeAccount = useAccountSelectorContext((state) => state.closeAccount);

  const onFrontend = useOnFrontend();
  const captchaVisible = useSearchSelectorContext((state) => state.captchaVisible);
  const searchActive = useSearchSelectorContext((state) => state.searchActive);

  const rootData = useRootData();
  const [loggedIn, setLoggedIn] = useState(false);
  const sizes = usePagesizeSelectorContext((state) => state.sizes);

  const onRecipeList = useOnRecipeList();
  const onTraining = useOnTraining();
  const onProfile = useOnProfile();

  const isLarge = useMemo(() => {
    return sizes.lg;
  }, [sizes.lg]);

  const uid = useMemo(() => {
    return rootData?.userSession?.userId;
  }, [rootData?.userSession?.userId]);
  useEffect(() => {
    gtag({ userId: uid });
  }, [uid]);

  useEffect(() => {
    if (rootData.userSession?.userId) {
      setLoggedIn(true);
    } else {
      if (loggedIn) {
        deleteCooking();
        deleteCurrentCooking();
      }
    }
  }, [rootData.userSession?.userId]);

  const appendCaptcha = () => {
    const captchaEl = document.getElementById("recaptcha-element");
    const captchaDialog = document.getElementById("recaptcha-dialog");
    if (captchaDialog) {
      captchaDialog.appendChild(captchaEl);
    }
  };

  useEffect(() => {
    if (captchaVisible) {
      setTimeout(appendCaptcha, 500);
    }
  }, [captchaVisible]);

  useEffect(() => {
    function onKeydown(event: any) {
      // if the escape key is pressed
      console.log('event.key', event.key)
      if (event.key === "Escape") {
        closeConfirmModal();
        closeAccount();
      }
    }
    window.addEventListener("keydown", onKeydown);
    return () => {
      window.removeEventListener("keydown", onKeydown);
    };
  }, []);

  const isOneColumn = useMemo(() => {
    if (onFrontend) return true;
    return !isLarge;
    // return (onRecipeList || onTraining || onProfile) && !isLarge;
  }, [isLarge, onRecipeList, onTraining, onProfile, onFrontend]);

  return (
    // <KApp theme={theme} className={`k-${theme}`} dark={dark}>
    // <ConfigProvider locale={enUS}>
    <>

      <PullToRefresh
        onRefresh={async () => {
          window.location.reload();
        }}
        className="pull-to-refresh"
        // renderText={(status) => {
        //   return <div>Refreshing</div>;
        // }}
        canReleaseText="Release to refresh"
        refreshingText="Loading..."
        completeText="Refreshing"
        pullingText="Pull down to refresh"
      >
        <PullRefreshLayout layout="app">
          <AppLayout2
            navbar={<AppTenantNavbar />}
            tabbar={onFrontend ? null : <AppTenantTabbar />}
            tabBarProps={{
              side: "right",
              breakpointClass: "lg",
              sideSize: "1/4",
              mainSize: "3/4",
              sider: true,
            }}
            relativeContent={true}
            layoutClassName={clsx(onFrontend ? undefined : isOneColumn ? "h-full" : "lg:h-full", searchActive && "searching")}
          >
            <PageLayout className={clsx("root-page-layout flex-1", !isLarge && " px-safe", isOneColumn ? "" : "lg:overflow-hidden")}>
              <Outlet />
            </PageLayout>
            {/* <Loader /> */}
          </AppLayout2>
          <RecipeCalendar />

          <CookRecipe />
          <ShoppingCart />
          <UserCalendar />
          <UserShopping />
          <ChooseCookRecipe />
          <CollectionForm />
          <AddCollection />
          {/* <Pantry /> */}
          <Achievements />
          <ForkingRecipeModal />
          <CreateAccountPrompt />
          {/* <NewsletterSignup /> */}
          {/* <BarcodeScanning /> */}
        </PullRefreshLayout>
      </PullToRefresh>
      <Dialog
        visible={captchaVisible}
        // onBackdropClick={() => set(false)}
        title="Please Verify You are Human"
        className="text-center"
        bodyClassName="w-auto"
        content={<div id={"recaptcha-dialog"}></div>}
      // titleFontSizeIos="text-xl"
      // titleFontSizeMaterial="text-xl"
      // buttons={
      //   <>
      //     <DialogButton onClick={() => setBasicOpened(false)}>Action 2</DialogButton>
      //     <DialogButton onClick={() => setBasicOpened(false)}>Action 1</DialogButton>
      //   </>
      // }
      />
    </>
    // </ConfigProvider>
    // </KApp>
  );
}

export default memo(PullToRefreshWrapper);
