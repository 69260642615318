import Toast from "antd-mobile/es/components/toast";
import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";
import { useInterval } from "~/utils/misc";

const TimerContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const DEFAULT_DELAY = 1000;

//regex to find hours, minutes, seconds in string with numbers possibly split by dash or "to"

const useTimerContextVals = () => {
  const [open, setOpen] = React.useState(false);
  const [timer, setTimer] = React.useState([0, 0, 0]);
  const [isActive, setIsActive] = React.useState(false);
  const [endTime, setEndTime] = React.useState(null);
  const [remainingTime, setRemainingTime] = React.useState(null);
  const [percent, setPercent] = React.useState(0);
  const [timerStartTime, setTimerStartTime] = React.useState(null);
  // const [audio] = React.useState(typeof Audio !== "undefined" && new Audio(`${window.ENV.CDN_IMG}media/audio/timer_end_1.mp3`));

  const getSecondsFromExpiry = useCallback(() => {
    const remainingTime = endTime ? dayjs(endTime).diff(dayjs(), "second") : 0;
    setRemainingTime(remainingTime);
  }, [endTime]);

  const startTime = useMemo(() => {
    const [hour, minute, second] = timer || [0, 0, 0];
    return endTime ? dayjs(endTime).subtract(hour, "hour").subtract(minute, "minute").subtract(second, "second").valueOf() : 0;
  }, [endTime, timer]);

  const getPercent = useCallback(() => {
    const currentTime = dayjs().valueOf();
    const diffStart = currentTime - startTime;
    const diffEnd = endTime - startTime;
    const percent = endTime ? (diffStart / diffEnd) * 100 : 0;
    setPercent(percent);
  }, [endTime, startTime, remainingTime]);

  useInterval(
    () => {
      if (remainingTime <= 0) {
        setPercent(100);
        deactivateTimer();
        // audio && audio.play();
      } else {
        getSecondsFromExpiry();
        getPercent();
      }
    },
    isActive ? DEFAULT_DELAY : null
  );

  const activateTimer = () => {
    setIsActive(true);
  };
  const deactivateTimer = () => {
    setIsActive(false);
  };

  const openTimer = () => {
    setOpen(true);
  };

  const closeTimer = () => {
    setOpen(false);
  };

  const startTimer = useCallback(() => {
    const [hours, minutes, seconds] = timer;
    if (hours || minutes || seconds) {
      const end = dayjs().add(hours, "hour").add(minutes, "minute").add(seconds, "second");
      const endTime = end.valueOf();
      setEndTime(endTime);
      const remainingTime = endTime ? dayjs(endTime).diff(dayjs(), "second") : 0;
      setRemainingTime(remainingTime);
      activateTimer();
      setTimerStartTime(dayjs().valueOf());
    } else {
      Toast.show("Please set a timer");
    }
    //need to set
  }, [timer]);

  const pauseTimer = useCallback(() => {
    if (isActive) {
      deactivateTimer();
    }
    //need to set
  }, [isActive, timer]);

  const resumeTimer = useCallback(() => {
    if (!isActive) {
      setEndTime(dayjs().add(remainingTime, "second").valueOf());
      activateTimer();
      if (!timerStartTime) {
        setTimerStartTime(dayjs().valueOf());
      }
    }
    //need to set
  }, [isActive, timer, remainingTime]);

  const resetTimer = useCallback(() => {
    deactivateTimer();
    setEndTime(null);
    // setRemainingTime(null);
    setPercent(0);
    setTimerStartTime(null);
    handleSetTimer(timer);
    //need to set
  }, [isActive, timer]);

  const addToTimer = useCallback(
    (hours, minutes, seconds) => {
      const [currentHours, currentMinutes, currentSeconds] = timer;
      const newHours = currentHours + hours;
      const newMinutes = currentMinutes + minutes;
      const newSeconds = currentSeconds + seconds;
      setTimer([newHours, newMinutes, newSeconds]);
      const newEndTime = dayjs(endTime).add(seconds, "second").add(hours, "hours").add(minutes, "minutes").valueOf();
      setEndTime(newEndTime);
      const remainingTime = newEndTime ? dayjs(newEndTime).diff(dayjs(), "second") : 0;
      setRemainingTime(remainingTime);
    },
    [timer, endTime]
  );

  const handleSetTimer = useCallback(
    (timer) => {
      setTimer(timer);
      const [hours, minutes, seconds] = timer;
      const endTime = dayjs().add(hours, "hour").add(minutes, "minute").add(seconds, "second").valueOf();
      const remainingTime = endTime ? dayjs(endTime).diff(dayjs(), "second") : 0;
      setRemainingTime(remainingTime);
    },
    [setTimer]
  );

  return {
    open,
    setOpen,
    openTimer,
    closeTimer,
    timer,
    setTimer: handleSetTimer,
    isActive,
    startTimer,
    endTime,
    remainingTime,
    percent,
    pauseTimer,
    resumeTimer,
    addToTimer,
    resetTimer,
    startTime: timerStartTime,
  };
};

const TimerContextProviderChild = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const pantryContext = useTimerContextVals();

  return <TimerContext.Provider value={{ ...pantryContext }}>{props.children}</TimerContext.Provider>;
};

const arePropsEqual = (prevProps, nextProps) => {
  return true;
  // Compare prop values and return true if they are equal,
  // or false if they have changed and component should re-render.
};

const TimerContextProvider = React.memo(TimerContextProviderChild, arePropsEqual);

TimerContextProvider.displayName = "TimerContextProvider";
/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useTimerContext = () => useContext(TimerContext);
const useTimerSelectorContext = (selector: any) => {
  return useContextSelector(TimerContext, selector);
};
export { TimerContextProvider, useTimerContext, useTimerSelectorContext };
