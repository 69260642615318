import { Transition } from "@headlessui/react";
import { useNavigate } from "@remix-run/react";
import { TabBar, TabBarItem } from "antd-mobile/es/components/tab-bar/tab-bar";
import Toast from "antd-mobile/es/components/toast";
import { memo, useCallback } from "react";
import { useCalendarSelectorContext } from "~/components/context/calendarContext";
import { useCommandPaletteSelectorContext } from "~/components/context/commandPaletteContext";
import { useCookRecipeSelectorContext } from "~/components/context/cookRecipeContext";
import { useForkedRecipeSelectorContext } from "~/components/context/forkedRecipesContext";
import { usePagesizeSelectorContext } from "~/components/context/pageSizeContext";
import { usePantrySelectorContext } from "~/components/context/pantryContext";
import { useShoppingListSelectorContext } from "~/components/context/shoppingListContext";
import { useAppData } from "~/utils/data/useAppData";
import { useAppTenantRoute, useCurrentRecipe, useGuestRoute, useOnFrontend, useOnRecipe } from "~/utils/data/useRecipeRouteData";
import CalendarIcon from "../icons/CalendarIcon";
import FireIcon from "../icons/FireIcon";
import HomeIcon from "../icons/HomeIcon";
import ShoppingCartIcon from "../icons/ShoppingCartIcon";
import AddressBookIcon from "../icons/crm/AddressBookIcon";
import {BookHeart, CookingPot, Calendar, ShoppingBasket, Refrigerator} from 'lucide-react';

function AppTenantTabbar() {
  const appData = useAppData();
  const openCommandPalette = useCommandPaletteSelectorContext((state) => state.openCommandPalette);

  const onFrontend = useOnFrontend();
  const navigate = useNavigate();
  const sizes = usePagesizeSelectorContext((state) => state.sizes);

  const openCookRecipe = useCookRecipeSelectorContext((state) => state.openCookRecipe);
  const numRecipes = useCookRecipeSelectorContext((state) => state.numRecipes);
  const openChooseCook = useCookRecipeSelectorContext((state) => state.openChooseCook);
  const setThisRecipeId = useCookRecipeSelectorContext((state) => state.setThisRecipeId);
  const setThisCollectionId = useCookRecipeSelectorContext((state) => state.setThisCollectionId);

  const openUserCalendar = useCalendarSelectorContext((state) => state.openUserCalendar);
  const numUpcomingRecipes = useCalendarSelectorContext((state) => state.numUpcomingRecipes);

  const openShoppingList = useShoppingListSelectorContext((state) => state.openShoppingList);
  const shoppingListItems = useShoppingListSelectorContext((state) => state.shoppingListItems);

  const userRecipes = useForkedRecipeSelectorContext((state) => state.userRecipes);

  const openPantry = usePantrySelectorContext((state) => state.openPantry);
  const pantryItems = usePantrySelectorContext((state) => state.pantryItems);

  const currentRecipe = useCurrentRecipe();

  const appTenantRoute = useAppTenantRoute();
  const guestRoute = useGuestRoute();
  const onRecipe = useOnRecipe();

  const parentRoute = appTenantRoute?.pathname ?? guestRoute?.pathname;
  const recipeRoute = onRecipe?.pathname;

  const handleClickCook = useCallback(() => {
    if (numRecipes > 1) {
      if (currentRecipe?.id) {
        setThisRecipeId(currentRecipe?.id);
        setThisCollectionId(null);
      }
      openChooseCook();
    } else if (numRecipes > 0) {
      openCookRecipe();
    } else {
      Toast.show({ icon: "fail", content: "Please select a recipe to cook" });
    }
  }, [numRecipes, currentRecipe?.id]);

  const handleClickCalendar = useCallback(() => {
    openUserCalendar();
  }, []);

  const handleClickShop = () => {
    openShoppingList();
  };

  const handleClickPantry = () => {
    openPantry();
  };

  const tabs = [
    { name: "Recipes", href: `${parentRoute}/recipe`, id: "recipes" },
    { name: "Shop", onClick: handleClickShop, id: "shop" },
    { name: "Calendar", onClick: handleClickCalendar, id: "calendar" },
    { name: "Cooking", onClick: handleClickCook, id: "cooking" },
    { name: "Pantry", onClick: handleClickPantry, id: "pantry" },
    { name: "Settings", onClick: openCommandPalette, id: "settings" },
    { name: "Recipe", href: recipeRoute, id: "recipe" },
    { name: "Ingredients", href: `${recipeRoute}/ingredients`, id: "ingredients" },
    { name: "Equipment", href: `${recipeRoute}/equipment`, id: "equipment" },
    { name: "Notes", href: `${recipeRoute}/notes`, id: "notes" },
    { name: "Nutrition", href: `${recipeRoute}/nutrition`, id: "nutrition" },
  ];

  const handleChange = (key: string) => {
    const item = tabs?.find((item) => item.id === key);
    if (item) {
      if (item.onClick) {
        item.onClick();
      } else {
        navigate(item.href);
      }
    }
  };

  if (sizes.lg && onFrontend) {
    return null;
  }
  return (
    <>
      <Transition
        appear={true}
        show={!sizes.lg}
        enter="transition ease duration-300 transform delay-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease duration-300 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={"tabbar flex-1 lg:hidden"}
      >
        <TabBar onChange={handleChange} activeKey={null}>
          <TabBarItem className="badge-success" key={"recipes"} icon={<BookHeart className="h-6 w-6" />} badge={userRecipes.length} title={"Recipes"} />
          <TabBarItem
            className="badge-success"
            key={"cooking"}
            icon={<CookingPot className="h-6 w-6" />}
            badge={numRecipes > 0 && numRecipes}
            title={"Cooking"}
          />

          <TabBarItem className="badge-success" key={"calendar"} icon={<Calendar className="h-6 w-6" />} badge={numUpcomingRecipes} title={"Calendar"} />
          <TabBarItem className="badge-success" key={"shop"} icon={<ShoppingBasket className="h-6 w-6" />} badge={shoppingListItems.length} title={"Shop"} />
          <TabBarItem className="badge-success" key={"pantry"} icon={<Refrigerator className="h-6 w-6" />} badge={pantryItems.length} title={"Pantry"} />

          {/* <TabBarItem key={"settings"} icon={<SettingsIcon className="h-6 w-6" />} title={"Settings"} /> */}
          {/* <TabBarItem
            key={"settings"}
            icon={
              appData.user?.avatar ? (
                <img className="h-6 w-6 rounded-full" src={appData.user?.avatar} alt="User avatar" />
              ) : appData.currentTenant?.icon ? (
                <img className="h-6 w-6 rounded-full" src={appData.currentTenant?.icon} alt="Tenant icon" />
              ) : (
                <span className="flex h-6 w-6 items-center justify-center rounded-full bg-theme-600">
                  <BrandIcon />
                </span>
              )
            }
            title={"Settings"}
          /> */}
        </TabBar>
      </Transition>
      {/* <Transition
        show={show && !!onRecipe}
        enter="transition ease duration-300 transform delay-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease duration-300 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <TabBar onChange={handleChange} activeKey={null}>
          <TabBarItem key={"recipe"} icon={<AddressBookIcon className="h-6 w-6" />} title={"Recipe"} />
          <TabBarItem key={"ingredients"} icon={<AddressBookIcon className="h-6 w-6" />} title={"Ingredients"} />
          <TabBarItem key={"equipment"} icon={<AddressBookIcon className="h-6 w-6" />} title={"Equipment"} />
          <TabBarItem key={"nutrition"} icon={<AddressBookIcon className="h-6 w-6" />} title={"Nutrition"} />
          <TabBarItem key={"notes"} icon={<AddressBookIcon className="h-6 w-6" />} title={"Notes"} />
        </TabBar>
      </Transition> */}
    </>
  );
}

export default memo(AppTenantTabbar);
