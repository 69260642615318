// import { useBarcodeContext } from "~/components/context/barcodeContext";
import List from "antd-mobile/es/components/list";
import clsx from "clsx";
import { Fragment } from "react";
import { useDashboardSelectorContext } from "~/components/context/dashboardContext";
import PopupWithNavbar from "~/components/layouts/PopupWithNavbar";
import CheckFilledCircleIcon from "~/components/ui/icons/CheckFilledCircleIcon";
import { calculatePercentCompletion } from "~/utils/misc";
import AchievementProgress from "./Achievements/Progress";

export default function Achievements() {
  const achievementsOpen = useDashboardSelectorContext((state) => state.achievementsOpen);
  const closeAchievements = useDashboardSelectorContext((state) => state.closeAchievements);
  const goals = useDashboardSelectorContext((state) => state.goals);

  return (
    <PopupWithNavbar
      className={"achievements-popup"}
      headerClass="bg-theme-300"
      // destroyOnClose={true}
      open={achievementsOpen}
      onClose={closeAchievements}
      title={"Achievements"}
      //   bodyClassName={"w-full"}
      // navbarProps={{
      //   className: "relative bg-theme-300",
      //   // right: <CameraIcon className="h-full w-16 p-4" />,
      //   right: (
      //     <div className="w-16 cursor-pointer p-4" onClick={toggleScanning}>
      //       <CameraIcon className="h-full" />
      //     </div>
      //   ),
      // }}
      shadow={false}
    >
      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="nested-list sticky-items flex flex-1 flex-col ">
          <List>
            {goals.map((goal, index) => {
              const levels = goal.levels?.sort((a, b) => a.number - b.number);
              const levelProgress = calculatePercentCompletion(levels, goal.comparison);
              const currentLevelIndex =
                levelProgress.findIndex((progress) => progress < 1) >= 0 ? levelProgress.findIndex((progress) => progress < 1) : levels.length - 1;
              const currentLevel = levels[currentLevelIndex];
              const prevLevel = levels[currentLevelIndex - 1];
              return (
                <Fragment key={index}>
                  <List.Item className="sticky top-0 z-10 bg-gray-100" extra={currentLevel.title} description={goal.description}>
                    {goal.title}
                  </List.Item>
                  {levels.map((level, index) => {
                    const isCurrent = currentLevelIndex === index;
                    const remaining = isCurrent ? Math.round(currentLevel.number * (1 - levelProgress[currentLevelIndex])) : undefined;
                    const progress = (
                      <AchievementProgress
                        levels={levels}
                        //levelProgress should be an array of length levels.length and should have 0 for any index higher than index
                        levelProgress={Array(levels.length)
                          .fill(1)
                          .map((_, i) => (i <= index ? 1 : 0))}
                        goal={goal}
                        prevLevel={prevLevel}
                        currentLevel={currentLevel}
                        currentLevelIndex={currentLevelIndex}
                        showTitle={false}
                      />
                    );
                    return (
                      <List.Item
                        key={index}
                        className={clsx(currentLevelIndex - 1 === index && "bg-theme-100")}
                        prefix={progress}
                        description={level.description}
                        extra={
                          currentLevelIndex - 1 === index ? (
                            <CheckFilledCircleIcon className="h-10 w-10  text-theme-300" />
                          ) : remaining ? (
                            <span className="text-sm">{`Need ${remaining} more!`}</span>
                          ) : undefined
                        }
                      >
                        {level.title}
                      </List.Item>
                    );
                  })}
                </Fragment>
              );
            })}
          </List>
        </div>
      </div>
    </PopupWithNavbar>
  );
}
